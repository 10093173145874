// at-grid-planes.scss
.at-grid-planes {
  padding-bottom: 48px;
  .at-item-vertical {
    position: relative;
    padding-bottom: 48px;
    .cont:hover::before {
      height: 100%;
    }
    .img-bg {
      max-height: 300px;
      @include media-breakpoint-up(md) {
        max-height: 350px;
      }
    }
  }
}
