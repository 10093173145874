// at-tit-txt-btn-img.scss
%heading-same {
  font-weight: 800;
  font-size: to.rem(24px);
  line-height: to.rem(32px);
  letter-spacing: to.rem(-0.36px);
  color: #333333;
  text-decoration: none;
  transition: $animColor;
  @include media-breakpoint-up(lg) {
    font-size: to.rem(28px);
    line-height: to.rem(36px);
  }
  @include media-breakpoint-up(xxl) {
    font-size: to.rem(32px);
    line-height: to.rem(40px);
    letter-spacing: to.rem(-0.48px);
  }
}
.at-tit-txt-btn-img {
  position: relative;
  display: flex;
  background-color: $white;
  border-radius: 10px;
  padding: 12px;
  box-sizing: border-box;
  flex-direction: column-reverse;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.06);
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    &.toder {
      flex-direction: row-reverse;
      .text-left {
        align-items: flex-end;
        text-align: right;
      }
    }
  }
  .text-left {
    box-sizing: border-box;
    padding: 0 14px;
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(lg) {
      padding: 20px;
      width: 292px;
      min-width: 292px;
      align-items: flex-start;
    }
    @include media-breakpoint-up(xxl) {
      padding: 20px 32px;
      width: 380px;
      min-width: 380px;
    }
    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }
  .heading {
    @extend %heading-same;
    margin-top: 24px;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
    & > * {
      @extend %heading-same;
      margin: 0;
    }
    &:hover {
      color: $at-primary;
      & > * {
        color: $at-primary;
      }
    }
  }
  .parrafo {
    font-size: to.rem(16px);
    line-height: to.rem(25px);
    color: #666;
    margin-top: 14px;
    margin-bottom: 0;
  }
  .btn {
    margin: 30px 0;
    @include media-breakpoint-up(lg) {
      margin: 52px 0 0;
    }
  }
  .fechas,
  .lugar {
    font-size: to.rem(14px);
    line-height: to.rem(14px);
    color: #666;
    margin-top: 9px;
    display: flex;
    align-items: center;
    .icon {
      display: flex;
      min-width: 16px;
      margin-right: 6px;
    }
    svg {
      height: 16px;
      position: relative;
      top: -1px;
      path {
        fill: #b3b3b3;
        transition: $animFill;
      }
    }
  }
  .fechas {
    margin-top: 22px;
  }
  .image {
    position: relative;
    margin: 0 -12px;
    top: -12px;
    min-height: auto;
    @include media-breakpoint-up(lg) {
      margin: 0;
      top: 0;
      flex-grow: 2;
      min-height: 455px;
      .img-fluid {
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
      }
    }
    @include media-breakpoint-up(xl) {
      min-height: 474px;
    }
    .cat {
      position: absolute;
      top: 16px;
      left: 16px;
    }
    &:hover {
      .cat {
        color: $black;
        background-color: $white;
      }
    }
  }
}
.at-tit-txt-btn-img-solo {
  padding-bottom: 2rem;
  margin: 0 !important;
  @include media-breakpoint-up(md) {
    padding-bottom: 3rem;
  }
}
