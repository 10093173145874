// at-info-contenido.scss
.at-info-contenido {
  padding-bottom: 42px;
  .row {
    margin-bottom: 30px;
    .info {
      background: #e9e9e9;
      padding: 40px 20px;
      svg {
        color: $at-primary;
        height: 90px;
        margin: 0 0 12px;
      }
      .h3,
      .h4 {
        margin-bottom: 12px;
      }
      p {
        margin: 0;
      }
    }
    ul {
      background: #e9e9e9;
      padding-top: 10px;
      padding-bottom: 10px;
      ::marker {
        color: $at-primary;
      }
    }
    .img-fluid {
      min-width: 100%;
    }
  }
}
.icos-anar {
  margin-top: 40px;
  margin-bottom: 30px;
  a {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: to.rem(16px);
    line-height: to.rem(25px);
    color: #535353;
    text-decoration: none;
    padding: 36px 6px 42px;
    border-radius: 10px;
    background-color: transparent;
    transition: $animBgColor;
    min-height: 100%;
    box-sizing: border-box;
    &:hover {
      background-color: $white;
    }
    svg {
      height: 50px;
      width: 50px;
      color: $at-primary;
    }
    p {
      padding: 0;
      margin: 0;
    }
    .title {
      font-weight: 800;
      font-size: to.rem(24px);
      letter-spacing: to.rem(-0.4px);
      color: $dkGray;
      margin: 20px 0 10px;
      @include media-breakpoint-up(md) {
        font-size: to.rem(28px);
        letter-spacing: to.rem(-0.5px);
      }
    }
  }
  .col-xl-3 {
    a {
      &::after,
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: -0.75rem;
        background: $white;
        width: calc(100% + 1.5rem);
        height: 1px;
      }
      &::before {
        bottom: -1px;
        background: rgba(0, 0, 0, 0.08);
      }
      @include media-breakpoint-up(xl) {
        &::after,
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: -0.75rem;
          background: $white;
          width: 1px;
          height: 100%;
        }
        &::before {
          right: calc(-0.75rem - 1px);
          background: rgba(0, 0, 0, 0.08);
        }
      }
    }
    @include media-breakpoint-down(md) {
      &:last-child {
        a {
          &::after,
          &::before {
            display: none;
          }
        }
      }
    }
    @include media-breakpoint-up(xl) {
      &:last-child {
        a {
          &::after,
          &::before {
            display: none;
          }
        }
      }
    }
    @include media-breakpoint-only(md) {
      &:nth-child(2) {
        a {
          &::after,
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: calc(-0.75rem - 1px);
            background: $white;
            width: 1px;
            height: 100%;
          }
          &::before {
            left: -0.75rem;
            background: rgba(0, 0, 0, 0.08);
          }
        }
      }
      &:nth-child(3) {
        a {
          &::after,
          &::before {
            content: '';
            position: absolute;
            top: 0;
            right: -0.75rem;
            background: $white;
            width: 1px;
            height: 100%;
          }
          &::before {
            right: calc(-0.75rem - 1px);
            background: rgba(0, 0, 0, 0.08);
          }
        }
      }
      &:nth-child(4) {
        a {
          &::after,
          &::before {
            bottom: auto;
            top: -1px;
          }
          &::before {
            top: 0;
          }
        }
      }
    }
    @include media-breakpoint-only(lg) {
      &:nth-child(2) {
        a {
          &::after,
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: calc(-0.75rem - 1px);
            background: $white;
            width: 1px;
            height: 100%;
          }
          &::before {
            left: -0.75rem;
            background: rgba(0, 0, 0, 0.08);
          }
        }
      }
      &:nth-child(3) {
        a {
          &::after,
          &::before {
            content: '';
            position: absolute;
            top: 0;
            right: -0.75rem;
            background: $white;
            width: 1px;
            height: 100%;
          }
          &::before {
            right: calc(-0.75rem - 1px);
            background: rgba(0, 0, 0, 0.08);
          }
        }
      }
      &:nth-child(4) {
        a {
          &::after,
          &::before {
            bottom: auto;
            top: -1px;
          }
          &::before {
            top: 0;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .img-fluid.mx-auto {
    min-width: 100%;
  }
}
