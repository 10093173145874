:root {
  --swiper-navigation-size: 72px;
  --swiper-theme-color: #ffffff;
  --swiper-pagination-bullet-size: 23px;
  --swiper-pagination-bullet-width: 23px;
  --swiper-pagination-bullet-height: 23px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 5px;
}
.slider-activ-wrap {
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
  @include media-breakpoint-up(lg) {
    padding-bottom: 90px;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: 130px;
  }
  @include media-breakpoint-up(xxl) {
    padding-bottom: 164px;
  }
  > .container {
    position: relative;
    z-index: 10;
    .container-inner {
      overflow: hidden;
      padding: 0 8px;
      @include media-breakpoint-up(md) {
        margin: 0 42px;
      }
      @include media-breakpoint-up(lg) {
        margin: 0 92px;
      }
      @include media-breakpoint-up(xl) {
        margin: 0 142px;
      }
      &.ajustado {
        > * {
          color: $white;
        }
      }
    }
  }
  .imagen-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    height: 100%;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.35) 0%,
        rgba(0, 0, 0, 0.25) 52.68%,
        rgba(0, 0, 0, 0) 99.78%
      );
      width: 100%;
      height: 344px;
      z-index: 6;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        0deg,
        rgba(233, 233, 233, 1) 3.74%,
        rgba(233, 233, 233, 0) 100%
      );
      width: 100%;
      height: 391px;
      z-index: 6;
    }
    .img-bg {
      position: relative;
      object-fit: cover;
      z-index: 5;
      min-height: 100%;
      min-width: 101%;
      max-width: 101%;
    }
  }
}
.slider-activ {
  padding: 30px 0 53px;
  overflow: visible;
  .swiper-wrapper {
    .container {
      z-index: 10;
      visibility: visible;
    }
  }
}
.nav-slider-activ {
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0.7;
    @include media-breakpoint-down(lg) {
      visibility: hidden;
    }
  }
  .swiper-pagination {
    visibility: visible;
    font-size: 0;
    .swiper-pagination-bullet {
      border: 1px solid #a7a7a7;
      transition: $animAll;
      outline: none !important;
      box-shadow: none !important;
      &-active {
        background: #333333 !important;
        border: 1px solid #fff !important;
        cursor: default;
      }
      &:hover {
        background: $at-primary;
        border: 1px solid #fff;
      }
    }
  }
}
