// at-detalle-prop.scss
.at-detalle-prop {
  position: relative;
  margin-bottom: 64px;
  .at-mapa {
    max-height: 400px;
    min-height: 400px;
  }
  .tabs {
    position: relative;
    .tablistwrap {
      overflow-x: auto;
      display: flex;
      margin: 20px 0;
      padding: 0 0 6px;
      @include media-breakpoint-up(lg) {
        justify-content: center;
      }
      &::after {
        content: '';
        position: absolute;
        display: block;
        right: 0;
        top: 0;
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        width: 32px;
        height: 100%;
      }
      @include media-breakpoint-up(md) {
        margin: 40px 0 30px;
      }
      @include media-breakpoint-up(xxl) {
        overflow: visible;
      }
    }
    .tablist {
      border: none;
      display: flex;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        flex: 0 0 auto;
      }
      a {
        font-weight: 800;
        font-size: to.rem(19px);
        line-height: to.rem(28px);
        color: #808080;
        text-decoration: none;
        position: relative;
        margin-right: 20px;
        padding: 2px 0;
        transition: $animColor;
        @include media-breakpoint-up(md) {
          margin: 0 18px;
          padding: 2px 2px 6px;
          font-size: to.rem(20px);
        }
        @include media-breakpoint-up(xl) {
          font-size: to.rem(24px);
        }
        &::after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 0;
          height: 4px;
          background-color: transparent;
          transition: $animAll;
          @include media-breakpoint-up(md) {
            height: 5px;
          }
        }
        &:hover {
          color: $at-primary;
        }
      }
      [role='tab'][aria-selected='true'] {
        color: $dkGray;
        &::after {
          width: 100%;
          background-color: $dkGray;
          transition: $animAll;
        }
      }
    }
  }
}
.grid-simple {
  .at-grid-agenda {
    padding-top: 22px;
  }
  .at-grid-hotels .btn-mas {
    margin-top: 42px;
  }
}
