.pmr-modal {
  position: relative;
  .logo {
    position: absolute;
    left: 16px;
    top: 16px;
    display: flex;
    justify-content: flex-start;
    svg {
      width: 240px;
      max-width: 60vw;
    }
  }
  .imageslist {
    img {
      min-width: 100%;
      max-width: 100%;
      align-self: flex-start;
    }
    .row {
      margin-top: 20px;
      padding: 0 5px;
      > div {
        position: relative;
        overflow: hidden;
        padding: 0 5px;
        margin-bottom: 10px;
      }
    }
    .avatar {
      display: flex;
      position: relative;
      padding: 3px;
      cursor: pointer;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-style: solid;
        border-color: transparent;
        border-width: 6px;
        border-radius: 3px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
      }
      &.current {
        &::after {
          border-color: #398be7 !important;
          transform: scale(1);
        }
      }
      &:hover {
        &::after {
          border-color: #155fb3;
          transform: scale(1);
        }
      }
    }
  }
  .icon {
    display: flex;
    justify-content: center;
    margin: 24px 0;
    svg {
      display: block !important;
      min-width: 71px;
      max-width: 71px;
    }
  }
  hr {
    display: none;
  }
  .btn {
    margin: 6px;
  }
  @include media-breakpoint-down(sm) {
    p {
      font-size: to.rem(15px);
      line-height: to.rem(19px);
    }
    #country {
      margin-bottom: 10px;
    }
  }
  .newsletter-form {
    .form-group {
      margin-top: 20px;
      .checkbox {
        margin-bottom: 8px;
        text-align: left;
        label {
          white-space: nowrap;
        }
      }
      legend {
        font-size: to.rem(18px);
        font-weight: 700;
        color: $dkGray;
        margin-bottom: 12px;
        @include media-breakpoint-down(sm) {
          font-size: to.rem(16px);
          line-height: to.rem(19px);
        }
      }
      .error-msg {
        margin: 0;
      }
    }
  }
}
