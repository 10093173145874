%titulo-modo-lectura {
  font-size: to.rem(32px);
  line-height: to.rem(36px);
  @include media-breakpoint-up(lg) {
    font-size: to.rem(54px);
    line-height: to.rem(64px);
  }
}
%subsubtitulo-modo-lectura {
  font-size: to.rem(22px);
  line-height: to.rem(30px);
  letter-spacing: to.rem(-0.4px);
  @include media-breakpoint-up(lg) {
    font-size: to.rem(42px);
    line-height: to.rem(52px);
    letter-spacing: to.rem(-0.76px);
  }
}
.contenido-modo-lectura {
  display: none;
  margin-top: 182px;
  margin-bottom: 120px;
  color: $black;
  * {
    color: $black !important;
  }
  .title,
  .h1 {
    @extend %titulo-modo-lectura;
    > * {
      @extend %titulo-modo-lectura;
    }
  }
  .subsubtitulo {
    @extend %subsubtitulo-modo-lectura;
    > * {
      @extend %subsubtitulo-modo-lectura;
    }
  }
  p,
  ul,
  li {
    font-size: to.rem(16px);
    line-height: to.rem(26px);
    letter-spacing: to.rem(-0.24px);
    @include media-breakpoint-up(lg) {
      font-size: to.rem(24px);
      line-height: to.rem(46px);
      letter-spacing: 0;
    }
  }
}
.letter110 {
  font-size: 110%;
}
.letter125 {
  font-size: 125%;
}
.modo-lectura-ON {
  .header-page {
    font-size: 100% !important;
  }
  main {
    color: #000000;
    .intro-detalle::after,
    .intro-detalle .image,
    .intro-detalle .info .cat,
    .intro-detalle .nav-interno,
    .intro-detalle .info-img,
    .intro-detalle .info .sobretitle .btn,
    .intro-single .image,
    .at-detalle-contenido .mapzone .col.map,
    .at-detalle-contenido .btnsytrip,
    .at-detalle-contenido .datos-itinerario .graficas .grafica.concanvas,
    .at-detalle-contenido #detailcontainer .deg,
    .at-detalle-contenido #detailcontainer .button-close,
    .at-detalle-contenido #detailcontainer .btn,
    .at-detalle-contenido .precionoche,
    .at-detalle-contenido .mapzone .col .timelabel,
    .at-detalle-contenido .mapzone .col .timegoggle,
    .at-detalle-pistas,
    .at-grid-agenda .cont .at-item-agenda-inner:before,
    .at-grid-featured .cont:before,
    .at-grid-featured .img-bg,
    .slider-cuadrado .cont:before,
    .at-info-contenido .row .img-fluid,
    .at-info-contenido .float-md-end,
    #media,
    .social-reviews,
    .slider-social-wrap,
    .slider-detalle-wrap,
    .at-detalle-prop,
    .slider-agenda,
    .slider-cat-wrap,
    .slider-event-wrap,
    .grid-simple,
    #seocontainer,
    .at-fila-download-app,
    .at-info-contenido .row.image-grid,
    picture,
    .at-mapa,
    .no-lectura {
      display: none;
    }
    .intro-detalle,
    .intro-single {
      min-height: inherit;
    }
    .at-detalle-contenido #detailcontainer .calcChildSize {
      height: auto;
    }
    .intro-detalle .info .sobretitle .stars svg {
      filter: none;
      path {
        fill: #000000;
      }
    }
    .intro-detalle {
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        margin-bottom: 24px;
      }
      .container {
        padding-top: 153px;
        @include media-breakpoint-up(lg) {
          padding-top: 166px;
        }
      }
    }
    .at-detalle-contenido .fecha-wrap {
      justify-content: flex-start;
      .fecha {
        align-items: flex-start;
        background: transparent;
        padding: 0;
        svg {
          color: #000000;
          position: relative;
          top: -3px;
        }
        &::after {
          display: none;
        }
      }
    }
    .intro-detalle .info .title,
    .intro-detalle .info .title > *,
    .intro-plan .info .title,
    .intro-plan .info .title > *,
    .intro-single .info .title,
    .intro-single .info .title > *,
    .title-sinintro,
    .title-sinintro > * {
      color: #000000;
      text-shadow: inherit;
      text-align: left;
    }
    .at-detalle-contenido .subtitulo,
    .intro-single .info .parrafo,
    .intro-single .info .parrafo > *,
    .intro-single .info p,
    .intro-single .info p > *,
    .at-grid-featured .cont .text-center .heading,
    .slider-cuadrado .cont .text-center .heading {
      text-align: left;
      color: #000000;
    }
    .at-detalle-contenido .datos-itinerario .graficas .grafica .title {
      font-weight: 700;
    }
    .at-detalle-contenido .datos-itinerario .graficas .grafica .title,
    .at-detalle-contenido .datos-itinerario .graficas .grafica .number,
    .at-detalle-contenido .subsubtitulo,
    .at-detalle-contenido .subsubtitulo > *,
    .at-detalle-contenido .title {
      color: #000000;
    }
    .at-detalle-contenido .datos-itinerario,
    .at-info-contenido .row ul,
    .gris-bg {
      background: transparent;
    }
    .at-grid-featured .cont .datos .bigtext p,
    .slider-cuadrado .cont .datos .bigtext p {
      text-align: left;
    }
    .at-grid-featured .cont .text-center,
    .slider-cuadrado .cont .text-center {
      position: relative;
      align-items: flex-start;
      min-height: inherit;
      padding: 0;
    }
    .at-grid-featured .cont .datos,
    .slider-cuadrado .cont .datos {
      align-items: flex-start;
      padding: 0;
    }
    .at-grid-featured .cont .datos .btns,
    .slider-cuadrado .cont .datos .btns {
      align-items: flex-start;
      flex-direction: row;
      gap: 10px;
    }
    p,
    ul,
    li {
      color: #000000;
      font-size: to.rem(16px);
      line-height: to.rem(26px);
      letter-spacing: to.rem(-0.24px);
      @include media-breakpoint-up(lg) {
        font-size: to.rem(24px);
        line-height: to.rem(46px);
        letter-spacing: 0;
      }
    }
    .at-grid-featured .col-xxl-4,
    .at-info-contenido .col-md-8 {
      width: 100%;
    }
  }
  .footer {
    display: none !important;
  }
  .contenido-modo-lectura {
    display: block;
  }
}
