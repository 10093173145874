.pmr-header {
  background-image: url('/bundles/app/redesign/img/header-BG-05.jpg');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: inherit;
  background-color: #175fb3;
  position: relative;
  overflow: hidden;
  display: flex;
  @include media-breakpoint-down(md) {
    background-size: cover;
  }
  @include media-breakpoint-down(sm) {
    background-image: none;
  }
  &::before {
    content: '';
    top: 0;
    right: 323px;
    position: absolute;
    background: linear-gradient(
      90deg,
      rgba(23, 95, 179, 1) 0%,
      rgba(23, 95, 179, 0) 100%
    );
    width: 540px;
    height: 100%;
    max-width: 100vw;
    @include media-breakpoint-down(md) {
      right: auto;
      left: 0;
      min-width: 100vw;
    }
  }
  &::after {
    content: '';
    bottom: 0;
    right: 0;
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(23, 95, 179, 0.75) 40.33%,
      rgba(23, 95, 179, 0) 100%
    );
    width: 100%;
    height: 66px;
  }
  & > .container > .row > .col-sm-auto {
    &:first-child {
      z-index: 60;
    }
  }
  .container {
    position: relative;
    z-index: 10;
    padding-top: 54px;
    @include media-breakpoint-only(sm) {
      position: initial;
    }
    @include media-breakpoint-up(md) {
      padding-top: 10px;
    }
    @include media-breakpoint-up(xl) {
      padding-top: 178px;
    }
    & > .row {
      position: relative;
      min-height: 317px;
      align-items: center;
      @include media-breakpoint-only(sm) {
        padding-bottom: 60px;
        position: initial;
      }
      @include media-breakpoint-down(md) {
        min-height: 260px;
        .col-sm-auto {
          margin-top: 12px;
          justify-content: center;
          &:first-child {
            display: flex;
          }
        }
        &::after {
          content: '';
          bottom: 0;
          left: 0;
          position: absolute;
          background: linear-gradient(
            90deg,
            rgba(23, 95, 179, 1) 0%,
            rgba(23, 95, 179, 0) 100%
          );
          width: 25px;
          height: 52px;
          display: block;
          z-index: 60;
        }
        &::before {
          content: '';
          bottom: 0;
          right: 0;
          position: absolute;
          background: linear-gradient(
            270deg,
            rgba(23, 95, 179, 1) 0%,
            rgba(23, 95, 179, 0) 100%
          );
          width: 25px;
          height: 52px;
          display: block;
          z-index: 60;
        }
      }
      .col.ml-sm-auto.align-self-end {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 50;
        @include media-breakpoint-down(md) {
          overflow-y: scroll;
        }
        @include media-breakpoint-down(sm) {
          position: relative;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .circulo {
      transform: scale(0.8);
    }
  }
  @include media-breakpoint-only(sm) {
    .circulo {
      position: relative;
      left: -20px;
      bottom: -12px;
    }
  }
  .pmr-datos-user {
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .frase {
      font-weight: 700;
      font-size: to.rem(18px);
      line-height: to.rem(18px);
      color: rgba(255, 255, 255, 0.7);
      @include media-breakpoint-down(sm) {
        font-size: to.rem(16px);
        line-height: to.rem(16px);
      }
    }
    .nombre {
      font-weight: 800;
      font-size: to.rem(48px);
      line-height: to.rem(48px);
      color: $white;
      margin: 4px 0;
      @include media-breakpoint-down(sm) {
        font-size: to.rem(34px);
        line-height: to.rem(34px);
        text-align: center;
      }
    }
    svg {
      opacity: 0.7;
      width: 165px;
      path {
        fill: $white;
      }
      @include media-breakpoint-down(sm) {
        width: 140px;
      }
    }
    .nivel {
      font-weight: 800;
      font-size: to.rem(17px);
      line-height: to.rem(17px);
      color: rgba(255, 255, 255, 0.65);
      @include media-breakpoint-down(sm) {
        font-size: to.rem(15px);
        line-height: to.rem(15px);
      }
    }
  }
  .pmr-nav {
    display: flex;
    justify-content: flex-end;
    position: relative;
    @include media-breakpoint-down(md) {
      justify-content: center;
      justify-content: flex-start;
      margin: 10px 0 0;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: nowrap;
      @include media-breakpoint-down(md) {
        padding: 0 20px 0 10px;
      }
      li {
        margin: 0 1px;
        a {
          display: flex;
          align-items: center;
          min-height: 100%;
          padding: 14px 16px;
          font-weight: 800;
          text-decoration: none;
          text-transform: uppercase;
          font-size: to.rem(14px);
          line-height: to.rem(16px);
          text-align: center;
          color: $white;
          background-color: transparent;
          transition: background-color 0.3s ease-in-out;
          position: relative;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          &:hover {
            background-color: rgba(255, 255, 255, 0.25);
          }
          @include media-breakpoint-down(md) {
            padding: 10px;
            min-height: 100%;
            align-items: center;
            text-align: center;
          }
        }
        &.current {
          a {
            background-color: $white;
            color: $at-primary;
            @include media-breakpoint-down(sm) {
              background-color: transparent;
              color: $white;
              &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                height: 5px;
                width: 100%;
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }
}
.circulo {
  opacity: 1;
  position: relative;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.75;
  }
  .avatar-modal-btn {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 20;
  }
  .circle_progress {
    &.pmr {
      width: 154px;
      position: relative;
      display: flex;
      svg {
        width: 154px;
        height: 154px;
      }
      .user-img {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 130px;
        height: 130px;
        overflow: hidden;
        img {
          width: 130px;
          height: 130px;
          border-radius: 50%;
        }
      }
      .iniciales {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-weight: 800;
        font-size: to.rem(48px);
        line-height: to.rem(48px);
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.pmr {
  a {
    text-decoration: none;
  }
  .tit {
    color: $dkGray;
    font-weight: 800;
    font-size: to.rem(42px);
    line-height: to.rem(48px);
    letter-spacing: to.rem(-0.76px);
    text-align: center;
    @include media-breakpoint-down(sm) {
      font-size: to.rem(32px);
      line-height: to.rem(38px);
    }
  }
}
