.pmr-carousel {
  position: relative;
  padding: 10px 0;
  margin-bottom: 90px;
  @include media-breakpoint-up(sm) {
    padding: 10px 50px;
  }
  .pmr-carousel-item {
    display: flex;
    flex-direction: column;
    margin: 32px 0 0;
    a {
      display: flex;
      flex-direction: column;
      min-height: 100%;
    }
    .top {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      width: 100%;
      z-index: 6;
      top: -31px;
      .nivel {
        order: 2;
        font-weight: 800;
        font-size: to.rem(28px);
        line-height: to.rem(30px);
        letter-spacing: to.rem(-0.7px);
        color: $white;
      }
      .status {
        order: 1;
        margin-bottom: 6px;
      }
    }
    .status {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $white;
      border: 4px solid #c9c9c9;
      position: relative;
      z-index: 10;
      svg {
        display: block !important;
        width: 26px;
        height: 26px;
        .check {
          display: none;
        }
        path {
          fill: #8d8d8d;
        }
      }
    }
    .image {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: linear-gradient(
          0deg,
          rgba(201, 201, 201, 0) 0%,
          rgba(201, 201, 201, 1) 67.22%
        );
        width: 100%;
        height: 147px;
        z-index: 5;
        transition: $animHeight;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.97) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        opacity: 0.76;
        width: 100%;
        height: 182px;
        z-index: 5;
        transition: $animAll;
      }
      .img-fluid {
        min-height: 338px;
        min-width: 100%;
        object-fit: cover;
        opacity: 0.35;
        transition: $animOpacity;
      }
      .titulo {
        position: absolute;
        z-index: 6;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        text-align: center;
        pointer-events: none;
        font-weight: 800;
        font-size: to.rem(28px);
        line-height: to.rem(30px);
        letter-spacing: to.rem(-0.42px);
        color: $white;
        padding: 20px;
        box-sizing: border-box;
        min-height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-up(sm) {
          font-size: to.rem(36px);
          line-height: to.rem(40px);
          letter-spacing: to.rem(-0.55px);
        }
      }
    }
    .text {
      margin: 0;
      padding: 30px 20px;
      font-size: to.rem(16px);
      line-height: to.rem(26px);
      letter-spacing: to.rem(-0.24px);
      color: $mdGray;
      text-align: center;
      position: relative;
      @include media-breakpoint-up(sm) {
        padding: 34px 40px;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: linear-gradient(
          0deg,
          rgba(230, 230, 230, 0) 0%,
          rgba(230, 230, 230, 1) 100%
        );
        width: 100%;
        height: 246px;
        max-height: 100%;
        z-index: -1;
        opacity: 0.5;
        transition: $animOpacity;
      }
    }
    &.checked {
      .image {
        position: relative;
        &::before {
          background: linear-gradient(
            0deg,
            rgba(61, 137, 211, 0) 0%,
            rgba(61, 137, 211, 1) 67.22%
          );
          height: 147px;
          z-index: 5;
        }
        .img-fluid {
          opacity: 1 !important;
        }
      }
      .top {
        .status {
          border-color: $at-primary;
          svg {
            .check {
              display: block;
              fill: #2ac24a;
            }
            .lock {
              display: none;
            }
          }
        }
      }
      .text {
        &::before {
          background: linear-gradient(
            0deg,
            rgba(0, 174, 239, 0) 0%,
            rgba(0, 174, 239, 0.18) 100%
          );
        }
      }
    }
    :hover {
      .image {
        &::after {
          opacity: 1;
        }
        &::before {
          height: 170px;
        }
        .img-fluid {
          opacity: 1;
        }
      }
      .text {
        &::before {
          opacity: 1;
        }
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    transform: scale(0.3509);
    top: 216px;
    @include media-breakpoint-up(sm) {
      transform: scale(0.46);
      top: 50%;
    }
    &::before {
      opacity: 0.18;
    }
  }
  .swiper-button-prev {
    left: -24px;
    @include media-breakpoint-up(sm) {
      left: -11px;
    }
  }
  .swiper-button-next {
    right: -24px;
    @include media-breakpoint-up(sm) {
      right: -11px;
    }
  }
}
