// _at-item-featured.scss
.at-item-featured {
  position: relative;
  height: 100%;
}
%at-item-featured-before-styles {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.97) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.76;
  width: 100%;
  height: 182px;
  z-index: 10;
  transition: $animAll;
}
.slider-cuadrado,
.at-grid-featured {
  .img-bg {
    position: relative;
    z-index: 5;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover;
  }
  .cont {
    position: relative;
    height: 100%;
    display: flex;
    z-index: 5;
    text-decoration: none;
    &::before {
      @extend %at-item-featured-before-styles;
    }
    .text-center {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: 15;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 140px;
      padding: 0 22px;
      box-sizing: border-box;
      max-height: 100%;
      overflow: hidden;
      .cat {
        margin: 12px 0 4px;
      }
      .heading {
        font-weight: 800;
        font-size: to.rem(37px);
        line-height: to.rem(43px);
        letter-spacing: to.rem(-0.55px);
        color: #ffffff;
        max-height: to.rem(86px);
        overflow: hidden;
      }
      .calcChildSize {
        overflow: hidden;
        height: 0;
        transition: $animHeight;
      }
      .parrafo {
        font-size: to.rem(18px);
        line-height: to.rem(28px);
        max-height: to.rem(168px);
        overflow: hidden;
        color: #ffffff;
        text-shadow: 1px 1px 0.55px rgba(0, 0, 33, 0.4);
        margin: 0;
        padding: 10px 0 18px;
      }
      .stars {
        svg {
          filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.6));
        }
      }
      .place {
        display: flex;
        align-items: center;
        color: $white;
        font-size: to.rem(14px);
        line-height: to.rem(14px);
        margin: 6px 0 13px;
        svg {
          height: 14px;
          margin-right: 6px;
          path {
            fill: $white;
          }
        }
      }
    }
    .datos {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      min-height: auto;
      padding: 4px 18px 14px;
      box-sizing: border-box;
      position: relative;
      z-index: 10;
      flex-grow: 2;
      @include media-breakpoint-up(xxl) {
        min-height: 110px;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        width: 100%;
        height: 100%;
        max-height: 110px;
        transition: $animOpacity;
        z-index: 5;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(255, 255, 255, 1);
        opacity: 0;
        width: 100%;
        height: 100%;
        transition: $animOpacity;
        z-index: 5;
      }
      .bigtext {
        position: relative;
        z-index: 10;
        font-size: to.rem(16px);
        line-height: to.rem(25px);
        margin-top: 16px;
        color: $mdGray;
        p {
          padding: 0;
          margin: 0;
        }
      }
      .fechas,
      .lugar {
        position: relative;
        z-index: 10;
        font-size: to.rem(14px);
        line-height: to.rem(16px);
        color: $mdGray;
        margin-top: 9px;
        display: block;
        text-decoration: none;
        .icon {
          display: inline;
          margin-right: 5px;
        }
        span {
          display: inline;
        }
        svg {
          height: 16px;
          position: relative;
          top: -2px;
          path {
            fill: #b3b3b3;
            transition: $animFill;
          }
        }
      }
      .btns {
        position: relative;
        z-index: 10;
        margin-top: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn {
          margin-top: 10px;
          padding: 8px 14px;
          text-transform: initial;
          font-weight: 700;
          letter-spacing: 0;
          svg {
            position: relative;
            top: -1px;
            margin-right: 1px;
            height: 14px;
            path {
              transition: $animFill;
            }
          }
          &.btn-outline-primary {
            background-color: $white;
            &:hover {
              background-color: $at-primary;
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      &:hover {
        &::before {
          opacity: 1;
          height: 100%;
        }
        .datos {
          &::before {
            opacity: 1;
          }
          svg {
            path {
              fill: $at-primary;
            }
          }
        }
        .cat {
          color: $black;
          background-color: $white;
        }
        .calcChildSize {
          height: var(--calc-height);
        }
      }
    }
    .enrolled {
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 6;
      display: flex;
      align-items: center;
      span {
        color: $white;
        font-size: to.rem(14px);
        line-height: to.rem(14px);
        filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.6));
      }
      svg {
        height: 18px;
        margin-right: 8px;
        filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.6));
        path {
          fill: $white;
        }
      }
    }
  }
}
.at-grid-hotels {
  .cont {
    &::before {
      height: 166px;
    }
    .text-center {
      min-height: 123px;
      padding: 0 16px;
      .heading {
        font-size: to.rem(31px);
        line-height: to.rem(36px);
        letter-spacing: to.rem(-0.46px);
        max-height: none;
      }
    }
  }
}
.at-grid-agenda {
  padding-top: 54px;
  .cont {
    flex-direction: column;
    &::before {
      display: none;
    }
    .text-center {
      .heading {
        margin-bottom: 18px;
      }
    }
    &.enguias {
      .text-center {
        min-height: 100px;
        .heading {
          margin-bottom: 0;
          text-transform: none;
        }
      }
    }
    .at-item-agenda-inner {
      position: relative;
      &::before {
        @extend %at-item-featured-before-styles;
      }
    }
    &:hover {
      .at-item-agenda-inner {
        &::before {
          opacity: 1;
          height: 100%;
        }
      }
    }
  }
}
.at-grid-guias {
  @include media-breakpoint-down(sm) {
    padding-top: 0;
    > .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .col-md-6 {
    margin-top: 36px;
  }
  .img-bg {
    min-height: 314px;
    @include media-breakpoint-up(sm) {
      min-height: 450px;
    }
    @include media-breakpoint-up(md) {
      min-height: 252px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 314px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 348px;
    }
    @include media-breakpoint-up(xxl) {
      min-height: 314px;
    }
  }
}
.at-grid-of-tur {
  padding-top: 0;
}
