.pmr-forms {
  .logo {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 70px;
    svg {
      max-width: 329px;
      margin-bottom: 30px;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        margin: 0 0 30px;
        padding: 0 30px;
      }
    }
  }
  .nav-niveles {
    list-style: none;
    margin: 30px 0 0;
    padding: 0 50px 28px;
    display: flex;
    justify-content: space-around;
    border-bottom: 4px solid #cccccc;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      padding: 0 20px 28px;
      justify-content: space-between;
    }
    // @include media-breakpoint-down(xs) {
    //   padding: 0 0 28px;
    // }
    &::before {
      content: '';
      position: absolute;
      background: #e6e6e6;
      height: 4px;
      left: 16.5%;
      top: 85px;
      width: 67%;
      //   @include media-breakpoint-down(lg) {
      //     width: 432px;
      //   }
      //   @include media-breakpoint-down(md) {
      //     width: 464px;
      //   }
      //   @include media-breakpoint-down(sm) {
      //     //     width: 322px;
      //     //   }
      //     //   @include media-breakpoint-down(xs) {
      //     width: 76vw;
      //     left: 10vw;
      //   }
    }
    li {
      position: relative;
      z-index: 10;
      flex-grow: 1;
      flex-basis: 0;
      .top {
        font-size: to.rem(14px);
        line-height: to.rem(14px);
        font-weight: 800;
        color: #cccccc;
      }
      .nivel {
        font-size: to.rem(52px);
        line-height: to.rem(49px);
        letter-spacing: to.rem(-1.3px);
        font-weight: 800;
        color: #cccccc;
        transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        @include media-breakpoint-down(sm) {
          font-size: 40px;
        }
      }
      .status {
        width: 38px;
        height: 38px;
        margin: 4px 0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: 2px solid #e6e6e6;
        position: relative;
        z-index: 10;
        transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        &::after {
          content: '';
          border-radius: 50%;
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
          opacity: 0;
          transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
        svg {
          display: block !important;
          width: 18px;
          height: 18px;
          .check {
            display: none;
          }
        }
      }
      .desc {
        font-weight: 700;
        font-size: to.rem(14px);
        line-height: to.rem(17px);
        color: #808080;
        text-align: center;
        @include media-breakpoint-down(sm) {
          padding: 0 5px;
        }
      }
      a {
        display: flex;
        align-items: center;
        flex-direction: column;
        &:hover {
          .nivel {
            transform: scale(1.1, 1.1);
          }
          .status {
            transform: scale(1.15, 1.15);
            &::after {
              opacity: 0.8;
            }
          }
        }
      }
      &.listo {
        &::before {
          content: '';
          position: absolute;
          background: $at-primary;
          left: 48%;
          top: 85px;
          height: 4px;
          width: 100%;
          z-index: 5;
          @include media-breakpoint-down(lg) {
            width: 136px;
          }
          @include media-breakpoint-down(sm) {
            width: 24vw;
          }
        }
        .top {
          color: $at-primary;
        }
        .nivel {
          color: $at-primary;
        }
        .desc {
          color: $at-primary;
        }
        .status {
          border-color: $at-primary;
          svg {
            .check {
              display: block;
            }
            .lock {
              display: none;
            }
          }
        }
      }
      &.current {
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -28px;
          margin-left: -16px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 16px 16px 16px;
          border-color: transparent transparent #cccccc transparent;
        }
        .top {
          color: $at-primary;
        }
        .nivel {
          color: $at-primary;
        }
        .desc {
          color: $at-primary;
        }
        .status {
          background-color: $at-primary;
          border-color: $at-primary;
          svg {
            .check {
              display: block;
              fill: #ffffff;
            }
            .lock {
              display: none;
            }
          }
        }
      }
    }
  }
  .pmr-form-wrap {
    padding: 60px 50px;
    position: relative;
    @include media-breakpoint-down(md) {
      padding: 30px;
    }
    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        rgba(244, 245, 247, 0) 0%,
        rgba(244, 245, 247, 1) 100%
      );
      opacity: 0.7;
    }
    .pmr-form {
      position: relative;
      z-index: 2;
    }
    .botones {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 12px;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      .btn {
        margin: 0 10px;
        @include media-breakpoint-down(sm) {
          margin: 5px 0;
        }
      }
    }
    .select,
    .select select,
    .form-group .form-input,
    fieldset.birthdate {
      border-radius: 4px;
      border: solid 1px #cccccc;
      min-height: 49px;
      max-height: 49px;
    }
    .select select {
      border: none;
    }
    .form-group .form-input {
      &:read-only,
      &[readonly='readonly'],
      &[readonly] {
        background: #eeeeee;
        cursor: not-allowed;
        &:active,
        &:focus {
          outline: 0;
        }
      }
    }
    fieldset.group {
      margin: 8px 0;
      padding: 0;
      border: none;
      legend {
        font-size: to.rem(18px);
        font-weight: 700;
        color: $dkGray;
        margin-bottom: 12px;
      }
      p {
        font-size: 14px;
        line-height: 1.7;
        color: #333;
        margin-top: 0;
      }
      .checkbox,
      .radio {
        margin-bottom: 8px;
      }
      .error-msg {
        margin: 5px 0 0;
        font-size: 12px;
        color: $rose-red;
      }
    }
    input[type='range'] {
      width: 100%;
      margin: 6px 0;
      height: 4px;
      background-image: linear-gradient(
        to right,
        #398be7 calc(var(--value) * 1%),
        #ccc 0
      );
      -webkit-appearance: none;
    }
    input[type='range']:focus {
      outline: none;
    }
    input[type='range']::-webkit-slider-runnable-track {
      background-image: linear-gradient(
        to right,
        #398be7 calc(var(--value) * 1%),
        #ccc 0
      );
      border: 0;
      border-radius: 7px;
      width: 100%;
      height: 4px;
      cursor: pointer;
    }
    input[type='range']::-webkit-slider-thumb {
      margin-top: -6px;
      width: 16px;
      height: 16px;
      background: #398be7;
      border: 0;
      border-radius: 16px;
      cursor: pointer;
      -webkit-appearance: none;
    }
    input[type='range']:focus::-webkit-slider-runnable-track {
      background-image: linear-gradient(
        to right,
        #398be7 calc(var(--value) * 1%),
        #ccc 0
      );
    }
    input[type='range']::-moz-range-track {
      background-image: linear-gradient(
        to right,
        #398be7 calc(var(--value) * 1%),
        #ccc 0
      );
      border: 0;
      border-radius: 7px;
      width: 100%;
      height: 4px;
      cursor: pointer;
    }
    input[type='range']::-moz-range-thumb {
      width: 16px;
      height: 16px;
      background: #398be7;
      border: 0;
      border-radius: 16px;
      cursor: pointer;
    }
    input[type='range']::-ms-track {
      background: transparent;
      border-color: transparent;
      border-width: 7px 0;
      color: transparent;
      width: 100%;
      height: 4px;
      cursor: pointer;
    }
    input[type='range']::-ms-fill-lower {
      background: #398be7;
      border: 0;
      border-radius: 14px;
    }
    input[type='range']::-ms-fill-upper {
      background: #ccc;
      border: 0;
      border-radius: 14px;
    }
    input[type='range']::-ms-thumb {
      width: 16px;
      height: 16px;
      background: #398be7;
      border: 0;
      border-radius: 16px;
      cursor: pointer;
      margin-top: 0px;
    }
    input[type='range']:focus::-ms-fill-lower {
      background: #398be7;
    }
    input[type='range']:focus::-ms-fill-upper {
      background: #ccc;
    }
    @supports (-ms-ime-align: auto) {
      input[type='range'] {
        margin: 0;
      }
    }
    .minmax {
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
      font-size: 10px;
      line-height: 14px;
      font-weight: 700;
      color: #ccc;
      span {
        position: relative;
        width: 16px;
        display: flex;
        justify-content: center;
        &.numero {
          top: 1px;
          font-size: 14px;
          color: #333;
        }
      }
    }
    .radio label {
      width: auto;
    }
    .border-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 20;
      cursor: pointer;
      font-size: 18px !important;
      //   font-family: CircularStd;
      font-weight: 700;
      margin-bottom: 16px;
      transition: all 0.3s ease;
      span {
        color: #398be7;
        font-weight: 600;
        font-size: 14px;
        &[class^='icon-'],
        &[class*=' icon-'],
        [class^='icon-'],
        [class*=' icon-'] {
          font-weight: normal;
        }
      }
      &:hover {
        color: #155fb3;
        span {
          color: #155fb3;
        }
      }
    }
  }
  .sr-only {
    display: none;
  }
}
