// at-filter-planes.scss
.at-filter-planes {
  padding: 40px 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @include media-breakpoint-down(md) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @include media-breakpoint-up(xxl) {
    flex-direction: row;
    padding: 40px 0 48px;
  }
  .number {
    font-weight: 800;
    font-size: to.rem(21px);
    line-height: to.rem(23px);
    letter-spacing: to.rem(-0.38px);
    color: $dkGray;
    margin-bottom: 12px;
    @include media-breakpoint-down(md) {
      font-size: to.rem(18px);
      line-height: to.rem(20px);
      letter-spacing: to.rem(-0.19);
      margin-bottom: 0;
    }
    @include media-breakpoint-up(xxl) {
      margin-bottom: 0;
    }
  }
  .select {
    flex: 1;
    margin: 6px;
    max-width: calc(100% - 12px);
    min-width: calc(100% - 12px);
    @include media-breakpoint-up(sm) {
      max-width: calc(50% - 12px);
      min-width: calc(50% - 12px);
    }
    @include media-breakpoint-up(md) {
      min-width: 150px;
      max-width: 150px;
    }
    @include media-breakpoint-up(xxl) {
      margin: 0 0 0 12px;
    }
    &#select-category {
      max-width: calc(100% - 12px);
      min-width: calc(100% - 12px);
      @include media-breakpoint-up(md) {
        min-width: 150px;
        max-width: 150px;
      }
    }
    &#select-ubicacio {
      max-width: 100%;
      min-width: 220px;
    }
  }
  .filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .filtersmobile {
    display: flex;
    color: $dkGray;
    align-items: center;
    svg {
      width: 28px;
    }
    .chevron {
      margin: 0 2px 0 8px;
      transform: rotate(-90deg);
      width: 15px;
      transition: transform 0.2s ease-in-out;
      color: $at-primary;
    }
    &.showing {
      .chevron {
        transform: rotate(0deg);
      }
    }
  }
  @include media-breakpoint-down(md) {
    .calcChildSize {
      overflow: hidden;
      height: 0;
      transition: $animHeight;
      min-width: 100%;
      margin: 6px -6px 0;
      &.show {
        height: var(--calc-height);
      }
    }
  }
}
