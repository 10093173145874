// at-booking.scss
.at-booking {
  position: relative;
  .at-icon {
    position: absolute;
    height: 21px;
    top: 14px;
    right: 14px;
    color: $at-primary;
    pointer-events: none;
  }
  .form-group .error-msg {
    padding-left: 12px;
    font-weight: 700;
  }
  .rooms-input ~ .rooms-box .room-box_header {
    display: flex;
    box-shadow: none;
    position: relative;
    z-index: 30;
    box-sizing: border-box;
    padding: 10px 20px 0;
    margin: 20px 20px 0;
    @include media-breakpoint-up(md) {
      display: none;
    }
    .title {
      display: flex;
    }
  }
  @include media-breakpoint-down(md) {
    .rooms-box {
      z-index: 30;
    }
  }
}
.is-modal-dispo {
  @include media-breakpoint-down(sm) {
    .container-inner.ajustado {
      margin: 0;
    }
  }
  .rooms-input ~ .rooms-box {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99999;
    width: 100%;
    overflow-x: scroll;
    padding: 0;
    background: none;
    min-width: 100vw;
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    transform: none;
    //   max-height: 100vh;
    min-height: 100vh;
    &:before,
    &:after {
      position: fixed !important;
      transform: none !important;
      border: none !important;
    }
    &::before {
      z-index: 1;
      background: rgba(233, 233, 233, 0.97);
      display: flex;
      height: 100vh !important;
      width: 100vw !important;
      left: 0 !important;
      right: 0 !important;
      top: 0 !important;
      bottom: -30px !important;
      pointer-events: none;
    }
    &::after {
      z-index: 2;
      background: $white;
      border: 1px solid #c9c9c9;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
      padding: 50px 20px 20px;
      display: flex;
      height: calc(100vh + 60px) !important;
      width: calc(100vw - 40px) !important;
      left: 20px !important;
      right: 20px !important;
      top: 20px !important;
      bottom: -6px !important;
    }
    .rooms {
      background-color: #fff;
      margin: 0 20px 40px;
      padding: 20px;
      position: relative;
      z-index: 30;
    }
    .room-box_button {
      display: block;
    }
    .room-box_header {
      display: flex;
    }
  }
}
.datebig {
  .daterangepicker {
    border: none;
    border-radius: 0;
    box-shadow: none;
    left: 0 !important;
    margin: 0;
    max-height: 100vh;
    min-height: 100vh;
    min-width: 100vw;
    overflow-x: scroll;
    padding: 0;
    position: fixed;
    text-align: center;
    top: 0 !important;
    &.show-calendar {
      .drp-buttons {
        display: flex !important;
      }
      .drp-calendar {
        margin: 60px 40px 25px;
        min-width: calc(100% - 80px);
        position: relative;
        z-index: 20;
        @include media-breakpoint-up(md) {
          min-width: calc(50% - 60px);
          &.left {
            margin-right: 20px;
          }
          &.right {
            margin-left: 20px;
          }
          .calendar-table {
            .next {
              right: -20px;
            }
            .prev {
              left: -20px;
            }
          }
        }
      }
    }
  }
}
.at-booking-box {
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: -28px;
  margin-top: 30px;
  padding: 0 20px;
  @include media-breakpoint-up(md) {
    padding: 0 40px;
  }
  .reserva {
    margin: 10px 0 16px;
    font-weight: 700;
    color: $at-primary;
    text-decoration: underline;
    transition: $animColor;
    cursor: pointer;
    &:hover {
      color: $white;
    }
  }
  .form-group .form-input {
    border: 2px solid $at-primary;
    padding-right: 36px;
  }
}
.at-booking-home {
  margin-bottom: -20px;
  text-align: center;
  .heading {
    margin: 40px 0 6px;
    text-align: center;
    font-weight: 800;
    font-size: to.rem(20px);
    line-height: to.rem(24px);
    color: $dkGray;
  }
  .reserva {
    font-weight: 700;
    color: $at-primary;
    text-decoration: underline;
    transition: $animColor;
    cursor: pointer;
    &:hover {
      color: $dkGray;
    }
  }
}
.daterangepicker {
  position: absolute;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 20px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.27);
  background-color: $white;
  border-radius: 6px;
  padding: 27px 60px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-size: to.rem(16px);
  line-height: to.rem(18px);
  color: $dkGray;
  @include media-breakpoint-down(md) {
    min-width: 100vw;
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin: 20px;
    margin: 0;
    padding: 50px 20px 20px;
    padding: 0;
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    text-align: center;
    overflow-x: scroll;
    max-height: 100vh;
    min-height: 100vh;
    &:before,
    &:after {
      border: none !important;
    }
    &::before {
      z-index: 1;
      background: rgba(233, 233, 233, 0.97);
      display: flex;
      height: calc(100vh + 60px) !important;
      width: 100vw !important;
      left: 0 !important;
      right: 0 !important;
      top: 0 !important;
      bottom: -30px !important;
      pointer-events: none;
    }
    &::after {
      z-index: 2;
      background: $white;
      border: 1px solid #c9c9c9;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
      padding: 50px 20px 20px;
      display: flex;
      height: calc(100vh + 60px) !important;
      width: calc(100vw - 40px) !important;
      left: 20px !important;
      right: 20px !important;
      top: 20px !important;
      bottom: -6px !important;
    }
    &.show-calendar .drp-calendar {
      position: relative;
      z-index: 20;
      min-width: calc(100% - 80px);
      margin: 25px 40px;
      margin-top: 60px;
      &.right {
        margin-top: 30px;
        margin-bottom: 50px;
      }
      @include media-breakpoint-down(sm) {
        margin-top: 30px;
      }
    }
  }
  &:before,
  &:after {
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }
  &:before {
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
  }
  &:after {
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
  }
  &.opensleft:before {
    right: 9px;
  }
  &.opensleft:after {
    right: 10px;
  }
  &.openscenter:before {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
  }
  &.openscenter:after {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
  }
  &.opensright:before {
    left: 9px;
  }
  &.opensright:after {
    left: 10px;
  }
  &.drop-up {
    margin-top: -7px;
  }
  &.drop-up:before {
    top: initial;
    bottom: -7px;
    border-bottom: initial;
    border-top: 7px solid #ccc;
  }
  &.drop-up:after {
    top: initial;
    bottom: -6px;
    border-bottom: initial;
    border-top: 6px solid #fff;
  }
  &.single & .ranges,
  &.single .drp-calendar {
    float: none;
  }
  &.single .drp-selected {
    display: none;
  }
  &.show-calendar .drp-calendar {
    display: block;
  }
  &.show-calendar .drp-buttons {
    display: flex;
  }
  &.auto-apply .drp-buttons {
    display: none;
  }
  .drp-calendar {
    display: none;
    max-width: 270px;
    padding: 0 12px;
  }
  //   .drp-calendar.left {
  //     padding: 8px 0 8px 8px;
  //   }
  //   .drp-calendar.right {
  //     padding: 8px;
  //   }
  .drp-calendar.single .calendar-table {
    border: none;
  }
  .calendar-table {
    .next,
    .prev {
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 20px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #4d4d4d;
      transition: $animBgColor;
      @include media-breakpoint-down(md) {
        left: 30px;
        top: 50vh;
        position: fixed;
        z-index: 50;
      }
      @include media-breakpoint-down(sm) {
        left: 25px;
      }
      span {
        border: 1px solid #fff;
        border-radius: 2px;
        border-width: 0 4px 4px 0;
        display: inline-block;
        padding: 3px;
        width: 15px;
        height: 15px;
        transform: rotate(135deg);
        transition: $animBorderColor;
        position: relative;
        right: -2px;
      }
      &.next {
        left: auto;
        right: 20px;
        @include media-breakpoint-down(md) {
          right: 30px;
        }
        @include media-breakpoint-down(sm) {
          right: 25px;
        }
        span {
          transform: rotate(-45deg);
          right: inherit;
          left: -2px;
        }
      }
      &:hover {
        background-color: $ltGray;
        span {
          border-color: $dkGray;
        }
      }
    }
  }
  .calendar-table th,
  .calendar-table td {
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    width: 38px;
    height: 38px;
    line-height: 38px;
    font-size: to.rem(16px);
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid transparent;
    white-space: nowrap;
    cursor: pointer;
  }
  //   .calendar-table {
  //     border: 1px solid #fff;
  //     border-radius: 4px;
  //     background-color: #fff;
  //   }
  .calendar-table table {
    width: 100%;
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;
  }
  td.available:hover,
  th.available:hover {
    background-color: #eee;
    border-color: transparent;
    color: inherit;
  }
  td.available {
    position: relative;
    &:after {
      content: attr(data-price);
      display: block;
      font-size: small;
      font-size: 10px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      position: absolute;
      bottom: 6px;
      width: 100%;
      color: #a5a5a5;
    }
  }
  td.price {
    padding-top: 1px;
    position: relative;
    vertical-align: top;
    line-height: 27px;
    height: 40px;
    &:after {
      content: attr(data-price);
      display: block;
      font-size: small;
      font-size: 10px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      position: absolute;
      bottom: 1px;
      width: 100%;
      color: #a5a5a5;
    }
  }
  td.disabled {
    padding-top: 4px;
    vertical-align: top;
    &:after {
      display: none;
    }
  }
  td.offer {
    &:after {
      color: #0f9d58;
    }
  }
  td.week,
  th.week {
    font-size: 80%;
    color: #ccc;
  }
  td.off,
  td.off.in-range,
  td.off.start-date,
  td.off.end-date {
    background-color: $white;
    border-color: transparent;
    color: #999;
  }
  td.in-range {
    background-color: #dcebfa;
    border-color: transparent;
    color: #000;
    border-radius: 0;
  }
  td.start-date {
    border-radius: 4px 0 0 4px;
  }
  td.end-date {
    border-radius: 0 4px 4px 0;
  }
  td.start-date.end-date {
    border-radius: 4px;
  }
  td.today {
    color: $at-primary;
  }
  td.active,
  td.active:hover {
    background-color: $at-primary;
    border-color: transparent;
    color: $white;
    &::after {
      color: $white;
    }
  }
  thead {
    font-weight: normal;
    font-size: to.rem(14px);
    color: rgba(128, 128, 128, 0.8);
    th {
      font-weight: normal !important;
      font-size: to.rem(14px) !important;
      &.month {
        font-weight: bold !important;
        font-size: to.rem(21px) !important;
        @include media-breakpoint-down(sm) {
          font-size: to.rem(18px) !important;
        }
      }
    }
  }
  th.month {
    width: auto;
    text-transform: uppercase;
    font-weight: bold;
    font-size: to.rem(21px) !important;
    color: $dkGray;
  }
  td.disabled,
  option.disabled {
    color: $ltGray;
    cursor: not-allowed;
    text-decoration: line-through;
  }
  select.monthselect,
  select.yearselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default;
  }
  select.monthselect {
    margin-right: 2%;
    width: 56%;
  }
  select.yearselect {
    width: 40%;
  }
  select.hourselect,
  select.minuteselect,
  select.secondselect,
  select.ampmselect {
    width: 50px;
    margin: 0 auto;
    background: #eee;
    border: 1px solid #eee;
    padding: 2px;
    outline: 0;
    font-size: 12px;
  }
  .calendar-time {
    text-align: center;
    margin: 4px auto 0 auto;
    line-height: 30px;
    position: relative;
  }
  .calendar-time select.disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  .drp-buttons {
    clear: both;
    text-align: right;
    padding: 8px;
    border-top: 1px solid #ddd;
    display: none;
    line-height: 12px;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    z-index: 20;
    bottom: 0;
    background-color: $white;
    width: 100vw;
    box-sizing: border-box;
  }
  .drp-selected {
    display: inline-block;
    font-size: to.rem(14px);
    font-weight: bold;
    padding-right: 2px;
  }
  .drp-buttons .btn {
    margin-left: 8px;
    margin-right: 8px;
    font-size: to.rem(14px);
    padding: 4px 10px;
    &.cancelBtn {
      padding: 0;
      margin: 0;
    }
    svg {
      position: fixed;
      top: 30px;
      right: 30px;
      width: 37px;
      height: 37px;
      @include media-breakpoint-down(sm) {
        top: 20px;
        right: 20px;
      }
      path {
        fill: #a7a7a7;
        transition: $animFill;
      }
    }
  }
  &.show-ranges.single.rtl .drp-calendar.left {
    border-right: 1px solid #ddd;
  }
  &.show-ranges.single.ltr .drp-calendar.left {
    border-left: 1px solid #ddd;
  }
  &.show-ranges.rtl .drp-calendar.right {
    border-right: 1px solid #ddd;
  }
  &.show-ranges.ltr .drp-calendar.left {
    border-left: 1px solid #ddd;
  }
  .ranges {
    float: none;
    text-align: left;
    margin: 0;
  }
  &.show-calendar .ranges {
    margin-top: 8px;
  }
  .ranges ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;
  }
  .ranges li {
    font-size: 12px;
    padding: 8px 12px;
    cursor: pointer;
  }
  .ranges li:hover {
    background-color: #eee;
  }
  .ranges li.active {
    background-color: $at-primary;
    color: $white;
  }
  /*  Larger Screen Styling */
  @include media-breakpoint-up(md) {
    width: auto;
    direction: ltr;
    text-align: left;
    .ranges ul {
      width: 140px;
    }
    &.single .ranges ul {
      width: 100%;
    }
    &.single .drp-calendar.left {
      clear: none;
    }
    &.single .ranges,
    &.single .drp-calendar {
      float: left;
    }
    .drp-calendar.left {
      clear: left;
      margin-right: 0;
    }
    .drp-calendar.left .calendar-table {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .drp-calendar.right {
      margin-left: 0;
    }
    .drp-calendar.right .calendar-table {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &.drp-calendar.left .calendar-table {
      padding-right: 8px;
    }
    .ranges,
    .drp-calendar {
      float: left;
    }
  }
  @media (min-width: 730px) {
    .ranges {
      width: auto;
    }
    .ranges {
      float: left;
    }
    &.rtl .ranges {
      float: right;
    }
    .drp-calendar.left {
      clear: none !important;
    }
  }
}
.is-modal-suggest {
  .input-container {
    position: relative;
    .fa-map-marker-alt {
      position: absolute;
      right: 12px;
      top: 13px;
      height: 21px;
      color: $at-primary;
    }
    .form-input {
      border: 2px solid #398be7;
      padding-right: 36px;
      &:focus {
        outline: none;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.27);
      }
    }
  }
  .suggestion-input ~ .suggestion-box {
    height: 0;
    transition: $animHeight;
    .d-flex {
      flex-direction: column;
    }
  }
  .modal-at-inner {
    min-width: calc(100% - 40px);
  }
  &.suggest-open {
    .suggestion-input ~ .suggestion-box {
      background-color: #fff;
      border-radius: 0;
      bottom: auto;
      box-shadow: none;
      margin-top: 12px;
      padding: 0;
      position: relative;
      width: auto;
      display: block;
      height: 100%;
      &::before {
        display: none;
      }
      .citys,
      .hotels {
        margin: 0;
        width: 100%;
      }
    }
  }
}
