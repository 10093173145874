.info-img {
  position: absolute;
  left: 32px;
  bottom: 0;
  z-index: 300;
  @include media-breakpoint-down(sm) {
    left: 23px;
  }
  .info-trigger {
    cursor: pointer;
    svg {
      color: rgba($white, 0.65);
      width: 32px;
      height: 32px;
      filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
      transition: $animColor;
      position: relative;
      z-index: 10;
    }
    &.onHover {
      svg {
        color: rgba($white, 1);
      }
      .info-box {
        opacity: 1;
        visibility: visible;
      }
    }
    .info-box {
      position: absolute;
      left: -8px;
      bottom: 45px;
      min-width: 452px;
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.27);
      background-color: $white;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      padding: 5px;
      font-size: to.rem(14px);
      line-height: to.rem(21px);
      letter-spacing: to.rem(-0.21px);
      color: $dkGray;
      z-index: 10;
      cursor: default;
      opacity: 0;
      visibility: hidden;
      transition: $animAll;
      @include media-breakpoint-down(xl) {
        max-width: 100%;
      }
      @include media-breakpoint-down(sm) {
        max-width: calc(100vw - 30px);
        min-width: calc(100vw - 30px);
      }
      &::before {
        border-color: $white transparent transparent;
        border-style: solid;
        border-width: 11px 9px 0;
        content: '';
        height: 0;
        width: 0;
        position: absolute;
        left: 15px;
        bottom: -11px;
        z-index: 10;
      }
      &::after {
        content: '';
        height: calc(100% + 52px);
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 5;
      }
      p {
        padding: 10px 15px;
        margin: 0;
        font-size: to.rem(14px);
        line-height: to.rem(21px);
        letter-spacing: to.rem(-0.21px);
        color: $dkGray;
        position: relative;
        z-index: 10;
        @include media-breakpoint-down(lg) {
          font-size: to.rem(12px);
          line-height: to.rem(18px);
          letter-spacing: to.rem(-0.18px);
        }
      }
      a {
        font-weight: 700;
      }
      .img-info {
        display: none;
        @include media-breakpoint-down(xl) {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .info-close {
        position: absolute;
        top: 7px;
        right: 9px;
        filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
        color: $white;
        display: none;
        z-index: 40;
        cursor: pointer;
        @include media-breakpoint-down(xl) {
          display: block;
        }
        svg {
          color: $white;
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}
.slider-top {
  .info-img {
    bottom: 100px;
  }
}
.slider-activ-wrap {
  .info-img {
    bottom: -22px;
  }
}
.slider-contabs-wrap {
  .info-img {
    bottom: 116px;
    @include media-breakpoint-down(xl) {
      .info-trigger .info-box {
        bottom: auto;
        top: 45px;
        left: calc(-50vw + 30px);
        left: 15px;
        transform: translateX(-50%);
        &:before {
          bottom: auto;
          top: -9px;
          left: 50%;
          margin-left: -9px;
          border-color: transparent transparent #fff transparent;
          border-width: 0 11px 9px 9px;
        }
      }
      bottom: auto;
      top: 160px;
      left: 50%;
      margin-left: -16px;
    }
    @include media-breakpoint-down(lg) {
      .info-box {
        min-width: 394px;
      }
    }
    @include media-breakpoint-down(sm) {
      .info-box {
        max-width: calc(100vw - 90px);
        min-width: calc(100vw - 90px);
      }
    }
  }
}
