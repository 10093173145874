// at-planificador.scss
.at-planificador {
  position: relative;
  @include media-breakpoint-up(xxl) {
    position: relative;
  }
}
.new-list-empty,
.new-list {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 6px dashed $at-primary;
  font-weight: 800;
  font-size: to.rem(21px);
  line-height: to.rem(24px);
  letter-spacing: to.rem(1.26px);
  min-height: 96px;
  padding-right: 12px;
  padding-left: 12px;
  transition: $animAll;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  &:hover {
    border-color: $blue-dark;
  }
}
.new-list {
  height: 96px;
  min-height: 100%;
}
.at-planifica-contenido {
  .ruta {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 35px;
    border-radius: 11px;
    background: rgba(255, 255, 255, 0.35);
    border: 5px solid $white;
    padding: 30px 0 0 0;
    margin-bottom: 35px;
    @include media-breakpoint-up(lg) {
      margin-top: 55px;
      margin-bottom: 55px;
    }
    svg {
      height: 36px;
      color: $at-primary;
    }
    .places,
    .path {
      display: flex;
      justify-content: center;
      .place {
        display: flex;
        flex: 2;
        font-weight: 800;
        font-size: to.rem(24px);
        line-height: to.rem(24px);
        letter-spacing: to.rem(-0.3px);
        color: $dkGray;
        margin: 0 16px;
        @include media-breakpoint-up(sm) {
          font-size: to.rem(32px);
          line-height: to.rem(32px);
        }
        @include media-breakpoint-up(lg) {
          font-size: to.rem(42px);
          line-height: to.rem(42px);
          letter-spacing: to.rem(-0.63px);
        }
        &:first-of-type {
          justify-content: flex-end;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      .places {
        flex-direction: column;
        padding-left: 54px;
        .place {
          justify-content: flex-start !important;
          margin: 0 8px 32px 0;
        }
      }
    }
    .path {
      display: flex;
      margin: 20px 0 24px;
      @include media-breakpoint-down(sm) {
        margin: 0;
        padding: 8px 8px 4px;
        background: $white;
      }
      p {
        margin: 0;
        font-weight: 700;
        font-size: to.rem(21px);
        line-height: to.rem(30px);
        letter-spacing: to.rem(-0.31px);
        color: #4d4d4d;
        position: relative;
        padding-right: 30px;
        text-transform: uppercase;
        @include media-breakpoint-down(sm) {
          font-size: to.rem(12px);
          line-height: to.rem(20px);
          padding-right: 24px;
        }
        &::after {
          content: '|';
          position: absolute;
          right: 13px;
          top: -1px;
          font-weight: normal;
          font-size: to.rem(30px);
          color: $at-primary;
          @include media-breakpoint-down(sm) {
            right: 9px;
            top: 0;
            font-size: to.rem(18px);
          }
        }
        &:last-of-type {
          padding-right: 0;
          &::after {
            display: none;
          }
        }
      }
      .number {
        position: relative;
        top: 1px;
        font-size: to.rem(30px);
        letter-spacing: to.rem(-0.45px);
        color: #4d4d4d;
        @include media-breakpoint-down(sm) {
          font-size: to.rem(18px);
        }
      }
    }
    .icons {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      width: 80px;
      @include media-breakpoint-up(lg) {
        width: 130px;
      }
      @include media-breakpoint-down(sm) {
        position: absolute;
        left: 20px;
        top: 29px;
        flex-direction: column;
        width: 30px;
        height: 51%;
        box-sizing: border-box;
      }
      &::before {
        content: '';
        position: absolute;
        width: 72%;
        left: 50%;
        top: 31px;
        transform: translateX(-50%);
        border-top: 3px dashed $at-primary;
        @include media-breakpoint-up(lg) {
          width: 82%;
        }
        @include media-breakpoint-down(sm) {
          width: auto;
          left: calc(50% - 1px);
          top: 50%;
          transform: translate(-50%, -50%);
          height: 24%;
          border-top: none;
          border-left: 3px dashed $at-primary;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .subtitulo {
      font-size: to.rem(18px);
      line-height: to.rem(30px);
    }
  }
  .nav-interno {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    min-height: 48px;
    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }
    @include media-breakpoint-up(xl) {
      align-items: center;
      min-height: 68px;
    }
    &::before,
    &::after {
      background: linear-gradient(
        270deg,
        rgba(233, 233, 233, 1),
        rgba(233, 233, 233, 0)
      );
      height: 100%;
      position: absolute;
      right: -1.5rem;
      bottom: 0;
      top: 0;
      width: 1.5rem;
      z-index: 5;
      @include media-breakpoint-down(md) {
        content: '';
        display: block;
      }
    }
    &::before {
      right: inherit;
      left: -1.5rem;
      background: linear-gradient(
        90deg,
        rgba(233, 233, 233, 1),
        rgba(233, 233, 233, 0)
      );
    }
    .menu {
      display: flex;
      margin: 0;
      padding: 0 1.3rem;
      list-style: none;
      position: absolute;
      min-width: 100vw;
      left: -1.3rem;
      max-width: 100%;
      overflow: auto;
      @include media-breakpoint-up(md) {
        margin: 20px 0 0;
        padding: 0;
        bottom: -60px;
        left: auto;
        min-width: auto;
        overflow: visible;
        position: relative;
        bottom: inherit;
      }
      li {
        display: flex;
        flex: 0 0 auto;
      }
      a {
        position: relative;
        font-weight: 800;
        font-size: to.rem(19px);
        line-height: to.rem(24px);
        letter-spacing: to.rem(-0.24px);
        text-decoration: none;
        color: #8d8d8d;
        padding: 14px;
        margin-right: 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        text-transform: inherit;
        margin: 0;
        &::after {
          display: none;
        }
        @include media-breakpoint-up(md) {
          font-size: to.rem(20px);
        }
        @include media-breakpoint-up(xl) {
          font-size: to.rem(24px);
          padding: 22px 30px;
        }
        &.current {
          color: $dkGray !important;
          background: $white !important;
        }
        &:hover {
          color: $at-primary;
        }
      }
    }
  }
  .dies {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      height: calc(100% - 62px);
      left: -8px;
      top: 62px;
      bottom: 0;
      border-left: 4px dashed #e9e9e9;
      @include media-breakpoint-up(sm) {
        left: -16px;
      }
      @include media-breakpoint-up(md) {
        left: -47px;
        height: calc(100% - 94px);
        top: 94px;
        border-left: 6px dashed #e9e9e9;
      }
      @include media-breakpoint-up(lg) {
        left: -16px;
      }
      @include media-breakpoint-up(xl) {
        left: -13px;
      }
      @include media-breakpoint-up(xxl) {
        left: 0;
      }
    }
    .dia-planificador {
      position: relative;
      padding: 48px 0 10px;
      @include media-breakpoint-up(md) {
        padding: 78px 0 10px;
      }
      &::before {
        content: '';
        position: absolute;
        left: -26px;
        top: 53px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: $at-primary;
        @include media-breakpoint-up(md) {
          left: -58px;
          top: 85px;
          width: 30px;
          height: 30px;
        }
      }
      .info {
        margin-bottom: 40px;
        .title {
          @include media-breakpoint-down(md) {
            margin-bottom: 12px;
          }
          .nactivities {
            font-weight: 800;
            font-size: to.rem(18px);
            letter-spacing: to.rem(-0.24px);
            color: #8d8d8d;
            @include media-breakpoint-up(md) {
              font-size: to.rem(24px);
            }
          }
        }
        p {
          font-size: to.rem(16px);
          line-height: to.rem(26px);
          letter-spacing: to.rem(-0.45px);
          color: #4d4d4d;
          @include media-breakpoint-up(md) {
            font-size: to.rem(18px);
            line-height: to.rem(30px);
          }
        }
      }
      .dia-activitat {
        position: relative;
        background: #e9e9e9;
        display: flex;
        margin-bottom: 16px;
        font-size: 0;
        padding: 20px 5px;
        left: -1px;
        @include media-breakpoint-up(md) {
          left: 0;
          padding: 38px 26px;
        }
        &::before {
          content: '';
          position: absolute;
          left: -40px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 50%;
          background: $white;
          border: 4px solid $at-primary;
          width: 16px;
          height: 16px;
          @include media-breakpoint-down(md) {
            left: -11px;
          }
        }
        &::after {
          content: '';
          position: absolute;
          left: -17px;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 18px 17px 18px 0;
          border-color: transparent #e9e9e9 transparent transparent;
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        &:nth-child(even) {
          background: #f5f5f5;
          &::after {
            border-color: transparent #f5f5f5 transparent transparent;
          }
        }
        .image {
          picture,
          img {
            max-width: 100%;
            min-width: 100%;
            object-fit: cover;
            min-height: 100%;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          font-size: to.rem(16px);
          line-height: to.rem(23px);
          color: to.rem(#4d4d4d);
          margin-bottom: 0;
          align-items: flex-start;
          p {
            font-size: to.rem(16px);
            line-height: to.rem(23px);
            margin: 0;
          }
          .tags {
            order: 1;
            display: flex;
            font-weight: 700;
            font-size: to.rem(12px);
            line-height: to.rem(20px);
            letter-spacing: to.rem(0.72px);
            color: #d9d9d9;
            background-color: #8d8d8d;
            padding: 0 5px;
            text-transform: uppercase;
            margin-top: 12px;
            @include media-breakpoint-up(xl) {
              margin-top: 0;
            }
          }
          .title {
            order: 2;
            margin: 8px 0;
            a {
              font-weight: 800;
              font-size: to.rem(24px);
              line-height: to.rem(28px);
              letter-spacing: to.rem(-0.36px);
              color: $dkGray;
              text-decoration: none;
              &:hover {
                color: $at-primary;
              }
              @include media-breakpoint-up(md) {
                font-size: to.rem(32px);
                line-height: to.rem(36px);
                letter-spacing: to.rem(-0.48px);
              }
            }
          }
          .ezrichtext-field {
            order: 3;
          }
          .place {
            order: 4;
            display: flex;
            align-items: flex-start;
            font-weight: 700;
            font-size: to.rem(16px);
            line-height: to.rem(20px);
            color: #4d4d4d;
            margin: 8px 0 0;
            span {
              margin-top: 4px;
            }
            svg {
              height: 24px;
              min-width: 21px;
              margin-right: 4px;
              color: $at-primary;
            }
          }
        }
      }
      .title-aprop {
        display: flex;
        margin: 24px 0 6px;
        font-weight: 800;
        font-size: to.rem(32px);
        line-height: to.rem(38px);
        letter-spacing: to.rem(-0.42px);
        color: $dkGray;
      }
      .at-grid-featured {
        margin-bottom: 48px;
      }
    }
  }
  &.at-planifica-contenido-crear {
    .dies .dia-planificador {
      display: flex;
      flex-wrap: wrap;
      @include media-breakpoint-down(md) {
        .info {
          margin-bottom: 20px;
        }
      }
      .dia-activitat {
        position: relative;
        border: 4px solid transparent;
        transition: $animBorderColor;
        left: -4px;
        @include media-breakpoint-down(md) {
          left: 0;
          border: 0;
        }
        &:hover {
          border-color: $at-primary;
          .buttons {
            opacity: 1;
          }
        }
        .description {
          order: 4;
        }
        .buttons {
          position: absolute;
          top: -3px;
          right: calc(-0.75rem - 3px);
          width: auto;
          opacity: 0;
          transition: $animOpacity;
          @include media-breakpoint-down(lg) {
            opacity: 1;
          }
          .btn {
            margin-left: 1px;
          }
        }
        .title {
          margin-top: 26px;
        }
      }
    }
    .info {
      order: 1;
    }
    .d-flex.flex-column {
      flex: 2;
      min-width: 100%;
      order: 3;
    }
    .addactivity,
    .addday {
      flex: 2;
      min-width: 100%;
      order: 4;
      border: 4px dashed $at-primary;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 96px;
      padding: 8px;
      box-sizing: border-box;
      position: relative;
      font-weight: 800;
      font-size: to.rem(21px);
      line-height: to.rem(24px);
      letter-spacing: to.rem(1.26px);
      color: $at-primary;
      border-radius: 0;
      z-index: 3;
      transition: $animColor;
      @include media-breakpoint-up(md) {
        border: 6px dashed $at-primary;
      }
      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 6px;
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        background-color: #eff6fc;
        transition: $animBgColor;
        z-index: -1;
        @include media-breakpoint-up(md) {
          top: 4px;
          left: 4px;
          width: calc(100% - 8px);
          height: calc(100% - 8px);
        }
      }
      &:hover {
        color: $white;
        &::before {
          background-color: $at-primary;
        }
      }
    }
    .addday {
      border: 4px dashed #e9e9e9;
      color: $white;
      transition: $animAll;
      flex: initial;
      margin: 40px 0 50px;
      min-width: auto;
      @include media-breakpoint-up(md) {
        border: 6px dashed #e9e9e9;
      }
      &::before {
        background-color: #e9e9e9;
      }
      &::after {
        content: '';
        position: absolute;
        left: -31px;
        top: 31px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: #e9e9e9;
        @include media-breakpoint-up(md) {
          left: -64px;
          top: 29px;
          width: 30px;
          height: 30px;
        }
      }
      &:hover {
        color: $white;
        border-color: $at-primary;
        &::before {
          background-color: $at-primary;
        }
      }
    }
    .buttons {
      position: relative;
      top: -1px;
      order: 2;
      margin-left: 14px;
      font-size: 0;
      @include media-breakpoint-up(md) {
        top: -7px;
      }
      .btn {
        background: $at-primary;
        color: $white;
        margin: 0 2px;
        @include media-breakpoint-down(md) {
          padding: 10px;
        }
        &:hover {
          background: $white;
          color: $at-primary;
          border-color: $at-primary;
        }
      }
      svg {
        height: 20px;
        @include media-breakpoint-up(md) {
          height: 28px;
        }
      }
    }
    .subtitulo {
      margin-bottom: 90px;
      padding-top: 0;
    }
  }
}
.plan-sticky-message {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  width: 100%;
  background: $dkGray;
  padding: 12px 0;
  z-index: 10;
  transition: $animAll;
  @include media-breakpoint-up(md) {
    padding: 22px 0;
  }
  &::before {
    position: absolute;
    content: '';
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    height: 15px;
    top: -15px;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 11;
  }
  .logging-msj,
  p {
    margin: 0;
    color: $white;
    min-height: 100%;
    display: flex;
    align-items: center;
    line-height: 26px;
    @include media-breakpoint-down(md) {
      font-size: to.rem(14px);
      line-height: to.rem(18px);
      padding-bottom: 12px;
    }
  }
  @include media-breakpoint-down(md) {
    .btn {
      font-size: to.rem(12px);
      line-height: to.rem(14px);
      letter-spacing: to.rem(0.18px);
      padding: 10px 14px;
    }
  }
}
.header-not-at-top {
  .plan-sticky-message {
    bottom: 0;
  }
  .footer.planifica {
    padding-bottom: var(--sticky-message-height);
  }
}
.at-planifica-banner {
  position: relative;
  min-height: 370px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 50px 0;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    width: 100%;
    height: 15px;
    pointer-events: none;
    z-index: 6;
  }
  &::after {
    top: auto;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .container {
    position: relative;
    z-index: 10;
  }
  .img-bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    min-height: 100%;
    z-index: 5;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        rgba(0, 70, 148, 1) 30%,
        rgba(57, 139, 231, 1) 79.35%
      );
      opacity: 0.8;
    }
    img {
      position: absolute;
      object-fit: cover;
      max-width: 100%;
      min-height: 100%;
    }
  }
  .col-md-8 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include media-breakpoint-up(md) {
      align-items: flex-start;
      text-align: left;
    }
  }
  .heading {
    font-weight: 800;
    font-size: to.rem(30px);
    line-height: to.rem(36px);
    letter-spacing: to.rem(-0.54px);
    color: $white;
    @include media-breakpoint-up(md) {
      font-size: to.rem(36px);
      line-height: to.rem(42px);
    }
    @include media-breakpoint-up(xxl) {
      font-size: to.rem(42px);
      line-height: to.rem(52px);
      letter-spacing: to.rem(-0.76px);
    }
  }
  .parrafo {
    font-size: to.rem(18px);
    line-height: to.rem(28px);
    color: $white;
    margin: 12px 0 32px;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
  .caja {
    border: 6px dashed #e9e9e9;
    height: 186px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      margin: 20px;
    }
  }
}
.is-modal-plan-nombre {
  .textswitch {
    margin-top: 28px;
  }
  .switch {
    position: relative;
    border-radius: 6px;
    border: solid 1px #b3b3b3;
    height: auto;
    padding: 0;
    overflow: hidden;
    width: auto;
    .switch-label {
      position: relative;
      z-index: 2;
      width: 50%;
      height: 100%;
      letter-spacing: normal;
      color: #a7a7a7;
      background-color: transparent;
      text-align: center;
      cursor: pointer;
      font-size: to.rem(16px);
      line-height: to.rem(16px);
      letter-spacing: to.rem(-0.36px);
      font-weight: 800;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 15px !important;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      @include media-breakpoint-up(md) {
        font-size: to.rem(18px);
        padding: 16px 30px !important;
      }
      &::before,
      &::after {
        display: none;
      }
      &:last-of-type {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
    .switch-input:checked + .switch-label {
      color: $white !important;
      border-color: transparent;
    }
    .switch-selection {
      background-color: $at-primary;
      height: 100%;
      left: 0;
      top: 0;
      width: 50%;
      border-radius: 0;
    }
    .switch-input:checked + .switch-label-on ~ .switch-selection {
      transform: translateX(calc(100% + 1px));
    }
  }
}
.is-modal-buscar-planificador {
  .container {
    .container {
      padding-top: 20px !important;
    }
  }
}
.result-activities {
  .dia-activitat-buscador {
    position: relative;
    background: #f5f5f5;
    display: flex;
    margin-bottom: 16px;
    font-size: 0;
    padding: 20px 5px;
    left: -1px;
    @include media-breakpoint-up(md) {
      left: 0;
      padding: 38px 26px;
    }
    .image {
      picture,
      img {
        max-width: 100%;
        min-width: 100%;
        object-fit: cover;
        min-height: 100%;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      font-size: to.rem(16px);
      line-height: to.rem(23px);
      color: to.rem(#4d4d4d);
      margin-bottom: 0;
      align-items: flex-start;
      margin-bottom: 60px;
      p {
        font-size: to.rem(16px);
        line-height: to.rem(23px);
        margin: 0;
      }
      .title {
        margin: 8px 0;
        @include media-breakpoint-up(md) {
          margin: 0 0 4px;
        }
        a {
          font-weight: 800;
          font-size: to.rem(24px);
          line-height: to.rem(28px);
          letter-spacing: to.rem(-0.36px);
          color: $dkGray;
          text-decoration: none;
          &:hover {
            color: $at-primary;
          }
          @include media-breakpoint-up(md) {
            font-size: to.rem(32px);
            line-height: to.rem(36px);
            letter-spacing: to.rem(-0.48px);
          }
        }
      }
      .place {
        display: flex;
        align-items: flex-start;
        font-weight: 700;
        font-size: to.rem(16px);
        line-height: to.rem(20px);
        color: #4d4d4d;
        margin: 8px 0 0;
        span {
          margin-top: 4px;
        }
        svg {
          height: 24px;
          min-width: 21px;
          margin-right: 4px;
          color: $at-primary;
        }
      }
    }
    .btn {
      position: absolute;
      right: 20px;
      bottom: 20px;
      width: auto;
      @include media-breakpoint-up(md) {
        right: 26px;
        bottom: 26px;
      }
    }
  }
}
