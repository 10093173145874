fieldset.birthdate {
  border: 1px solid #d7dcde;
  border-radius: 6px;
  padding: 0;
  margin: 0;
  position: relative;
  cursor: text;
  background-color: #fff;
  &.has-error {
    background-color: #fee;
    border-color: red;
  }
  legend {
    padding: 0;
  }
  input {
    background-color: transparent;
    &:active,
    &:focus {
      border: none;
      background: none;
      outline: 0;
    }
  }
  &.has-float-label legend {
    pointer-events: none;
    position: absolute;
    font-weight: 700;
    font-size: to.rem(12px);
    letter-spacing: to.rem(0.96px);
    color: #a7a7a7;
    left: 12px;
    top: 4px;
    text-transform: uppercase;
    padding: 0;
  }
  &.has-float-label input {
    border: 0;
    width: 45px;
    text-align: center;
    padding: 24px 5px 3px 5px;
    &[data-type='day'] {
      width: 42px;
      padding-left: 12px;
    }
    &[data-type='year'] {
      width: 62px;
    }
  }
  .separator {
    position: relative;
    pointer-events: none;
    cursor: text;
    &::before {
      content: '/';
      position: absolute;
      top: -2px;
      left: -5px;
    }
  }
  &:not(.has-value):not(.is-focused) {
    input,
    .separator {
      opacity: 0;
    }
  }
}
