// intro-detalle.scss
.intro-detalle {
  display: flex;
  align-items: flex-end;
  min-height: 500px;
  position: relative;
  margin-bottom: 40px;
  &::after {
    content: '';
    position: absolute;
    background: #e9e9e9;
    height: 42px;
    width: 100%;
    left: 0;
    bottom: -40px;
  }
  @include media-breakpoint-up(md) {
    min-height: 514px;
    margin-bottom: 0;
    &::after {
      display: none;
    }
  }
  .container {
    position: relative;
    z-index: 10;
    padding-top: 178px;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title {
      @extend %intro-h1;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }
      > * {
        @extend %intro-h1;
        @include media-breakpoint-down(md) {
          margin-bottom: 0;
        }
      }
    }
    .sobretitle {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 8px;
      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
      }
      .stars {
        .star {
          height: 21px;
          @include media-breakpoint-up(md) {
            height: 28px;
          }
        }
        svg {
          height: 21px;
          margin: 1px;
          filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.6));
          @include media-breakpoint-up(md) {
            height: 28px;
            margin: 1px 2px;
          }
        }
      }
      .btn {
        display: flex;
        align-items: center;
        font-size: to.rem(12px);
        padding: 7px 14px;
        margin: 0 0 10px 0;
        @include media-breakpoint-up(md) {
          font-size: to.rem(16px);
          padding: 12px 18px;
          margin: 0 0 0 16px;
        }
        svg {
          height: 18px;
          margin-right: 5px;
          @include media-breakpoint-up(md) {
            height: 24px;
          }
        }
      }
    }
    .cat {
      font-size: to.rem(14px);
      line-height: to.rem(28px);
      letter-spacing: to.rem(1.08px);
      font-weight: 400;
      color: $white;
      margin-bottom: 6px;
      @include media-breakpoint-up(md) {
        font-size: to.rem(16px);
        line-height: to.rem(30px);
      }
      @include media-breakpoint-up(xl) {
        font-size: to.rem(18px);
        line-height: to.rem(33px);
      }
    }
  }
  .nav-interno {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    min-height: 48px;
    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }
    @include media-breakpoint-up(xl) {
      align-items: center;
      min-height: 68px;
    }
    &::after {
      background: linear-gradient(
        270deg,
        rgba(233, 233, 233, 1),
        rgba(233, 233, 233, 0)
      );
      height: 100%;
      position: absolute;
      right: -2px;
      bottom: -60px;
      width: 32px;
      @include media-breakpoint-down(md) {
        content: '';
        display: block;
      }
    }
    .menu {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      position: absolute;
      left: 0;
      bottom: -60px;
      max-width: 100%;
      overflow: auto;
      @include media-breakpoint-up(md) {
        min-width: auto;
        overflow: visible;
        position: relative;
        bottom: inherit;
      }
      li {
        display: flex;
        flex: 0 0 auto;
      }
      a {
        position: relative;
        font-weight: 800;
        font-size: to.rem(19px);
        line-height: to.rem(24px);
        letter-spacing: to.rem(-0.24px);
        text-decoration: none;
        color: #808080;
        padding: 10px 0;
        margin-right: 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        text-transform: uppercase;
        &::after {
          position: absolute;
          left: 0;
          bottom: 3px;
          width: 100%;
          height: 4px;
          background: #2b2b2b;
        }
        @include media-breakpoint-up(md) {
          margin: 0;
          text-transform: inherit;
          font-size: to.rem(20px);
          padding: 14px;
          color: $white;
        }
        @include media-breakpoint-up(xl) {
          font-size: to.rem(24px);
          padding: 22px 30px;
        }
        &.current {
          color: $dkGray !important;
          &::after {
            content: '';
          }
          @include media-breakpoint-up(md) {
            background: #e9e9e9 !important;
            &::after {
              display: none;
            }
          }
        }
        &:hover {
          color: $at-primary;
        }
      }
    }
    .tools {
      display: flex;
      .comparte,
      .favorito {
        position: relative;
        path {
          fill: $white;
          transition: $animFill;
        }
        &:hover {
          svg {
            path {
              fill: $at-primary;
            }
          }
        }
      }
      .comparte {
        border: none;
        background: none;
        padding: 0;
        svg {
          color: $white;
          filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.6));
          height: 21px;
          @include media-breakpoint-up(md) {
            height: 36px;
          }
        }
      }
      .favorito {
        height: auto;
        width: auto;
        right: 0;
        top: 0;
        margin-left: 22px;
        background: none;
        svg {
          margin: 0;
          width: auto;
          height: 23px;
          @include media-breakpoint-up(md) {
            height: 36px;
          }
        }
      }
    }
    @include media-breakpoint-up(md) {
      &.sinmenu {
        justify-content: flex-end;
      }
    }
  }
  .image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 7;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 33, 0.65) 0%,
        rgba(0, 0, 33, 0) 100%
      );
      width: 100%;
      height: calc(100% - 96px);
    }
    .img-bg {
      min-height: 100%;
      min-width: 100%;
      max-width: 100%;
      object-fit: cover;
      position: relative;
      z-index: 6;
    }
    .at-video-bg + .img-bg {
      visibility: visible;
      @include media-breakpoint-up(xl) {
        visibility: hidden;
      }
    }
    .at-video-bg {
      display: none;
      position: absolute;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      @include media-breakpoint-up(xl) {
        display: flex;
      }
    }
  }
  &.es-peq {
    min-height: 240px;
    @include media-breakpoint-up(md) {
      min-height: 340px;
    }
  }
  .info-img {
    bottom: 10px;
    left: 20px;
    @include media-breakpoint-up(sm) {
      left: 12px;
    }
    @include media-breakpoint-up(md) {
      left: 16px;
    }
    @include media-breakpoint-up(lg) {
      left: 32px;
    }
    @include media-breakpoint-up(xl) {
      bottom: 16px;
    }
  }
}
