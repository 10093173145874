.pmr-tuperfil {
  position: relative;
  margin-bottom: 60px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 100px;
  }
  .datos-niveles {
    border-top: 4px solid #cccccc;
    position: relative;
    margin-top: 36px;
    @include media-breakpoint-up(lg) {
      margin-top: 62px;
    }
    @include media-breakpoint-down(sm) {
      border-top: none;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        rgba(244, 245, 247, 0) 0%,
        rgba(244, 245, 247, 0.6) 30%,
        rgba(244, 245, 247, 0.6) 50%,
        rgba(244, 245, 247, 1) 100%
      );
      opacity: 0.7;
      z-index: 5;
    }
    .nivel {
      position: relative;
      z-index: 10;
      padding: 26px;
      border-top: 1px solid #ffffff;
      background: none;
      border-radius: 0;
      @include media-breakpoint-down(sm) {
        border-top: 4px solid #cccccc;
        padding: 0 16px 50px;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 10%,
          rgba(244, 245, 247, 1) 100%
        );
      }
      @include media-breakpoint-up(sm) {
        &::before {
          content: '';
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          border-top: 1px solid #cccccc;
          height: 1px;
          width: 100%;
        }
        &:first-child {
          border: none;
          &::before {
            display: none;
          }
        }
      }
      p {
        margin: 0;
      }
      .card-header {
        border: none;
        background: none;
        .ac-trigger {
          background: none;
          padding-left: 0;
          padding-right: 0;
          @include media-breakpoint-down(sm) {
            flex-direction: column;
            padding-top: 0;
            margin-top: -22px;
          }
          svg path {
            fill: $greyish;
          }
        }
        .divoculta {
          max-height: 8.5rem;
          transition: max-height 0.3s ease-in-out;
          padding: 0 48px;
          overflow: hidden;
          color: #aaaaaa;
          @include media-breakpoint-down(xl) {
            max-height: 10rem;
          }
          @include media-breakpoint-down(sm) {
            padding: 0;
          }
        }
        .nivel-tit {
          font-weight: bold;
          font-size: to.rem(24px);
          letter-spacing: to.rem(-1.2px);
          color: #8d8d8d;
          @include media-breakpoint-down(sm) {
            font-size: 20px;
            margin-top: 4px;
            padding: 0 32px;
            text-align: center;
          }
          strong {
            letter-spacing: 0;
            color: #8d8d8d;
            margin-left: 4px;
          }
        }
        .botones {
          display: flex;
          align-items: center;
          justify-content: center;
          .btn {
            margin: 10px 0;
            width: auto;
          }
        }
      }
      .status {
        min-width: 38px;
        width: 38px;
        height: 38px;
        margin: 4px 8px 4px 0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: 2px solid #b3b3b3;
        position: relative;
        z-index: 10;
        @include media-breakpoint-down(sm) {
          margin: 0;
        }
        svg {
          display: block !important;
          transform: none !important;
          width: 18px;
          height: 18px;
          .check {
            display: none;
          }
        }
      }
      .toggle-btn {
        min-width: 38px;
        width: 38px;
        height: 38px;
        margin: 4px 0;
        margin-left: auto;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #4b9cdc;
        position: relative;
        z-index: 10;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        font-size: 14px;
        @include media-breakpoint-down(sm) {
          position: absolute;
          right: -16px;
          top: 32px;
          transform: scale(0.7);
          margin: 0;
        }
        svg path {
          fill: $white !important;
        }
        &:hover {
          opacity: 0.7;
        }
      }
      [aria-expanded='true'] {
        .toggle-btn {
          background: #b3b3b3;
        }
        & + .divoculta {
          max-height: 0;
        }
      }
      &.listo {
        .status {
          border-color: $at-primary;
          svg {
            .check {
              display: block;
            }
            .lock {
              display: none;
            }
          }
        }
        p,
        .card-body {
          color: $dkGray;
        }
        .nivel-tit {
          color: $mdGray;
          strong {
            color: $at-primary;
          }
        }
      }
      .card-body {
        padding: 0 48px;
        color: #aaaaaa;
        @include media-breakpoint-down(sm) {
          padding: 0;
        }
        & > .row {
          background: #ffffff;
          border: 1px solid #e6e6e6;
          border-bottom: none;
          padding: 20px 10px;
        }
        .dato {
          display: flex;
          flex-direction: column;
          margin-bottom: 8px;
          font-size: 16px;
          line-height: 22px;
          color: #333333;
          .campo {
            margin-right: 4px;
            font-size: 14px;
          }
          .value {
            font-weight: bold;
            word-break: break-word;
          }
        }
        .botones {
          background: #ffffff;
          border: 1px solid #e6e6e6;
          border-top: none;
          position: relative;
          display: flex;
          justify-content: center;
          margin: 0 -10px 8px;
          padding-bottom: 20px;
          @include media-breakpoint-down(sm) {
            flex-direction: column;
          }
          .btn {
            margin: 0 10px;
            width: auto;
            @include media-breakpoint-down(sm) {
              margin: var(--bs-gutter-x);
            }
          }
        }
        .beneficios {
          .tit {
            font-size: to.rem(24px);
            line-height: to.rem(36px);
            color: #808080;
            margin-top: 20px;
          }
          p {
            font-size: 16px;
            line-height: 26px;
            color: #333333;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .at-accordion {
      padding-right: 0;
    }
  }
}
.pmr-tuperfil-edit {
  &.profile-details {
    margin: 60px 0 90px;
    .title {
      font-size: to.rem(16px);
      line-height: to.rem(20px);
      font-weight: 700;
      text-align: left;
      margin: 0;
    }
    .border-top {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #e6e6e6;
      padding: 5px 0;
      margin-bottom: 15px;
      align-items: center;
      a,
      .trigger {
        display: flex;
        font-size: to.rem(14px);
        line-height: to.rem(20px);
        cursor: pointer;
        color: $at-primary;
        transition: $animColor;
        svg {
          height: 17px;
          width: 17px;
          margin-right: 3px;
        }
        &:hover {
          color: #155fb3;
        }
      }
    }
  }
}
