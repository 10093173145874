.pmr-promo {
  position: relative;
  .title-section {
    &[id^='ancla'] {
      margin-top: 70px;
    }
  }
  .row {
    .row {
      width: 100%;
      justify-content: center;
    }
  }
  .anclas-pmr {
    display: flex;
    justify-content: center;
    ul {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      margin: 0 20px;
      padding: 0;
      list-style: none;
      li {
        &::marker {
          display: none;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #e7e7e7;
          border: 1px Solid #e6e6e6;
          border-radius: 6px;
          padding: 14px 16px;
          font-size: to.rem(14px);
          line-height: to.rem(14px);
          text-transform: uppercase;
          font-weight: 800;
          margin: 2px;
          color: #4d4d4d;
          transition: all 0.3s ease;
          text-decoration: none;
          &:hover {
            background: #398be7;
            color: #ffffff;
          }
          &.current {
            background: #ffffff !important;
            color: #398be7 !important;
          }
        }
      }
    }
  }
  .slider-cuadrado,
  .at-grid-featured {
    .cont {
      .datos {
        &::after {
          background: linear-gradient(
            180deg,
            rgba(233, 233, 233, 1) 0%,
            rgba(233, 233, 233, 0) 100%
          );
        }
        &::before {
          background: rgba(233, 233, 233, 1);
        }
      }
    }
  }
}
.hered-pag {
  margin-top: 60px;
  .align-center {
    text-align: center;
  }
  .item-crossell:hover::before {
    background-color: #155fb3;
    opacity: 0.9;
  }
  @include media-breakpoint-down(xs) {
    .title-section,
    .text-description {
      padding: 0 20px;
    }
  }
}
