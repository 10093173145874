// at-grid-activities.scss
.at-grid-activities {
  margin-bottom: 54px;
  @include media-breakpoint-up(md) {
    margin-bottom: 108px;
  }
}
.gris-bg {
  .at-grid-activities {
    margin-bottom: 0;
  }
}
