#idt-result-map {
  margin: 0;
  width: 100%;
  height: 100%;
  top: 0;
}
.top-modo-mapa {
  position: relative;
  background-color: #e9e9e9;
  height: 100vh;
  overflow: hidden;
  > .container {
    position: relative;
    z-index: 10;
    height: 100vh;
    pointer-events: none;
  }
  .contenido-mapa {
    position: relative;
    z-index: 10;
    height: 100vh;
    pointer-events: none;
  }
  #idt-map-mode {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    min-height: 100vh;
    min-width: 100vw;
    z-index: 5;
  }
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group {
    margin: 0 80px 80px 0;
    @include media-breakpoint-down(xxl) {
      margin: 0 10px 10px 0;
    }
  }
  .mapa-filtros-wrap {
    box-sizing: border-box;
    user-select: none;
    touch-action: none;
    min-height: 64px;
    max-height: 523px;
    pointer-events: all;
    position: absolute;
    z-index: 15;
    top: 146px;
    @include media-breakpoint-down(xxl) {
      top: 82px;
    }
  }
  .mapa-filtros {
    background: $white;
    padding: 12px;
    padding-right: 0;
    border-radius: 8px;
    width: 324px;
    box-shadow: 5px 0px 8px 3px rgba(0, 0, 0, 0.1);
    pointer-events: all;
    height: 100%;
    min-height: 64px;
    max-height: 508px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    @media (min-width: 1439.99px) and (max-height: 760px) {
      max-height: calc(100vh - 230px);
    }
    @include media-breakpoint-down(sm) {
      width: calc(100vw - 42px);
    }
    &.active {
      #myScrollElement {
        height: 100%;
        bottom: 12px;
        &::before,
        &::after {
          opacity: 1;
        }
      }
      .compact {
        display: none;
      }
      & + .toggle-btn {
        &::after {
          display: none;
        }
        .triangle {
          transform: rotate(180deg);
        }
      }
    }
    #myScrollElement {
      height: 0;
      margin-bottom: 0;
      padding-bottom: 0;
      max-height: inherit;
      box-sizing: border-box;
      transition: $animAll;
      &::before,
      &::after {
        height: 20px;
        opacity: 0;
        transition: $animOpacity;
      }
      &::before {
        background: linear-gradient(0deg, hsla(0, 0%, 100%, 0), #fff);
        top: 0;
        content: '';
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        width: 100%;
        z-index: 1;
      }
      .simplebar-content {
        padding: 0 !important;
        margin: 8px 0;
      }
      .simplebar-scrollbar {
        border-radius: 5px;
        &::before {
          background-color: #c9c9c9;
        }
        &.simplebar-visible::before {
          opacity: 1;
        }
      }
      .simplebar-track {
        right: 3px;
        &.simplebar-vertical {
          top: 0;
          width: 9px;
        }
      }
    }
    .at-accordion {
      padding-right: 16px;
      .ac {
        margin-top: 5px;
        &:nth-child(odd) {
          background-color: #f7fafe;
        }
        &.has-filters-selected {
          .ac-trigger {
            svg {
              path {
                fill: $at-primary;
              }
            }
          }
        }
        .andorra-search-filter {
          grid: none;
          padding: 0 3px 10px 28px;
          .checkbox label,
          .switch label {
            width: 100%;
          }
        }
        .ac-trigger {
          padding: 13px 14px 14px 9px;
          background: transparent;
          justify-content: flex-start;
          color: #4d4d4d !important;
          svg {
            height: 18px;
            margin-left: auto;
          }
        }
      }
    }
    .compact {
      display: flex;
      align-items: center;
      font-size: to.rem(16px);
      letter-spacing: to.rem(-0.4px);
      line-height: to.rem(19px);
      color: #4d4d4d;
      background: #f3f3f3;
      padding: 10px 14px 11px 9px;
      margin-right: 12px;
      .cantidad {
        margin-left: auto;
      }
    }
    .dot {
      display: block;
      border-radius: 8px;
      width: 8px;
      height: 8px;
      margin-right: 5px;
    }
    .cantidad {
      position: relative;
      font-weight: 400;
      font-size: to.rem(14px);
      letter-spacing: to.rem(-0.21px);
      color: #a7a7a7;
      top: 1px;
      margin-left: 6px;
    }
  }
  .toggle-btn {
    position: relative;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    z-index: 5;
    cursor: pointer;
    pointer-events: all;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30px;
      height: 15px;
      background-color: $white;
      border-radius: 0 0 15px 15px;
      z-index: 1;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
    }
    &::after {
      content: '';
      position: absolute;
      top: -49px;
      left: -147px;
      width: 324px;
      height: 64px;
      cursor: pointer;
      z-index: 1;
      opacity: 0.6;
    }
    .triangle {
      position: relative;
      top: 2px;
      z-index: 2;
      width: 0;
      height: 0;
      border-width: 7px 5px 0 5px;
      border-color: #333333 transparent transparent transparent;
      border-style: solid;
      transition: $animAll;
    }
  }
  .dragdiv {
    position: absolute;
    bottom: -19px;
    left: 0;
    width: 100%;
    height: 32px;
    z-index: 100;
    pointer-events: all;
    display: none;
    cursor: s-resize;
  }
  .markers {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
  }
  .marker {
    background: transparent url(../img/markers/marker-default.png) no-repeat
      center center;
    width: 50px;
    height: 50px;
    pointer-events: all;
    > .tooltip-mm-custom {
      display: none;
    }
    &.color0001 {
      background-image: url(../img/markers/marker-color0001.png);
    }
    &.color0002 {
      background-image: url(../img/markers/marker-color0002.png);
    }
    &.color0003 {
      background-image: url(../img/markers/marker-color0003.png);
    }
    &.color0004 {
      background-image: url(../img/markers/marker-color0004.png);
    }
    &.color0005 {
      background-image: url(../img/markers/marker-color0005.png);
    }
    &.color0006 {
      background-image: url(../img/markers/marker-color0006.png);
    }
    &.color0007 {
      background-image: url(../img/markers/marker-color0007.png);
    }
    &.color0008 {
      background-image: url(../img/markers/marker-color0008.png);
    }
    &.active {
      .tooltip-mm-custom {
        animation: fadeIn 0.2s forwards;
      }
    }
  }
  .mapboxgl-popup {
    position: absolute;
    width: 400px;
    max-width: 400px !important;
    padding: 6px;
    box-sizing: border-box;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.1);
    left: -9.5px;
    top: -14px;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      width: calc(100vw - 42px);
    }
    .mapboxgl-popup-content {
      border-radius: 0;
      box-shadow: none;
      padding: 0;
      min-width: 100%;
    }
    .mapboxgl-popup-close-button {
      right: -10px !important;
      top: -36px !important;
      font-weight: bold !important;
      font-size: 26px !important;
    }
  }
  .tooltip-mm-custom {
    display: flex;
    padding-right: 8px;
    min-height: 114px;
    &:hover {
      background-color: #398be7;
      .text {
        color: $white;
        p,
        .titulo {
          color: $white;
        }
      }
    }
    img {
      width: 114px;
      object-fit: cover;
    }
    .tag {
      position: absolute;
      top: 13px;
      left: -1px;
      font-weight: 700;
      font-size: to.rem(10px);
      line-height: to.rem(16px);
      letter-spacing: to.rem(0.5px);
      padding: 0 10px;
      color: #ffffff;
      background: #ff1d25;
    }
    .text {
      margin-top: 7px;
      margin-left: 10px;
      flex: 2;
      p {
        font-size: 14px;
        font-size: to.rem(14px);
        line-height: to.rem(19px);
        color: #666666;
        margin-bottom: 0;
        max-height: 60px;
        overflow: hidden;
        transition: $animColor;
        @include media-breakpoint-down(sm) {
          max-height: 80px;
        }
      }
    }
    .titulo {
      font-weight: 800;
      font-size: to.rem(16px);
      line-height: to.rem(19px);
      color: #333333;
      margin-bottom: 5px;
      max-height: 40px;
      overflow: hidden;
      transition: $animColor;
      @include media-breakpoint-down(sm) {
        max-height: 60px;
      }
    }
  }
  .mont {
    position: absolute;
    z-index: 10;
    width: 2100px;
    min-width: 100%;
    left: 0;
    right: 0;
    bottom: -1px;
    filter: drop-shadow(0 0 4px rgb(0 0 0 / 0.3));
    pointer-events: none;
    @include media-breakpoint-down(xxl) {
      display: none;
    }
  }
}
.color0001 {
  color: #c9c9c9;
  background-color: #c9c9c9;
}
.color0002 {
  color: #ff801e;
  background-color: #ff801e;
}
.color0003 {
  color: #00caff;
  background-color: #00caff;
}
.color0004 {
  color: #bdaf21;
  background-color: #bdaf21;
}
.color0005 {
  color: #a7a7a7;
  background-color: #a7a7a7;
}
.color0006 {
  color: #e800ff;
  background-color: #e800ff;
}
.color0007 {
  color: #b8801e;
  background-color: #b8801e;
}
.color0008 {
  color: #ffbf2b;
  background-color: #ffbf2b;
}
.mobile {
  .top-modo-mapa {
    .mapa-filtros {
      height: calc(50vh - 87px);
      max-height: calc(100vh - 110px);
      overflow: inherit;
      &.active {
        #myScrollElement {
          bottom: 0;
          .simplebar-content {
            margin: 0;
          }
        }
      }
    }
    .toggle-btn {
      height: 36px;
      width: 36px;
      top: -18px;
      &::before {
        width: 36px;
        height: 18px;
        border-radius: 0 0 18px 18px;
      }
      &::after {
        display: none;
      }
      .triangle {
        transform: rotate(180deg);
        top: -4px;
        border-width: 8px 6px 0 6px;
        &::after {
          content: '';
          position: absolute;
          left: -6px;
          top: -19px;
          z-index: 2;
          width: 0;
          height: 0;
          border-width: 8px 6px 0 6px;
          border-color: #333333 transparent transparent transparent;
          border-style: solid;
          transform: rotate(180deg);
        }
      }
    }
    .dragdiv {
      display: block;
    }
  }
}
