html {
  box-sizing: border-box;
  font-size: 100%;
  scroll-behavior: smooth;
}
/*! purgecss start ignore */
*,
*:before,
*:after {
  box-sizing: inherit;
}
/*! purgecss end ignore */
body {
  background-color: $white;
  &.no-scroll {
    overflow: hidden;
  }
  @include media-breakpoint-down(md) {
    &.modal-open {
      overflow: hidden;
      .at-navbar-global {
        z-index: 1;
      }
    }
  }
}
a {
  transition: $animColor;
}
a:hover {
  color: $blue-dark;
}
.container-inner {
  @include media-breakpoint-up(md) {
    margin: 0 50px;
  }
  @include media-breakpoint-up(lg) {
    margin: 0 100px;
  }
  @include media-breakpoint-up(xl) {
    margin: 0 150px;
  }
  &.ajustado {
    margin: 0 15px;
    @include media-breakpoint-up(md) {
      margin: 0;
    }
    > * {
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
// menos gutter en MD
@include media-breakpoint-down(sm) {
  .container {
    --bs-gutter-x: 1.3rem !important;
  }
}
.btn {
  font-weight: 800;
  font-size: to.rem(16px);
  line-height: to.rem(20px);
  letter-spacing: to.rem(0.96px);
  border-radius: 6px;
  padding: 14px 18px;
  text-transform: uppercase;
  outline: 0 !important;
  box-shadow: none !important;
  &.btn-primary {
    color: $white;
    &:hover {
      background-color: $white;
      border-color: $at-primary;
      color: $at-primary;
      path {
        fill: $at-primary;
      }
    }
  }
  &.btn-secondary {
    background-color: $dkGray;
    color: $white;
    &:hover {
      background-color: $white;
      border-color: $dkGray;
      color: $dkGray;
    }
  }
  &.btn-light {
    background-color: $white;
    color: #4d4d4d;
    &:hover {
      background-color: #333;
      border-color: $white;
      color: $white;
    }
  }
  &.btn-outline-light {
    border-color: $white;
    color: $white;
    &:hover {
      background-color: $white;
      color: $at-primary;
    }
  }
  &.btn-outline-primary {
    &:hover {
      color: $white;
      path {
        fill: $white;
      }
    }
    &-whitebg {
      background-color: $white;
      &:hover {
        background-color: $at-primary;
      }
    }
  }
  &.btn-outline-gray {
    color: $mdGray;
    border-color: $mdGray;
    background-color: $white;
    &:hover {
      color: $white;
      background-color: $mdGray;
    }
  }
}
.favorito {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 39px;
  height: 39px;
  z-index: 10;
  font-size: 0;
  background-color: transparent;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $animBgColor;
  cursor: pointer;
  svg {
    margin-top: 1px;
    width: 25px;
    height: 28px;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.6));
    path {
      fill: #ffffff;
      transition: $animFill;
    }
  }
  &:hover {
    background-color: $white;
    svg {
      filter: none;
      path {
        fill: $at-primary;
      }
    }
  }
}
.cat {
  font-weight: 700;
  font-size: to.rem(12px);
  line-height: to.rem(20px);
  letter-spacing: to.rem(0.72px);
  color: #d9d9d9;
  background-color: #333333;
  padding: 0 5px;
  text-transform: uppercase;
  transition: $animAll;
}
.seo-module.text-module {
  margin-top: 178px;
}

.at-fila {
  margin-top: 64px;
  @include media-breakpoint-down(sm) {
    margin-top: 40px;
  }
  &.gris-bg {
    margin-top: 0 !important;
  }
}
.at-fila-padd {
  padding-top: 64px;
  padding-bottom: 54px;
  @include media-breakpoint-down(sm) {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

.at-fila.no-hero {
  margin-top: 158px;
  @include media-breakpoint-down(sm) {
    margin-top: 100px;
  }
}

.gris-bg {
  background: #e9e9e9;
}
.arrow-down {
  position: relative;
  width: 14px;
  height: 9px;
  font-size: 0;
  &:before,
  &:after {
    content: '';
    position: absolute;
    background: #333333;
    border-radius: 2px;
    display: block;
    top: 0;
    right: 3px;
    height: 10px;
    width: 2px;
    transform: rotate(45deg);
    transition: $animBgColor;
  }
  &:after {
    right: inherit;
    left: 3px;
    transform: rotate(-45deg);
  }
}
.deg-grey-bg-bot {
  position: relative;
  min-height: 490px;
  > * {
    position: relative;
    z-index: 10;
  }
  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(233, 233, 233, 1) 0%,
      rgba(233, 233, 233, 0) 100%
    );
    height: 490px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
  }
}
.select {
  &.select-lang {
    position: relative;
    margin-bottom: 46px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    width: 210px;
    max-height: 45px;
    box-sizing: border-box;
    transition: $animBgColor;
    select {
      padding: 0 28px 0 56px;
      appearance: none;
      height: 43px;
      width: 100%;
      font-size: to.rem(18px);
      line-height: to.rem(20px);
      color: #333333;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:active,
      &:focus {
        border: none;
        background: none;
        outline: 0;
      }
    }
    &::after {
      display: none;
    }
    .icon {
      position: absolute;
      width: 38px;
      left: 11px;
      top: 17px;
      color: var(--bs-body-color);
    }
    .arrow-down {
      position: absolute;
      right: 10px;
      top: 16px;
    }
    &:hover {
      background-color: $white;
      .arrow-down {
        &:before,
        &:after {
          background-color: $at-primary;
        }
      }
    }
  }
}
.separadordiv {
  margin-bottom: 60px;
  @include media-breakpoint-up(md) {
    margin-top: 110px;
  }
}
.separadorlinea {
  padding: 2px 0;
  position: relative;
  &::before,
  &::after {
    content: '';
    position: relative;
    display: block;
    height: 1px;
    width: 100%;
    background-color: $white;
  }
  &::after {
    background-color: #d7d7d7;
  }
}
.stars {
  display: flex;
  margin-bottom: 4px;
  .star {
    height: 14px;
    display: flex;
  }
  svg {
    height: 14px;
    margin: 1px;
    path {
      fill: #ffba00;
    }
  }
}
.jumbotron-404 {
  .item {
    position: relative;
    min-height: 100vh;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 17, 99, 0.3);
      top: 0;
      left: 0;
      z-index: 2;
    }
    .caption {
      position: relative;
      z-index: 5;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $white;
        .text {
          text-align: center;
          font-weight: 700;
          padding: 0 20px;
        }
        .title-main,
        .h1 {
          color: $white;
        }
      }
    }
  }
  .img-fluid {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    align-items: center;
    display: flex;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    object-fit: cover;
    z-index: 1;
  }
}

.mapboxgl-ctrl-bottom-right {
  @include media-breakpoint-down(lg) {
    bottom: 80px;
    right: 10px;
  }
}

.at-navbar-global .slide-menu .tools-mobile .select-wrap {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.at-navbar-global .slide-menu .tools-mobile {
  margin-top: 0%;
}

.mapboxgl-ctrl-group button {
  width: 45px;
  height: 45px;
}
