// _modal-filtro-hoteles.scss
.basicLightbox {
  .is-modal-filtro-hoteles {
    &.is-modal-peque {
      .modal-at-inner {
        max-width: 100%;
        width: 975px;
        padding: 50px 0 32px;
        box-sizing: border-box;
        @include media-breakpoint-up(md) {
          padding: 70px 26px 32px 50px;
        }
        .simplebar-scrollbar:before {
          background: #a7a7a7;
          opacity: 1;
        }
        .simplebar-track.simplebar-vertical {
          &::before {
            content: '';
            position: absolute;
            background-color: #a7a7a7;
            top: 0;
            bottom: 0;
            left: 50%;
            width: 1px;
            height: 100%;
          }
        }
        .btn-container {
          margin-right: 22px;
          padding-top: 24px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          border-top: 1px solid #e9e9e9;
          @include media-breakpoint-down(md) {
            margin-right: 0;
          }
          .btn {
            margin: 4px;
          }
        }
        @include media-breakpoint-down(md) {
          .container {
            padding-left: 0;
            padding-right: 0;
          }
          .at-accordion {
            padding-right: 0;
          }
        }
      }
    }
  }
}
