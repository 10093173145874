:root {
  --swiper-navigation-size: 72px;
}
.slider-event-wrap {
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  > .container {
    position: relative;
    z-index: 10;
    @include media-breakpoint-down(sm) {
      &.text-center {
        margin: 0;
        padding: 0;
      }
    }
  }
}
.slider-event {
  margin-top: 42px;
  margin-bottom: 42px;
  .swiper-wrapper {
    .swiper-slide {
      &::after {
        content: '';
        position: absolute;
        z-index: 20;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: #ffffff;
        opacity: 0.75;
        transition: $animOpacity;
      }
      &.swiper-slide-prev,
      &.swiper-slide-next {
        &::after {
          opacity: 0.45;
        }
      }
      &.swiper-slide-active {
        &::after {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    .container {
      z-index: 10;
      visibility: visible;
    }
  }
  .img-bg {
    position: relative;
    object-fit: cover;
    z-index: 5;
    min-height: 100%;
    min-width: 101%;
    max-width: 101%;
  }
  .cont {
    position: relative;
    position: relative;
    height: 100%;
    display: flex;
    z-index: 5;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.75) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      width: 100%;
      height: 182px;
      z-index: 10;
      transition: $animHeight;
    }
    .text-center {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: 15;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 140px;
      padding: 0 22px 28px;
      box-sizing: border-box;
      max-height: 100%;
      overflow: hidden;
      .cat {
        margin: 12px 0 4px;
      }
      .heading {
        font-weight: 800;
        font-size: to.rem(37px);
        line-height: to.rem(43px);
        letter-spacing: to.rem(-0.55px);
        color: #ffffff;
        max-height: to.rem(215px);
        overflow: hidden;
      }
      .calcChildSize {
        overflow: hidden;
        height: 0;
        transition: $animHeight;
      }
      .parrafo {
        font-size: to.rem(18px);
        line-height: to.rem(28px);
        max-height: to.rem(154px);
        overflow: hidden;
        color: #ffffff;
        text-shadow: 1px 1px 0.55px rgba(0, 0, 33, 0.4);
        margin: 0;
        padding: 10px 0 4px;
      }
    }
    @include media-breakpoint-up(lg) {
      &:hover {
        &::before {
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.97) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          height: 437px;
        }
        .cat {
          color: $black;
          background-color: $white;
        }
        .calcChildSize {
          height: var(--calc-height);
        }
      }
    }
  }
}
.nav-slider-event {
  .swiper-button-prev,
  .swiper-button-next {
    color: $white;
    opacity: 1;
    @include media-breakpoint-up(md) {
      color: #8d8d8d;
      opacity: 0.75;
    }
  }
}
