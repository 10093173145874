// _slider-social.scss
:root {
  --swiper-navigation-size: 72px;
  --swiper-theme-color: #ffffff;
}
.slider-social-wrap {
  position: relative;
  margin-top: 40px;
  margin-bottom: 100px;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    > .container {
      margin: 0;
      padding: 0;
    }
  }
}
.slider-social {
  overflow: inherit;
  margin-bottom: 12px;
  .swiper-wrapper {
    .img-bg {
      position: relative;
      z-index: 5;
      min-height: 400px;
      max-height: 400px;
    }
    .swiper-slide {
      display: flex;
      margin: 0;
      padding: 0;
      width: auto;
    }
    .container {
      z-index: 10;
      visibility: visible;
    }
  }
  .at-item-social {
    position: relative;
    .user-link {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      z-index: 10;
      font-size: to.rem(14px);
      line-height: to.rem(16px);
      color: $white;
      text-decoration: none;
      padding: 0 4px 0 10px;
      border-radius: 36px;
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.6));
      transition: $animAll;
      @include media-breakpoint-down(sm) {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
      }
      svg {
        width: 36px;
        height: 36px;
        path {
          fill: $white;
          transition: $animFill;
        }
      }
      &:hover {
        background-color: $white;
        color: $at-primary;
        svg {
          path {
            fill: $at-primary;
          }
        }
      }
    }
  }
}
.pswp {
  &2 {
    .pswp__counter,
    .pswp__caption {
      display: none;
    }
  }
  &__previews {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    // background-color: rgba(black, 0.3);
    overflow: auto;
    max-height: 10vh;
    > div {
      display: flex;
    }
    // Hide scrollbar in Quantum (Firefox)
    scrollbar-width: none;
    // Hide scrollbar in Blink (Chrome)
    &::-webkit-scrollbar {
      display: none;
    }
    img {
      min-height: 10vh;
      max-width: 10vh;
      object-fit: cover;
      opacity: 0.5;
      transition: opacity 0.3s;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      &.is-active {
        opacity: 1;
        cursor: default;
      }
    }
  }
}
