.pmr-side {
  background: #f4f5f7;
  min-height: 100%;
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 90px;
  @include media-breakpoint-down(lg) {
    margin-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    display: flex;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(244, 245, 247, 1) 100%
    );
    width: 82px;
    height: 100%;
  }
  &::after {
    display: none;
  }
  @include media-breakpoint-down(lg) {
    &::after {
      right: inherit;
      left: 100%;
      display: block;
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(244, 245, 247, 1) 100%
      );
    }
  }
  .bienvenido {
    padding: 50px 28px 18px 18px;
    @include media-breakpoint-down(md) {
      padding: 25px 18px;
      text-align: center;
      br {
        display: none;
      }
    }
    .titulo {
      font-size: to.rem(42px);
      line-height: to.rem(48px);
      letter-spacing: to.rem(-0.63px);
      color: $dkGray;
      margin-bottom: 18px;
      @include media-breakpoint-down(md) {
        font-size: to.rem(32px);
        line-height: to.rem(38px);
      }
    }
    .text {
      p {
        font-size: to.rem(18px);
        line-height: to.rem(30px);
        color: $mdGray;
      }
    }
  }
  .promobox {
    position: relative;
    margin: 18px;
    padding: 5px 10px 15px;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.07);
    z-index: 2;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-right: 0;
    }
    .triangle-with-shadow {
      width: 58px;
      height: 42px;
      position: absolute;
      top: -42px;
      left: 50%;
      margin-left: -28px;
      overflow: hidden;
    }
    .triangle-with-shadow:after {
      content: '';
      position: absolute;
      width: 42px;
      height: 42px;
      background: #ffffff;
      transform: rotate(45deg);
      top: 26px;
      left: 8px;
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
    }
    &__titulo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 4px 0 8px;
    }
    &__titulo__texto {
      font-weight: 700;
      font-size: to.rem(18px);
      line-height: to.rem(23px);
      letter-spacing: to.rem(-0.27px);
      color: #999999;
      margin: 0 6px;
      text-align: left;
    }
    .status {
      width: 38px;
      height: 38px;
      min-width: 38px;
      margin: 4px 0;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border: 2px solid #cccccc;
      position: relative;
      z-index: 10;
      transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      &::after {
        content: '';
        border-radius: 50%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
      svg {
        display: block !important;
        width: 18px;
        height: 18px;
        .check {
          display: none;
        }
      }
    }
    &.listo {
      text-align: center;
      .promobox__titulo__texto {
        color: $at-primary;
      }
      .status {
        background-color: $at-primary;
        border-color: $at-primary;
        svg {
          .check {
            display: block;
            fill: $white;
          }
          .lock {
            display: none;
          }
        }
      }
    }
    .image {
      position: relative;
      margin-bottom: 12px;
      display: flex;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.1);
      }
      img {
        min-width: 100%;
      }
      .titulo {
        position: absolute;
        width: 100%;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        text-align: center;
        font-weight: 800;
        font-size: to.rem(40px);
        line-height: to.rem(40px);
        letter-spacing: to.rem(-1px);
        color: $white;
        text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.35);
        z-index: 10;
        @include media-breakpoint-down(sm) {
          padding: 10px;
          font-size: to.rem(30px);
          line-height: to.rem(30px);
        }
        strong {
          font-weight: 800;
        }
      }
    }
    p {
      margin: 12px;
      padding: 0 0 4px;
      font-size: to.rem(16px);
      line-height: to.rem(26px);
      text-align: left;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
      strong {
        font-weight: 700;
      }
    }
  }
  .banner-destacado {
    position: relative;
    margin: 50px 18px 18px;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.07);
    z-index: 2;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: -26px;
      left: 0;
      border-top: 1px solid #ffffff;
      height: 1px;
      width: 100%;
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: -25px;
      left: 0;
      border-top: 1px solid #cccccc;
      height: 1px;
      width: 100%;
    }
    .fondo {
      position: absolute;
      z-index: 5;
      top: 8px;
      bottom: 8px;
      left: 8px;
      right: 8px;
      height: calc(100% - 16px);
      width: calc(100% - 16px);
      background-color: #011e6c;
      transition: filter 0.3s ease-in-out;
      .img-fluid {
        min-height: 100%;
        min-width: 100%;
        max-height: 100%;
      }
    }
    > * {
      position: relative;
      z-index: 10;
      cursor: pointer;
    }
    .sobre {
      display: flex;
      justify-content: center;
      margin: 18px 0 10px;
      svg {
        filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.25));
        width: 63px;
        height: 63px;
      }
    }
    .titulo {
      padding: 0 10px;
      text-align: center;
      font-weight: 700;
      font-size: to.rem(24px);
      line-height: to.rem(24px);
      color: $white;
      z-index: 10;
      @include media-breakpoint-down(sm) {
        font-size: to.rem(20px);
        line-height: to.rem(20px);
      }
    }
    p {
      margin: 12px 8px;
      padding: 0 8px 4px;
      font-size: to.rem(13px);
      line-height: to.rem(19px);
      color: $white;
      text-align: center;
    }
    .logo-awf {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      svg {
        width: 141px;
        height: 16px;
      }
    }
    &:hover {
      .fondo {
        filter: brightness(75%);
      }
    }
  }
  .links {
    list-style: none;
    margin: 40px 18px 18px;
    padding: 30px 0 0;
    position: relative;
    border-top: 1px solid #ffffff;
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      border-top: 1px solid #cccccc;
      height: 1px;
      width: 100%;
    }
    li {
      display: block;
      a {
        position: relative;
        display: flex;
        width: auto;
        margin: 2px 0;
        font-size: to.rem(14px);
        padding: 2px 2px 2px 14px;
        color: $mdGray;
        transition: all 0.3s ease-in-out;
        &::after {
          content: '';
          background-color: #a7a7a7;
          mask-image: url('/bundles/app/redesign/img/fa-icons/chevron-right-solid.svg');
          mask-size: 12px 12px;
          mask-repeat: no-repeat;
          mask-position: center;
          width: 12px;
          height: 12px;
          position: absolute;
          left: 0;
          top: 7px;
          transition: background-color 0.3s ease-in-out;
        }
        &:hover {
          color: #000;
          text-decoration: underline;
          &::after {
            background-color: #000;
          }
        }
      }
    }
  }
}
.pmr {
  overflow: hidden;
}
