// intro-single.scss
%intro-h1 {
  font-weight: 800;
  font-size: to.rem(32px);
  line-height: to.rem(38px);
  letter-spacing: to.rem(-0.42px);
  color: $white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  @include media-breakpoint-up(md) {
    font-size: to.rem(48px);
    line-height: to.rem(50px);
    letter-spacing: to.rem(-0.8px);
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.3);
  }
  @include media-breakpoint-up(lg) {
    font-size: to.rem(54px);
    line-height: to.rem(56px);
    letter-spacing: to.rem(-1.2px);
  }
  @include media-breakpoint-up(xl) {
    font-size: to.rem(64px);
    line-height: to.rem(69px);
    letter-spacing: to.rem(-1.6px);
  }
}
%intro-p {
  font-weight: 700;
  font-size: to.rem(14px);
  line-height: to.rem(23px);
  letter-spacing: to.rem(-0.14px);
  color: $white;
  @include media-breakpoint-up(md) {
    font-size: to.rem(20px);
    line-height: to.rem(28px);
    letter-spacing: to.rem(-0.2px);
  }
  @include media-breakpoint-up(xl) {
    font-size: to.rem(24px);
    line-height: to.rem(33px);
    letter-spacing: to.rem(-0.36px);
  }
}
.info-detall .intro-single .container{
  padding-top: 178px!important;
}
.intro-single {
  display: flex;
  align-items: flex-end;
  min-height: 514px;
  position: relative;
  @include media-breakpoint-up(md) {
    align-items: center;
  }
  .container {
    position: relative;
    z-index: 10;
    padding-top: 178px;
  }
  .info {
    .title {
      @extend %intro-h1;
      > * {
        margin-bottom: 10px;
        @include media-breakpoint-up(md) {
          margin-bottom: 16px;
        }
        @extend %intro-h1;
      }
    }
    .parrafo,
    p {
      margin-bottom: 24px;
      @include media-breakpoint-up(md) {
        margin-bottom: initial;
      }
      @extend %intro-p;
      > * {
        @extend %intro-p;
      }
    }
    .btn {
      margin: 0 0 30px;
      @include media-breakpoint-up(md) {
        margin: 38px 0 0 0;
      }
    }
  }
  .image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 7;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 33, 0.65) 0%,
        rgba(0, 0, 33, 0) 100%
      );
      width: 100%;
      height: calc(100% - 96px);
    }
    .img-bg {
      min-height: 100%;
      min-width: 100%;
      max-width: 100%;
      object-fit: cover;
      position: relative;
      z-index: 6;
    }
    .at-video-bg + .img-bg {
      visibility: visible;
      @include media-breakpoint-up(xl) {
        visibility: hidden;
      }
    }
    .at-video-bg {
      display: none;
      position: absolute;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      @include media-breakpoint-up(xl) {
        display: flex;
      }
    }
  }
}
