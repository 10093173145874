// Bootstrap default variable overrides
$body-color: #666666;
$blue: #398be7 !default;
$primary: #398be7;
$secondary: #4d4d4d;
$font-size-base: 1.125rem;
$line-height-base: 1.7;
$headings-color: #333333;
$headings-font-weight: 800;
$headings-margin-bottom: 1.3rem;
$h1-font-size: to.rem(41px);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
);

// Animaciones
$animAll: all 0.2s ease-in-out;
$animOpacity: opacity 0.2s ease-in-out;
$animBgColor: background-color 0.2s ease-in-out;
$animColor: color 0.2s ease-in-out;
$animColor-y-Bg: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
$animColor-y-Opa: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
$animBgColor-y-Bc: background-color 0.2s ease-in-out,
  border-color 0.2s ease-in-out;
$animHeight: height 0.2s ease-in-out;
$animFill: fill 0.2s ease-in-out;
$animTop: top 0.2s ease-in-out;
$animTransform: transform 0.2s ease-in-out;
$animBorderColor: border-color 0.2s ease-in-out;
@keyframes BounceSwiperNav {
  0% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  10% {
    opacity: 0.3;
    transform: scale(1.05);
  }
  30% {
    opacity: 0.4;
    transform: scale(1.1);
  }
  50% {
    opacity: 0.4;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.3;
    transform: scale(1);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
