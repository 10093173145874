$at-primary: #398be7 !default;
$blue-dark: #004694 !default;
$white: #ffffff;
$black: #000000;
$dkGray: #333333;
$mdGray: #666666;
$ltGray: #e6e6e6;
$rose-red: #c21e56;
$greyish: #cccccc;
$verd: rgb(150, 208, 86);
$blau: rgb(57, 139, 231);
$vermell: rgb(226, 72, 72);
