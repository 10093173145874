// Heredado REVISAR!!!!!!

.form-group {
  //   margin-bottom: 20px;
  margin-bottom: 10px;
  .form-input {
    // border-radius: 3px;
    // background-color: $white;
    // border: solid 1px #eee;
    // font-family: 'Open Sans';
    // font-size: 14px;
    // font-weight: normal;
    // font-style: normal;
    // font-stretch: normal;
    // line-height: 1.79;
    // letter-spacing: normal;
    // text-align: left;
    // color: $black;
    // padding: 12px 15px;
    // display: block;
    // width: 100%;
    // // Nuevo
    border-radius: 6px;
    background-color: $white;
    border: solid 1px #d7dcde;
    min-height: 50px;
    display: flex;
    padding: 12px;
    width: 100%;
    font-size: to.rem(14px);
    line-height: to.rem(16px);
    text-align: left;
    color: $black;
    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: $dkGray;
      opacity: 1;
    }
    &.has-error {
      border-color: red;
      background-color: #ffeeee;
    }
  }
  .error-msg {
    margin: 5px 0 0;
    font-size: 12px;
    color: red;
  }
}
.checkbox {
  margin-bottom: 20px;
  label,
  label a {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: left;
    color: $dkGray;
  }
  a {
    // pointer-events: none;
    &:hover {
      color: $at-primary;
    }
  }
  [type='checkbox']:not(:checked),
  [type='checkbox']:checked {
    position: absolute;
    left: -9999px;
  }
  [type='checkbox']:not(:checked) + label,
  [type='checkbox']:checked + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
  }
  /* checkbox aspect */
  [type='checkbox']:not(:checked) + label:before,
  [type='checkbox']:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 18px;
    height: 18px;
    border: solid 1px $at-primary;
    background: #fff;
    border-radius: 3px;
  }
  /* checked mark aspect */
  [type='checkbox']:not(:checked) + label:after,
  [type='checkbox']:checked + label:after {
    content: '';
    background: $at-primary;
    mask-image: url('/bundles/app/redesign/img/fa-icons/check-solid.svg');
    mask-size: cover;
    mask-repeat: no-repeat;
    mask-position: center;
    position: absolute;
    top: 6px;
    left: 3px;
    width: 12px;
    height: 12px;
    color: $at-primary;
    transition: all 0.2s;
  }
  /* checked mark aspect changes */
  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  [type='checkbox']:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  [type='checkbox']:checked + label {
    color: $at-primary !important;
    padding-left: 25px;
  }
  [type='checkbox']:checked + label a {
    padding-left: 0px;
    color: $at-primary !important;
  }
  [type='checkbox']:disabled + label {
    color: #eee !important;
    cursor: default;
  }
  [type='checkbox']:disabled + label a {
    color: #eee !important;
  }
  [type='checkbox']:disabled + label:before {
    border-color: #eee !important;
  }
  &.has-error {
    margin-bottom: 0;
    [type='checkbox']:not(:checked) + label:before,
    [type='checkbox']:checked + label:before {
      border-color: red;
      background-color: lighten(red, 62%);
    }
  }
}
.radio {
  label {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: normal;
    color: $black;
    width: 100%;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
  }
  label a {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: normal;
    color: $black;
    width: 100%;
    position: relative;
    padding-left: 0px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
  }
  a {
    pointer-events: none;
  }
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type='radio']:checked + label {
    color: $at-primary;
    padding-left: 28px;
  }
  [type='radio']:disabled + label {
    color: #eee;
    cursor: default;
  }
  [type='radio']:disabled + label a {
    color: #eee;
  }
  [type='radio']:checked + label a {
    color: $at-primary;
    padding-left: 0px;
  }
  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid $at-primary;
    border-radius: 100%;
    background: #fff;
  }
  [type='radio']:disabled + label:before {
    border: 1px solid #eee;
  }
  [type='radio']:checked + label:after,
  [type='radio']:not(:checked) + label:after {
    content: '\e905';
    content: '';
    background: $at-primary;
    mask-image: url('/bundles/app/redesign/img/fa-icons/check-solid.svg');
    mask-size: cover;
    mask-repeat: no-repeat;
    mask-position: center;
    speak: none;
    width: 12px;
    height: 12px;
    color: $at-primary;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  &.has-error {
    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
      border-color: red;
      background-color: #ffeeee;
    }
  }
}
.select {
  position: relative;
  border-radius: 6px;
  background-color: $white;
  border: solid 1px #d7dcde;
  overflow: hidden;
  &:after {
    content: '';
    background: $at-primary;
    mask-image: url('/bundles/app/redesign/img/fa-icons/chevron-down-solid.svg');
    mask-size: cover;
    mask-repeat: no-repeat;
    mask-position: center;
    position: absolute;
    right: 12px;
    top: 16px;
    width: 18px;
    height: 18px;
    color: $at-primary;
  }
  select {
    display: block;
    width: 100%;
    padding: 12px;
    padding-right: 36px;
    height: 48px;
    font-size: 14px;
    color: #eee;
    background-color: transparent;
    border: none;
    border-radius: 0;
    position: relative;
    z-index: 1;
    line-height: 50px;
    color: $black;
  }
  select::-ms-expand {
    display: none;
  }
  .icon {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -11px;
    width: 18px;
    color: $at-primary;
  }
  &.has-error {
    color: red;
    border-color: red;
    background-color: #ffeeee;
  }
}
.switch {
  position: relative;
  border-radius: 7px;
  background-color: $white;
  border: solid 1px $white;
  width: 100%;
  height: 37px;
  padding: 2px;
  overflow: hidden;
}
.switch-label {
  position: relative;
  z-index: 2;
  float: left;
  //   font-family: CircularStd;
  width: 50%;
  height: 100%;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 33px;
  letter-spacing: normal;
  text-align: center;
  color: #eee;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
}
.switch-label:active {
  font-weight: bold;
}
.switch-label-off {
  padding-left: 2px;
}
.switch-label-on {
  padding-right: 2px;
}
.switch-input {
  display: none;
}
.switch-input:checked + .switch-label {
  color: $at-primary;
}
.switch-input:checked + .switch-label-on ~ .switch-selection {
  transform: translateX(calc(100% + 4px));
}
.switch-selection {
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 2px;
  display: block;
  width: calc(50% - 4px);
  height: calc(100% - 4px);
  padding: inherit;
  border-radius: 3px;
  background-color: $white;
  transition: all 0.15s ease-out;
}
.multiple-switch {
  display: flex;
  .switch-input:checked + label {
    background-color: #fff;
    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  }
}
.password-input {
  .input-wrapper {
    position: relative;
    span {
      position: absolute;
      top: 14px;
      right: 10px;
      font-size: 23px;
      color: #888;
    }
  }
  p {
    margin: 0.5em 0;
    color: #a5a5a5;
  }
}
fieldset {
  .error-msg {
    margin: 5px 0 0;
    font-size: 12px;
    color: red;
  }
}
.password-input {
  p {
    font-size: to.rem(11px) !important;
    line-height: to.rem(14px);
    padding: 0 12px;
  }
}
.has-float-label {
  &,
  .form-group & {
    display: block;
    position: relative;
    width: 100%;
    input {
      font-size: to.rem(16px);
      line-height: to.rem(16px);
      padding-top: 24px;
      padding-bottom: 3px;
      &:disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }
    }
    select {
      appearance: none;
      font-size: to.rem(16px);
      line-height: to.rem(17px);
      padding-top: 24px;
      padding-bottom: 3px;
      &:active,
      &:focus {
        border: none;
        background: none;
        outline: 0;
      }
    }
    textarea {
      appearance: none;
      font-size: to.rem(16px);
      line-height: to.rem(18px);
      padding-top: 24px;
      padding-bottom: 3px;
    }
  }
  label {
    pointer-events: none;
    position: absolute;
    left: 15px;
    top: 5px;
    padding: 0;
    margin: 0;
    cursor: text;
    font-size: 80%;
    opacity: 1;
    transition: all 0.1s;
    color: #979797;
    font-weight: normal;
  }
  //   select + label {
  //     top: 4px;
  //   }
  input,
  textarea,
  select {
    // &:not(.has-value):not(.is-focused) {
    & + label {
      font-weight: 700;
      font-size: to.rem(12px);
      letter-spacing: to.rem(0.96px);
      color: #a7a7a7;
      left: 12px;
      top: 6px;
      text-transform: uppercase;
      line-height: 17px;
      max-height: 17px;
      max-width: calc(100% - 48px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    // }
  }
  select {
    & + label {
      top: 5px;
    }
  }
  //   select:not(.has-value):not(.is-focused) {
  //     opacity: 0;
  //   }
  //   i {
  //     position: absolute;
  //     right: 20px;
  //     top: 50%;
  //     transform: translateY(-50%);
  //     font-size: 20px;
  //     color: $at-primary;
  //   }
}
.suggestion-input {
  & ~ .suggestion-box {
    position: absolute;
    bottom: auto;
    margin-top: 20px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.27);
    background-color: $white;
    border-radius: 6px;
    padding: 27px;
    width: 580px;
    z-index: 20;
    display: none;
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-top: 5px solid white;
      border-bottom: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid white;
      left: 24px;
      z-index: 4;
      top: 0;
      transform: translate(0%, -50%) rotate(-45deg);
      box-shadow: 3px -3px 4px 0 rgba(0, 0, 0, 0.15);
    }
    .title {
      margin: 0 0 3px 0;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal;
      background-color: #99a6b4;
      color: $white;
      width: 100%;
      padding: 2px 12px;
    }
    .results {
      .result {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        color: #333333;
        padding: 8px 10px;
        margin: 0;
        transition: $animColor;
        &:hover {
          cursor: pointer;
          color: $at-primary;
        }
      }
    }
    .hotels,
    .citys {
      width: 50%;
      margin-right: 30px;
    }
    .hotels {
      margin-right: 0;
    }
  }
  &.suggest-open {
    & ~ .suggestion-box {
      display: block;
    }
  }
}
.rooms-input {
  & ~ .rooms-box {
    position: absolute;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.27);
    background-color: $white;
    border-radius: 6px;
    padding: 27px;
    width: 430px;
    z-index: 20;
    display: none;
    @include media-breakpoint-down(md) {
      position: fixed;
      right: 0;
      bottom: 0;
      z-index: 99999;
      width: 100%;
      overflow-x: scroll;
      padding: 0;
      background: none;
      min-width: 100vw;
      border: none;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      padding: 0;
      position: fixed;
      top: 0 !important;
      left: 0 !important;
      transform: none;
      //   max-height: 100vh;
      min-height: 100vh;
      &:before,
      &:after {
        position: fixed !important;
        transform: none !important;
        border: none !important;
      }
      &::before {
        z-index: 1;
        background: rgba(233, 233, 233, 0.97);
        display: flex;
        height: calc(100vh + 60px) !important;
        width: 100vw !important;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        bottom: -30px !important;
        pointer-events: none;
      }
      &::after {
        z-index: 2;
        background: $white;
        border: 1px solid #c9c9c9;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
        padding: 50px 20px 20px;
        display: flex;
        height: calc(100vh + 60px) !important;
        width: calc(100vw - 40px) !important;
        left: 20px !important;
        right: 20px !important;
        top: 20px !important;
        bottom: -6px !important;
      }
    }
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-top: 5px solid white;
      border-bottom: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid white;
      left: 50%;
      z-index: 4;
      top: 0;
      transform: translate(0%, -50%) rotate(-45deg);
      box-shadow: 3px -3px 4px 0 rgba(0, 0, 0, 0.15);
    }
    .room-box_header {
      position: sticky;
      top: 0;
      left: 0;
      background-color: $white;
      padding: 12px 10px;
      z-index: 50;
      display: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      @include media-breakpoint-down(md) {
        display: block;
      }
      .title {
        font-size: calc(1.38125rem + 1.575vw);
        font-weight: 800;
        letter-spacing: -0.04625rem;
      }
      .close {
        position: absolute;
        right: 8px;
        top: 10px;
        background: none;
        border: none;
        svg {
          width: 37px;
          height: 37px;
          path {
            fill: #a7a7a7;
            transition: $animFill;
          }
        }
      }
    }
    .room-box_button {
      padding: 10px 20px;
      background-color: $white;
      bottom: 0;
      display: none;
      position: sticky;
      z-index: 30;
      background-color: #fff;
      border-top: 1px solid #ddd;
      position: fixed;
      width: 100%;
      justify-content: center;
      @include media-breakpoint-down(md) {
        display: flex;
      }
      .btn {
        font-size: 0.875rem;
        margin-left: 8px;
        margin-right: 8px;
        padding: 4px 10px;
        background-color: $at-primary;
        border-color: $at-primary;
        color: $white;
      }
    }
    .rooms {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      @include media-breakpoint-down(md) {
        padding: 20px;
        margin: 0 20px;
        background-color: $white;
        z-index: 30;
        position: relative;
        margin-bottom: 40px;
      }
      .room {
        border-bottom: 1px solid $ltGray;
        padding-bottom: 6px;
        margin-bottom: 6px;
        width: 100%;
        h4 {
          margin: 0 0 3px 0;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.79;
          letter-spacing: normal;
          background-color: #99a6b4;
          color: $white;
          width: 100%;
          padding: 2px 12px;
        }
        .d-flex.justify-content-between {
          position: relative;
          &:not(.align-items-start) {
            background-color: #dcebfa;
            padding: 10px 12px;
            font-weight: bold;
          }
        }
        .pick {
          span {
            font-weight: bold;
            font-size: to.rem(16px);
            line-height: to.rem(18px);
            color: #333333;
            margin-right: 20px;
          }
          button.less,
          button.plus,
          .less,
          .plus {
            border: solid 1px $at-primary;
            width: 24px;
            height: 24px;
            max-width: 24px;
            background: $white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $animBgColor;
            cursor: pointer;
            svg {
              height: 12px;
              width: 10px;
              color: $at-primary;
              transition: $animColor;
            }
            &.disabled {
              border-color: #e0e0e0 !important;
              background: $white !important;
              cursor: default;
              svg {
                color: #e0e0e0 !important;
              }
            }
            &:hover {
              background: $at-primary;
              svg {
                color: $white;
              }
            }
          }
          input {
            border: 0px;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            width: 24px;
            height: 24px;
            background: none;
            padding: 0;
            line-height: 24px;
          }
        }
        .kids-age {
          display: none;
          background-color: #dcebfa;
          padding: 10px 12px;
          h5 {
            font-size: to.rem(14px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.64;
            letter-spacing: normal;
            color: #333333;
            margin: 0 0 3px 0;
          }
          .form-group {
            width: 100%;
            margin: 0;
            @include media-breakpoint-down(md) {
              justify-content: space-between;
              margin-bottom: 0;
            }
            .select {
              width: 84px;
              margin-right: 3px;
              @include media-breakpoint-down(md) {
                width: calc(50% - 5px);
                margin-bottom: 10px;
                margin-right: 0;
              }
              &:last-of-type {
                margin-right: 0;
              }
              &:after {
                right: 3px;
                top: 11px;
                width: 15px;
              }
              select {
                height: 37px;
                line-height: 25px;
                width: 82px;
                padding: 6px;
                padding-right: 18px;
                box-sizing: border-box;
                text-overflow: ellipsis;
                appearance: none;
                @include media-breakpoint-down(md) {
                  width: 100%;
                }
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        &.kids {
          .kids-age {
            display: block;
          }
        }
        @include media-breakpoint-down(md) {
          .align-items-start + .d-flex {
            flex-direction: column;
            .adults,
            .kids {
              span {
                margin-right: auto;
              }
            }
            .adults {
              margin-bottom: 20px;
            }
          }
        }
      }
      &.delete {
        .remove-room {
          display: flex;
        }
      }
    }
    .add-room {
      display: flex;
      margin-top: 9px;
      align-items: center;
      font-size: 0.75rem;
      padding: 4px 10px;
      text-transform: uppercase;
      svg {
        margin-right: 4px;
        height: 12px;
      }
    }
    .remove-room {
      display: none;
      background: none;
      border: none;
      align-items: center;
      position: absolute;
      font-size: to.rem(12px);
      line-height: to.rem(12px);
      right: 2px;
      top: 6px;
      opacity: 0.6;
      color: $white;
      transition: $animOpacity;
      svg {
        margin-right: 4px;
        height: 14px;
      }
      &:hover {
        opacity: 1;
      }
    }
    .add-room {
      @include media-breakpoint-down(md) {
        position: sticky;
        bottom: 0;
      }
    }
  }
  &.rooms-open {
    & ~ .rooms-box {
      display: block;
    }
    .form-input {
      border-color: $at-primary;
    }
  }
}
