.slider-info-wrap {
  position: relative;
  padding: 30px 0 40px;
  @include media-breakpoint-up(lg) {
    padding: 30px 0 80px;
  }
  > .container {
    position: relative;
    z-index: 10;
  }
}
.slider-info {
  min-height: auto;
  .swiper-wrapper {
    .container {
      z-index: 10;
      visibility: visible;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
      height: auto;
      @include media-breakpoint-up(md) {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: -3px;
          background: rgba(0, 0, 0, 0.08);
          width: 1px;
          height: 100%;
        }
      }
      &::before {
        content: '';
        position: absolute;
        background-color: $ltGray;
        border-radius: 10px;
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
      }
      &:hover {
        &::before {
          opacity: 0.4;
          animation: BounceSwiperNav 0.3s;
        }
      }
    }
  }
  .infoitem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none;
    background-color: transparent;
    transition: $animBgColor;
    padding: 20px 14px 1px;
    min-width: 100%;
    box-sizing: border-box;
    z-index: 2;
    svg {
      width: 50px;
      height: 50px;
      path {
        fill: $at-primary;
        transition: $animFill;
      }
    }
    .title {
      padding: 14px 0 8px;
      font-weight: 800;
      font-size: to.rem(26px);
      line-height: to.rem(32px);
      letter-spacing: to.rem(-0.5px);
      color: $dkGray;
      transition: $animColor;
      @include media-breakpoint-up(lg) {
        font-size: to.rem(28px);
      }
    }
    p {
      font-size: to.rem(16px);
      line-height: to.rem(25px);
      color: #535353;
    }
  }
}
.nav-slider-info {
  .swiper-button-prev,
  .swiper-button-next {
    color: $ltGray;
  }
}
