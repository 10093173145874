// at-grid-hotels.scss
.at-grid-hotels {
  margin-top: 0;
  padding-top: 94px;
  margin-bottom: 42px;
  .btn-mas {
    margin-top: 62px;
    padding-bottom: 54px;
  }
  #no-results {
    margin-top: -64px;
    .empty-list-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 32px;
    }
  }
  #no-results-blocks {
    & + .container-inner {
      display: none;
    }
    & + .container-inner + .separadorlinea {
      display: none;
    }
  }
}
