.at-fila-download-app {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding-top: 74px;
  &.sinflechatop {
    padding-top: 0;
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.15) 100%
      );
      width: 100%;
      height: 48px;
      pointer-events: none;
      z-index: 6;
      @include media-breakpoint-down(lg) {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.75) 0%,
          rgba(0, 0, 0, 0.02) 96.85%,
          rgba(0, 0, 0, 0) 100%
        );
        height: 90%;
      }
    }
    &::after {
      top: auto;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.15) 100%
      );
    }
    .container {
      z-index: 6;
      visibility: visible;
      min-height: 400px;
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 6;
        margin: 0 auto;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 33, 0.45) 0%,
          rgba(0, 0, 33, 0) 100%
        );
        max-width: 940px;
        right: auto;
        left: 40px;
        display: none;
        @include media-breakpoint-up(lg) {
          display: block;
          content: '';
        }
      }
      &::after {
        position: absolute;
        background: rgba(0, 0, 33, 0.45);
        left: calc(-100% + 40px);
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 7;
        display: none;
        @include media-breakpoint-up(lg) {
          display: block;
          content: '';
        }
      }
    }
    .app-data {
      justify-content: center;
      padding: 36px 0 200px;
      position: relative;
      z-index: 8;
      @include media-breakpoint-up(lg) {
        padding: 20px 0 0;
      }
    }
    .app-preview {
      position: absolute;
      bottom: -150px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 540px;
      margin: 0;
      z-index: 7;
      @include media-breakpoint-up(lg) {
        max-width: 600px;
        top: 50%;
        transform: translateY(-50%);
        left: auto;
        bottom: auto;
        right: -32%;
      }
      @include media-breakpoint-up(xl) {
        max-width: 680px;
        right: -22%;
        top: -10px;
        transform: none;
      }
      @include media-breakpoint-up(xxl) {
        right: -12%;
        max-width: inherit;
      }
    }
  }
  .img-bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    z-index: 5;
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
    min-width: 100vw;
  }
  .container {
    position: relative;
    z-index: 10;
    .container-inner {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }
  }
  .app-icon {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      height: 210px;
      width: 146px;
      align-self: end;
    }
    @include media-breakpoint-up(xl) {
      height: 317px;
      width: 221px;
    }
  }
  .app-data {
    padding-top: 64px;
    max-width: 100%;
    align-items: center;
    text-align: center;
    @include media-breakpoint-up(lg) {
      align-items: flex-start;
      text-align: left;
      max-width: 608px;
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
  .heading {
    font-weight: 800;
    font-size: to.rem(32px);
    line-height: to.rem(40px);
    letter-spacing: to.rem(-0.48px);
    color: $white;
    margin-bottom: 14px;
  }
  .parrafo {
    font-size: to.rem(16px);
    line-height: to.rem(25px);
    color: $white;
    margin-bottom: 24px;
  }
  .btns {
    > a {
      font-size: 0;
      margin-right: 6px;
      img {
        object-fit: cover;
        max-height: 40px;
      }
    }
  }
  .app-preview {
    margin-top: 15px;
    margin-bottom: -78px;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-bottom: 14px;
      max-width: 300px;
      object-fit: contain;
    }
    @include media-breakpoint-up(xl) {
      max-width: 400px;
    }
    @include media-breakpoint-up(xxl) {
      max-width: inherit;
    }
  }
}
