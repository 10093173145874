:root {
  --swiper-navigation-size: 72px;
  --swiper-theme-color: #ffffff;
}
.slider-cuadrado-wrap {
  position: relative;
  margin-top: 40px;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    > .container {
      margin: 0;
      padding: 0;
    }
  }
}
.slider-cuadrado {
  overflow: inherit;
  margin-bottom: 12px;
  .swiper-wrapper {
    .swiper-slide-duplicate {
      opacity: 0.3;
      pointer-events: none;
    }
    .swiper-slide {
      overflow: hidden;
      opacity: 0.3;
      transition: $animOpacity;
      pointer-events: none;
      &.swiper-slide-active {
        opacity: 1;
        pointer-events: initial;
      }
      @include media-breakpoint-up(xxl) {
        &.swiper-slide-prev,
        &.swiper-slide-next {
          opacity: 1;
          pointer-events: initial;
        }
      }
    }
    .container {
      z-index: 10;
      visibility: visible;
    }
  }
}
