// at-filter-select.scss
.at-filter-select {
  padding-top: 84px;
  .container-inner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .h1 {
      text-align: center;
      margin-bottom: 0;
    }
    .select-wrap {
      position: relative;
      color: #808080;
      margin-left: 14px;
      &::after {
        content: '';
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: #808080;
      }
      svg {
        position: absolute;
        top: 50%;
        margin-top: -15px;
        right: 0;
        width: 24px;
        pointer-events: none;
        transition: $animAll;
      }
      .edd-root-open + svg {
        transform: rotate(180deg);
      }
      #rangepicker {
        position: absolute;
        right: 50%;
        margin-right: 5px;
        bottom: 20px;
        opacity: 0;
      }
    }
    .edd-root {
      font-weight: 800;
      font-size: calc(1.38125rem + 1.575vw);
      line-height: 1.2;
      letter-spacing: -0.04625rem;
      color: #808080;
      width: auto;
      @include media-breakpoint-up(lg) {
        font-size: 2.5625rem;
      }
      .edd-head {
        background: none;
        border: none;
        // margin-bottom: 1.3rem;
        top: -1px;
        .edd-arrow {
          display: none;
        }
        .edd-value {
          border-right: none;
          display: flex;
          margin: 0;
          box-sizing: border-box;
          padding-right: 30px;
          width: 100%;
        }
      }
      .edd-body {
        min-width: auto;
        @include media-breakpoint-up(md) {
          min-width: 300px;
        }
      }
      .edd-items-list {
        font-size: to.rem(24px);
        letter-spacing: to.rem(-0.35);
        font-weight: 700;
      }
    }
  }
}
.edd-root,
.edd-root *,
.edd-root *::before,
.edd-root *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.edd-root {
  display: inline-block;
  position: relative;
  width: 180px;
  user-select: none;
  font-size: 16px;
  color: #333;
}
.edd-root-disabled {
  color: #ccc;
  cursor: not-allowed;
}
.edd-head {
  position: relative;
  overflow: hidden;
  border: 1px solid #eee;
  transition: box-shadow 200ms, border-color 150ms;
  background: white;
}
.edd-head,
.edd-body {
  border-radius: 6px;
}
// .edd-root-focused .edd-head {
//   box-shadow: 0 0 5px rgba(105, 215, 255, 0.4);
// }
// .edd-root-invalid .edd-head {
//   box-shadow: 0 0 5px rgba(255, 105, 105, 0.671);
// }
.edd-root:not(.edd-root-disabled):not(.edd-root-open) .edd-head:hover {
  border-color: #ccc;
}
.edd-value {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  border-right: 1px solid #eee;
}
// .edd-arrow {
//   position: absolute;
//   width: 18px;
//   height: 10px;
//   top: calc(50% - 5px);
//   right: calc(24px - 9px);
//   transition: transform 150ms;
//   pointer-events: none;
// }
// .edd-arrow::before {
//   content: '';
//   position: absolute;
//   width: 13px;
//   height: 13px;
//   border-right: 1px solid currentColor;
//   border-bottom: 1px solid currentColor;
//   top: -5px;
//   right: 0;
//   transform: rotate(45deg);
//   transform-origin: 50% 25%;
// }
// .edd-root-open .edd-arrow {
//   transform: rotate(180deg);
// }
.edd-value,
.edd-option,
.edd-group-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.edd-root:not(.edd-root-disabled) .edd-value,
.edd-option {
  cursor: pointer;
}
.edd-select {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: -100%;
  top: 0;
}
.edd-root-native .edd-select {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.edd-body {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid #eee;
  pointer-events: none;
  overflow: hidden;
  margin: 8px 0;
  z-index: 999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transform: scale(0.95);
  background: white;
}
.edd-root-open .edd-body {
  opacity: 1;
  pointer-events: all;
  transform: scale(1);
  transition: opacity 200ms,
    transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.edd-root-open-above .edd-body {
  bottom: 100%;
}
.edd-root-open-below .edd-body {
  top: 100%;
}
.edd-items-list {
  overflow: auto;
  max-height: 0;
  transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-overflow-scrolling: touch;
}
.edd-group-label {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: 12px 8px 4px;
  color: #999;
}
.edd-group-has-label {
  border-bottom: 1px solid #eee;
}
.edd-option {
  padding: 4px 8px;
}
.edd-group-has-label .edd-option {
  padding-left: 20px;
}
// .edd-option-selected {
//   font-weight: bold;
// }
.edd-option-focused:not(.edd-option-disabled) {
  color: $at-primary !important;
}
.edd-option-disabled,
.edd-group-disabled .edd-option {
  cursor: default;
  color: #ccc;
}
.edd-gradient-top,
.edd-gradient-bottom {
  content: '';
  position: absolute;
  left: 2px;
  right: 2px;
  height: 32px;
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: repeat-x;
  background-size: 100% 200%;
  pointer-events: none;
  transition: opacity 100ms;
  opacity: 0;
}
.edd-gradient-top {
  background-position: bottom;
  top: 0;
}
.edd-gradient-bottom {
  background-position: top;
  bottom: 0;
}
.edd-body-scrollable .edd-gradient-top,
.edd-body-scrollable .edd-gradient-bottom {
  opacity: 1;
}
.edd-body-scrollable.edd-body-at-top .edd-gradient-top,
.edd-body-scrollable.edd-body-at-bottom .edd-gradient-bottom {
  opacity: 0;
}
