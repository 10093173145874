// at-item-vertical.scss
.at-item-vertical {
  .img-bg {
    position: relative;
    z-index: 5;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover;
  }
  .cont {
    position: relative;
    position: relative;
    height: 100%;
    display: flex;
    z-index: 5;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.75) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      width: 100%;
      height: 182px;
      z-index: 10;
      transition: $animHeight;
    }
    .text-center {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: 15;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 140px;
      padding: 0 22px 28px;
      box-sizing: border-box;
      max-height: 100%;
      overflow: hidden;
      .cat {
        margin: 12px 0 4px;
      }
      .heading {
        font-weight: 800;
        font-size: to.rem(37px);
        line-height: to.rem(43px);
        letter-spacing: to.rem(-0.55px);
        color: #ffffff;
        max-height: to.rem(215px);
        overflow: hidden;
      }
      .calcChildSize {
        overflow: hidden;
        height: 0;
        transition: $animHeight;
      }
      .parrafo {
        font-size: to.rem(18px);
        line-height: to.rem(28px);
        max-height: to.rem(154px);
        overflow: hidden;
        color: #ffffff;
        text-shadow: 1px 1px 0.55px rgba(0, 0, 33, 0.4);
        margin: 0;
        padding: 10px 0 4px;
      }
    }
    .plandata {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: -48px;
      height: 49px;
      background: $black;
      z-index: 11;
      color: #b3b3b3;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-weight: 700;
        font-size: to.rem(12px);
        line-height: to.rem(12px);
        letter-spacing: to.rem(-0.18px);
        color: #b3b3b3;
        text-transform: uppercase;
      }
      .planuser {
        position: absolute;
        bottom: 100%;
        left: 50%;
        background: $black;
        padding: 4px 5px 4px 20px !important;
        box-sizing: border-box;
        transform: translateX(-50%);
        font-weight: 700;
        font-size: to.rem(11px);
        line-height: to.rem(20px);
        letter-spacing: to.rem(0.66px);
        color: #8d8d8d;
        text-transform: uppercase;
        display: block;
        white-space: nowrap;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        .icon {
          left: 5px;
          position: absolute;
          top: calc(50% - 1px);
          transform: translateY(-50%);
          svg {
            width: 11px;
            height: 11px;
          }
        }
      }
      .number {
        position: relative;
        top: 1px;
        font-size: to.rem(16px);
        letter-spacing: to.rem(-0.24px);
        color: #e9e9e9;
      }
      & > div {
        position: relative;
        padding-right: 23px;
        &::after {
          content: '|';
          position: absolute;
          right: 9px;
          top: 1px;
          font-weight: 800;
          font-size: to.rem(18px);
          color: #4d4d4d;
        }
        &:last-of-type,
        &.planuser {
          padding-right: 0;
          &::after {
            display: none;
          }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      &:hover {
        &::before {
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.97) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          height: 437px;
        }
        .cat {
          color: $black;
          background-color: $white;
        }
        .calcChildSize {
          height: var(--calc-height);
        }
      }
    }
  }
  &.at-item-planes {
    .cont .text-center {
      padding-bottom: 40px !important;
    }
  }
}
