// at-detalle-contenido.scss
.at-detalle-contenido {
  position: relative;
  overflow: hidden;
  .fecha-wrap {
    display: flex;
    justify-content: center;
    padding-top: 42px;
    .fecha {
      background: #d8d8d8;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 9px 26px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -11px;
        margin-left: -5px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 11px 10px 0 10px;
        border-color: #d8d8d8 transparent transparent transparent;
      }
      svg {
        color: $at-primary;
        height: 28px;
        margin-right: 6px;
      }
      .h4,
      h3 {
        margin: 0;
      }
    }
  }
  .subtitulo {
    padding-top: 38px;
    font-size: to.rem(22px);
    line-height: to.rem(34px);
    letter-spacing: to.rem(-0.32px);
    color: #4d4d4d;
    text-align: center;
    @include media-breakpoint-up(lg) {
      font-size: to.rem(32px);
      line-height: to.rem(44px);
      letter-spacing: to.rem(-0.48px);
    }
  }
  .mapzone {
    background: $white;
    border-radius: 8px;
    padding: 0 18px 18px;
    margin-top: 42px;
    margin-bottom: 50px;
    .row {
      flex-direction: column;
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }
    svg {
      color: #8d8d8d;
      max-width: 18px;
      height: 18px;
      transition: $animColor;
    }
    .col {
      position: relative;
      min-width: 100%;
      @include media-breakpoint-up(md) {
        min-width: 50%;
      }
      @include media-breakpoint-up(xxl) {
        min-width: 33%;
      }
      .title {
        font-weight: 800 !important;
        font-size: to.rem(26px) !important;
        line-height: to.rem(26px) !important;
        color: $dkGray !important;
        margin-bottom: 16px !important;
        margin-top: 20px !important;
      }
      .icon {
        min-width: 25px;
        display: flex;
        justify-content: center;
        margin-right: 5px;
        position: relative;
        top: 2px;
        &.chevron {
          margin-left: auto;
          svg {
            transition: $animAll;
          }
        }
      }
      &.map {
        .at-mapa {
          max-height: 180px;
          min-height: 180px;
          .mapboxgl-ctrl-bottom-right {
            display: none;
          }
          .modal-box {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
            pointer-events: none;
            .modal-mapa {
              display: flex;
              border-radius: 6px;
              background-color: rgba(0, 0, 0, 0.4);
              padding: 10px;
              font-weight: 700;
              color: $white;
              cursor: pointer;
              transition: $animBgColor;
              pointer-events: all;
              &:hover {
                background-color: rgba(0, 0, 0, 0.9);
              }
              svg {
                color: $white;
                width: 22px;
                margin-right: 5px;
              }
              span {
                flex-shrink: 0;
              }
            }
          }
        }
      }
      &:not(.map) {
        p,
        a,
        .timetoggle,
        .shadow-url {
          display: flex;
          align-items: flex-start;
          margin: 0 0 8px;
          font-weight: 700;
          font-size: to.rem(16px);
          line-height: to.rem(22px);
          color: #4d4d4d;
          word-break: break-word;
          transition: $animColor;
        }
        a,
        .timetoggle,
        .shadow-url {
          text-decoration: none;
          color: $at-primary;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
            color: $black;
            svg {
              color: $black;
            }
          }
        }
      }
      .timegoggle {
        position: relative;
        .timetoggle {
          cursor: pointer;
          display: flex;
          .icon {
            display: flex;
            align-items: center;
          }
        }
        .calcChildSize {
          overflow: hidden;
          height: 0;
          transition: $animHeight;
          font-size: to.rem(14px);
          line-height: to.rem(20px);
          @include media-breakpoint-up(xl) {
            position: absolute;
            left: 50%;
            top: calc(100% + 4px);
            transform: translateX(-50%);
            z-index: 10;
            padding-top: 10px;
            &::before {
              content: '';
              opacity: 0;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 10px 10px 10px;
              border-color: transparent transparent rgba(0, 0, 0, 0.15)
                transparent;
              z-index: 10;
              transition: $animOpacity;
            }
            > div {
              padding: 10px 16px;
              position: relative;
              background-clip: padding-box;
              background-color: #fff;
              border: 1px solid rgba(0, 0, 0, 0.15);
              border-radius: 0.25rem;
            }
          }
          ul {
            list-style: none;
            margin: 0 0 0.4rem 0;
            li {
              white-space: nowrap;
            }
          }
          .HuudEc {
            display: none;
          }
          .ylH6lf {
            min-width: 60px;
            padding: 3px 5px 3px 30px;
            max-width: 150px;
            vertical-align: text-top;
            @include media-breakpoint-up(xl) {
              padding: 3px 5px;
            }
          }
        }
        &.show {
          .calcChildSize {
            height: var(--calc-height);
            @include media-breakpoint-up(xl) {
              height: calc(var(--calc-height) + 10px);
              &::before {
                opacity: 1;
              }
            }
          }
          .icon {
            &.chevron {
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
      .timelabel {
        display: flex;
        justify-content: center;
        .img {
          width: 110px;
          height: 19px;
          position: absolute;
          transform: rotate(-7deg);
          bottom: -28px;
        }
        .label {
          background-color: #008045;
          font-size: to.rem(16px);
          line-height: to.rem(16px);
          color: $white;
          font-weight: 700;
          white-space: nowrap;
          text-transform: uppercase;
          padding: 8px 17px 7px;
          position: absolute;
          transform: rotate(-7deg);
          bottom: -54px;
          &.cerrado {
            background-color: #de001a;
          }
          &.alerta {
            background-color: #eb7f00;
          }
        }
      }
    }
  }
  %subsubtitulo {
    font-weight: 800;
    font-size: to.rem(30px);
    line-height: to.rem(36px);
    letter-spacing: to.rem(-0.54px);
    color: $dkGray;
    @include media-breakpoint-up(md) {
      font-size: to.rem(36px);
      line-height: to.rem(42px);
    }
    @include media-breakpoint-up(xxl) {
      font-size: to.rem(42px);
      line-height: to.rem(52px);
      letter-spacing: to.rem(-0.76px);
    }
  }
  .subsubtitulo {
    @extend %subsubtitulo;
    > * {
      @extend %subsubtitulo;
    }
  }
  #detailcontainer {
    margin-top: 42px;
    padding-bottom: 50px;
    .btn {
      font-size: to.rem(12px);
      letter-spacing: to.rem(0.72px);
      color: #4d4d4d;
      border-color: #b3b3b3;
      margin-top: 16px;
      padding: 9px 18px;
      &:hover {
        background-color: $white;
      }
    }
    .deg {
      position: relative;
      background: linear-gradient(
        0deg,
        rgba(233, 233, 233, 1) 0%,
        rgba(233, 233, 233, 0) 100%
      );
      width: 100%;
      height: 124px;
      margin-top: -124px;
      z-index: 10;
      opacity: 1;
      pointer-events: none;
      transition: $animOpacity;
    }
    .button-close {
      margin-bottom: 4px;
    }
    .separadorlinea {
      margin-top: 50px;
    }
    .calcChildSize {
      overflow: hidden;
      height: 0;
      transition: $animHeight;
    }
    .info-detall {
      & ul + .calcChildSize {
        margin-top: -1rem;
      }
    }
    &.show {
      #desplegadet-btn {
        display: none;
      }
      .deg {
        opacity: 0;
      }
      .calcChildSize {
        height: var(--calc-height);
      }
    }
  }
  .title {
    font-weight: 800;
    font-size: to.rem(21px);
    line-height: to.rem(27px);
    letter-spacing: to.rem(-0.38px);
    color: $dkGray;
    margin-bottom: 24px;
  }
  .precionoche {
    padding: 42px 0 30px;
    p {
      font-weight: 700;
      font-size: to.rem(14px);
      line-height: to.rem(20px);
      letter-spacing: to.rem(-0.21px);
      color: $dkGray;
      padding: 0;
      margin: 0;
      display: inline-block;
      @include media-breakpoint-up(md) {
        font-size: to.rem(24px);
        line-height: to.rem(44px);
        letter-spacing: to.rem(-0.36px);
      }
    }
    .price_container {
      display: block;
      padding: 42px 0 30px;
      background-color: $white;
      border-radius: 10px;
      position: relative;
      padding: 26px 30px 30px;
      border: 5px solid #d8d8d8;
      text-align: center;
      @include media-breakpoint-up(xxl) {
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
      &::before,
      &::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 17px 0 17px;
        border-color: #ffffff transparent transparent transparent;
        position: absolute;
        bottom: -20px;
        left: 50%;
        margin-left: -17px;
        z-index: 10;
      }
      &::after {
        border-width: 27px 24px 0 24px;
        border-color: #d8d8d8 transparent transparent transparent;
        position: absolute;
        bottom: -27px;
        left: 50%;
        margin-left: -24px;
        z-index: 5;
      }
      .btn {
        margin-top: 10px;
        font-size: to.rem(12px);
        @include media-breakpoint-up(md) {
          font-size: to.rem(16px);
        }
        @include media-breakpoint-up(xxl) {
          margin: 0 0 0 30px;
        }
      }
      .price {
        margin: 0 4px;
      }
      .number {
        font-weight: 800;
        font-size: to.rem(38px);
        @include media-breakpoint-up(md) {
          font-weight: 700;
          font-size: to.rem(48px);
        }
      }
      .symbol {
        font-size: to.rem(31px);
      }
    }
    .stickybar {
      position: fixed;
      left: 0;
      right: 0;
      width: 100%;
      background-color: $at-primary;
      color: $white;
      z-index: 15;
      align-items: center;
      display: flex;
      justify-content: space-evenly;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
      padding: 0 24px;
      box-sizing: border-box;
      .price {
        @include media-breakpoint-down(md) {
          display: flex;
          flex-direction: column;
        }
        span {
          font-size: to.rem(14px);
          line-height: to.rem(14px);
          letter-spacing: to.rem(-0.21px);
          font-weight: 700;
          display: flex;
          align-items: center;
          span {
            font-size: to.rem(28px);
            line-height: to.rem(28px);
            letter-spacing: 0;
            font-weight: 800;
            &.symbol {
              margin-left: 5px;
              font-size: to.rem(21px);
            }
          }
        }
      }
      .btn {
        background-color: $white;
        color: $at-primary !important;
        font-size: to.rem(12px);
        line-height: to.rem(14px);
        letter-spacing: to.rem(0.18px);
        margin: 8px 14px;
        padding: 10px 14px;
      }
      .planificador {
        svg {
          width: 32px;
        }
      }
    }
  }
  .servicios {
    width: 100%;
    padding-top: 42px;
    .servicios-list {
      width: 100%;
      flex-direction: column;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }
      .servicio {
        font-size: to.rem(14px);
        line-height: to.rem(16px);
        flex: 1 0 20%;
        max-width: inherit;
        margin-bottom: 32px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        @include media-breakpoint-up(sm) {
          flex: 1 0 50%;
          max-width: 50%;
        }
        @include media-breakpoint-up(lg) {
          flex: 1 0 33.3%;
          max-width: 33.3%;
        }
        @include media-breakpoint-up(xl) {
          flex: 1 0 25%;
          max-width: 25%;
        }
        @include media-breakpoint-up(xxl) {
          flex: 1 0 20%;
          max-width: 20%;
        }
        span {
          margin-right: 8px;
          font-size: 24px;
        }
      }
    }
  }
  .btnsytrip {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    margin-top: 44px;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
    }
    .botones {
      margin-right: 22px;
    }
    .tripadvisor {
      flex: 2;
      max-height: 50px;
      overflow: hidden;
      margin-bottom: 20px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
      .cdsROW {
        width: 100% !important;
        background: none !important;
        border: none !important;
        .cdsLocName {
          max-width: none !important;
          display: none;
          @include media-breakpoint-up(md) {
            display: table-cell;
          }
        }
        .cdsRating {
          padding-left: 7px;
          @include media-breakpoint-up(md) {
            padding-left: 0;
          }
        }
      }
    }
  }
  .datos-itinerario {
    background-color: rgba(179, 179, 179, 0.3);
    min-height: 150px;
    .graficas {
      display: flex;
      flex-wrap: wrap;
      margin-top: 44px;
      width: 100%;
      .grafica {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 2 0 100%;
        position: relative;
        box-sizing: border-box;
        padding: 28px;
        @include media-breakpoint-up(md) {
          align-items: flex-start;
          flex: 2 0 50%;
        }
        @include media-breakpoint-up(xxl) {
          flex: 2;
        }
        &::after,
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          background: $white;
          width: 100%;
          height: 1px;
        }
        &::before {
          bottom: -1px;
          background: rgba(0, 0, 0, 0.08);
        }
        @include media-breakpoint-up(md) {
          &:nth-child(1),
          &:nth-child(2) {
            &::after,
            &::before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              background: $white;
              width: 1px;
              height: 100%;
            }
            &::before {
              right: -1px;
              background: rgba(0, 0, 0, 0.08);
            }
          }
          &:nth-child(2) {
            &::before,
            &::after {
              display: none;
            }
          }
          &:nth-child(3),
          &:nth-child(4) {
            &::after,
            &::before {
              display: block;
              bottom: auto;
              top: 0;
            }
            &::before {
              top: -1px;
            }
          }
        }
        @include media-breakpoint-up(xxl) {
          &:nth-child(2) {
            &::before,
            &::after {
              display: block;
            }
          }
          &:nth-child(3) {
            &::before,
            &::after {
              display: none;
            }
          }
        }
        &.concanvas {
          padding: 28px 28px 0 0;
          &::after,
          &::before {
            display: none;
          }
          @include media-breakpoint-up(md) {
            &::after,
            &::before {
              display: block;
            }
          }
          @include media-breakpoint-up(xxl) {
            &::after,
            &::before {
              display: none;
            }
          }
        }
        .title {
          text-transform: uppercase;
          font-weight: normal;
          font-size: to.rem(16px);
          line-height: to.rem(21px);
          letter-spacing: to.rem(2.4px);
          color: #2a221e;
          margin-bottom: 24px;
        }
        .number {
          font-weight: 800;
          font-size: to.rem(26px);
          line-height: to.rem(30px);
          color: $dkGray;
          span {
            font-size: to.rem(46px);
          }
        }
        .level {
          width: 100%;
          .valor {
            font-weight: 800;
            font-size: to.rem(26px);
            line-height: to.rem(26px);
            letter-spacing: -0.26px;
            margin-bottom: 12px;
            text-align: center;
            @include media-breakpoint-up(md) {
              text-align: left;
            }
          }
          .rectagulos {
            display: flex;
            .rect {
              background: $white;
              width: 100%;
              height: 8px;
              margin-right: 4px;
              &:last-child {
                margin-right: 0;
              }
              &.lleno {
                background: $dkGray;
              }
            }
          }
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
}
#top-of-site-pixel-anchor {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
  top: 10px;
  left: 0;
}
.stickybar {
  visibility: hidden;
  bottom: -60px;
  transition: $animAll;
}
.header-not-at-top {
  .stickybar {
    @include media-breakpoint-down(xxl) {
      bottom: 0;
      visibility: visible;
    }
  }
}
