// at-clima-contenido.scss
.at-clima-contenido {
  position: relative;
  .temps-top {
    padding-bottom: 88px;
  }
  .datos-big-wrap {
    position: relative;
    min-height: 258px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    @include media-breakpoint-up(sm) {
      min-height: 310px;
    }
    @include media-breakpoint-up(xxl) {
      border-top-right-radius: 0;
      border-bottom-left-radius: 10px;
    }
    .datos-big {
      position: relative;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      .dia {
        font-weight: 800;
        font-size: to.rem(24px);
        line-height: to.rem(28px);
        color: #f1f1f1;
        text-transform: uppercase;
        padding: 6px 0;
        @include media-breakpoint-up(sm) {
          font-size: to.rem(32px);
        }
      }
      .weather {
        display: flex;
        align-items: center;
        padding: 10px 0;
        font-size: to.rem(57px);
        line-height: to.rem(68px);
        letter-spacing: to.rem(-1.62px);
        color: $white;
        font-weight: 800;
        @include media-breakpoint-up(sm) {
          padding: 30px 0;
          font-size: to.rem(81px);
          line-height: to.rem(70px);
        }
        .icono {
          font-size: to.rem(120px);
          line-height: to.rem(50px);
          @include media-breakpoint-up(sm) {
            font-size: to.rem(180px);
            line-height: to.rem(70px);
          }
        }
      }
      .datos {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: to.rem(28px);
        line-height: to.rem(34px);
        letter-spacing: to.rem(-0.84px);
        color: #a7a7a7;
        @include media-breakpoint-up(sm) {
          flex-direction: row;
          font-size: to.rem(42px);
          line-height: to.rem(42px);
          .max {
            margin-right: 22px;
          }
        }
        .min {
          margin-right: 22px;
        }
      }
    }
    .img-bg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      z-index: 5;
      min-width: 100%;
      min-height: 100%;
    }
  }
  .temperatura,
  .weather {
    font-weight: 800;
    .max {
      color: #ff0000;
    }
    .min {
      color: $at-primary;
    }
    span span {
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  .item-temps {
    display: block;
    background-color: $white;
    border: 1px solid #d7dcde;
    border-radius: 10px;
    text-align: left;
    position: relative;
    padding: 26px 86px 14px 18px;
    @include media-breakpoint-up(sm) {
      min-height: 310px;
      padding: 0;
      padding-top: 32px;
      text-align: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-content: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      align-content: center;
    }
    .title {
      font-weight: 700;
      font-size: to.rem(21px);
      line-height: to.rem(28px);
      color: $dkGray;
      @include media-breakpoint-up(sm) {
        font-size: to.rem(24px);
      }
    }
    .temperatura {
      font-size: to.rem(24px);
      line-height: to.rem(24px);
      @include media-breakpoint-down(sm) {
        font-size: to.rem(18px);
        display: inline-block;
      }
    }
    .icono {
      position: absolute;
      font-size: to.rem(80px);
      line-height: to.rem(80px);
      padding: 0;
      color: #8d8d8d;
      right: 6px;
      top: 8px;
      @include media-breakpoint-up(sm) {
        right: auto;
        padding: 16px 0;
        top: 0;
        transform: none;
        position: relative;
        font-size: to.rem(108px);
        line-height: to.rem(108px);
      }
    }
    .wind {
      font-size: to.rem(18px);
      letter-spacing: to.rem(-0.18px);
      font-weight: 700;
      strong {
        font-weight: 800;
      }
      @include media-breakpoint-up(sm) {
        font-size: to.rem(21px);
        letter-spacing: to.rem(-0.21px);
      }
    }
    .desglose {
      display: flex;
      flex-direction: column;
      min-width: 100%;
      padding: 10px 18px 6px;
      margin: 28px -86px 0 -18px;
      background: linear-gradient(
        0deg,
        rgba(233, 233, 233, 0) 0%,
        rgba(233, 233, 233, 1) 100%
      );
      @include media-breakpoint-up(sm) {
        margin: 28px 0 0;
        padding: 10px 0 32px;
      }
      .item {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 8px 0;
        @include media-breakpoint-up(sm) {
          padding: 15px 0;
        }
      }
      .title {
        text-align: center;
        min-width: 100%;
        font-size: to.rem(14px);
        line-height: to.rem(18px);
        margin-bottom: 6px;
      }
      .temperatura,
      .wind {
        font-size: to.rem(16px);
        line-height: to.rem(20px);
      }
      .temperatura {
        .max {
          margin-left: 6px;
        }
      }
      .wind {
        margin-left: 10px;
      }
    }
  }
  .wind {
    font-weight: 700;
    @include media-breakpoint-down(sm) {
      display: inline-block;
    }
    strong,
    span {
      font-weight: 800;
    }
  }
  .setmana {
    padding: 26px 0;
    @include media-breakpoint-up(sm) {
      padding: 26px 0 88px;
    }
  }
}
