.slider-icos-wrap {
  position: relative;
  padding: 20px 0 50px;
  > .container {
    position: relative;
    z-index: 10;
  }
}
.slider-icos {
  min-height: auto;
  .swiper-wrapper {
    .container {
      z-index: 10;
      visibility: visible;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
    }
  }
  .propostes {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none;
    background-color: transparent;
    transition: $animBgColor;
    border-radius: 10px;
    padding: 20px 5px 28px;
    min-width: 130px;
    box-sizing: border-box;
    svg {
      width: 80px;
      height: 80px;
      path {
        fill: $at-primary;
        transition: $animFill;
      }
    }
    .proposta {
      font-weight: 800;
      font-size: to.rem(21px);
      line-height: to.rem(30px);
      color: #3c3c3c;
      transition: $animColor;
      text-transform: lowercase;
      &::first-letter {
        text-transform: capitalize;
      }
    }
    &:hover {
      background-color: $at-primary;
      .proposta {
        color: $white;
      }
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}
.nav-slider-icos {
  .swiper-button-prev,
  .swiper-button-next {
    color: #d1d1d1;
  }
}
