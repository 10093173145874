.slider-agenda {
  position: relative;
  margin-bottom: 50px;
  .container-inner {
    overflow: hidden;
    padding: 0 8px;
    @include media-breakpoint-up(md) {
      margin: 0 42px;
    }
    @include media-breakpoint-up(lg) {
      margin: 0 92px;
    }
    @include media-breakpoint-up(xl) {
      margin: 0 142px;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: #d1d1d1;
  }
}
