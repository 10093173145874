// Heredador REVISAR!!!!!!

.searcher {
  //   padding-top: 60px;
  position: relative;
  z-index: 20;
  width: 100%;
  .searcher__search-box {
    // background-color: #fff;
    // padding: 30px;
    // margin-top: 30px;
    // @include media-breakpoint-down(md) {
    //   padding: 18px 15px;
    //   margin: 30px 5px 30px 5px;
    // }
    .subtitle-section {
      margin: 0 0 22px 0;
      padding: 0;
      @include media-breakpoint-down(md) {
        text-align: left;
        padding-left: 0;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
      }
    }
    .searcher__search-from {
      width: 100%;
      form {
        flex-wrap: wrap;
        @include media-breakpoint-down(md) {
          flex-direction: column;
        }
        .form-group {
          margin: 6px 0;
          position: relative;
          flex: 1;
          width: 100%;
          min-width: 100%;
          @include media-breakpoint-up(md) {
            margin: 6px;
            min-width: 40%;
          }
          @include media-breakpoint-up(xxl) {
            width: auto;
            min-width: inherit;
          }
          .form-input {
            &:focus {
              border-color: $at-primary;
            }
          }
        }
        .button {
          margin: 6px 0;
          width: 100%;
          @include media-breakpoint-up(md) {
            margin: 6px;
            width: auto;
          }
          .btn {
            width: 100%;
          }
          //   flex: 1;
          //   max-width: 165px;
          //   @include media-breakpoint-down(md) {
          //     max-width: 100%;
          //   }
          //   @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
          //     max-width: 100px;
          //   }
          //   .btn {
          //     line-height: 46px;
          //   }
        }
      }
    }
  }
  #modal-suggest {
    .modal-body {
      padding: 0px;
    }
    .input-container {
      background-color: #fff;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.31);
      padding: 20px 10px;
      .suggestion-input {
        border-bottom: 1px solid #a5a5a5;
        padding-bottom: 5px;
        height: 48px;
        label {
          display: none;
        }
        input {
          width: 100%;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          border: 0px;
          &::placeholder {
            color: #a5a5a5;
          }
        }
      }
    }
    .suggestion-box {
      left: 0;
      box-shadow: 0px 0px 0px 0px;
      width: 100%;
      padding: 0px;
      height: 100%;
      overflow-y: auto;
      .d-flex {
        flex-direction: column;
        .citys,
        .hotels {
          width: 100%;
          margin-right: 0;
          .title {
            margin: 0;
            background: #eeeeee;
            padding: 16px 10px;
          }
          .results {
            .result {
              margin: 0 10px;
              border-bottom: 1px solid #eeeeee;
              padding: 13px 8px;
            }
          }
        }
      }
      &:before {
        display: none;
      }
    }
  }
  &.overlay {
    padding-top: 0;
    .searcher__search-box {
      margin-top: 0;
      position: relative;
      top: -75px;
      z-index: 999;
      @include media-breakpoint-down(md) {
        top: 0;
        margin-top: 15px;
      }
    }
  }
}
.rangeHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  display: none;
  z-index: 99999;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.31);
  .header {
    border-bottom: 1px solid #e0e0e0;
    padding: 12px;
    .title {
      font-family: CircularStd;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
    }
    .close {
      i {
        font-size: 18px;
        color: #333333;
      }
    }
  }
  .dates {
    padding: 10px 0px;
    .date {
      width: 50%;
      padding: 0px 12px;
      &:first-child {
        border-right: 1px solid #e0e0e0;
      }
      .title {
        margin: 0px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333333;
      }
      .selected {
        margin: 0;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #333333;
      }
    }
  }
}
.openRangepicker {
  .rangeHeader {
    display: block;
  }
}
