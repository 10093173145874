.pmr-login {
  position: relative;
  margin-top: 100px;
  padding-bottom: 160px;
  .intro {
    p {
      font-size: to.rem(18px);
      line-height: to.rem(24px);
      @include media-breakpoint-down(sm) {
        font-size: to.rem(16px);
        line-height: to.rem(18px);
      }
    }
  }
  .formulario-container {
    margin-top: 30px;
    form {
      min-width: 330px;
      @include media-breakpoint-down(sm) {
        min-width: auto;
      }
      .mismalinea {
        display: flex;
        justify-content: space-between;
        .btn {
          margin-left: 10px;
        }
      }
      .error-msg {
        min-width: 100%;
        text-align: left;
        margin: 2px 0 0 12px;
      }
      input.password {
        max-width: 218px;
      }
    }
    .h3 {
      text-align: center;
      font-weight: 700;
      font-size: to.rem(18px);
      line-height: to.rem(20px);
      letter-spacing: to.rem(0.48px);
      color: #333333;
      text-transform: uppercase;
      padding: 0 0 16px 8px;
    }
  }
  .order-1 {
    background-color: #f4f5f7;
    @include media-breakpoint-down(lg) {
      padding: 40px 20px;
    }
  }
  .promobox {
    position: relative;
    padding: 10px 10px 15px;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.07);
    z-index: 2;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-right: 0;
    }
    &__titulo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 4px 0 8px;
    }
    &__titulo__texto {
      font-weight: 700;
      font-size: to.rem(18px);
      line-height: to.rem(23px);
      letter-spacing: to.rem(-0.27px);
      color: #999999;
      margin: 0 6px;
      text-align: left;
    }
    .image {
      position: relative;
      margin-bottom: 12px;
      display: flex;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.1);
      }
      img {
        min-width: 100%;
      }
      .titulo {
        position: absolute;
        width: 100%;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        text-align: center;
        font-weight: 800;
        font-size: to.rem(40px);
        line-height: to.rem(40px);
        letter-spacing: to.rem(-1px);
        color: $white;
        text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.35);
        z-index: 10;
        @include media-breakpoint-down(sm) {
          padding: 10px;
          font-size: to.rem(30px);
          line-height: to.rem(30px);
        }
        strong {
          font-weight: 800;
        }
      }
    }
    p {
      margin: 12px;
      padding: 0 0 4px;
      font-size: to.rem(16px);
      line-height: to.rem(26px);
      text-align: left;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
      strong {
        font-weight: 700;
      }
    }
  }
}
.footer.limpio {
  margin-top: auto;
}
