// _modo-drone.scss
.modo-drone {
  position: relative;
  height: 100vh;
  max-height: 100dvh;
  width: 100%;
  overflow: hidden;
  font-size: 0;
  cursor: move;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 350px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 5;
  }
  &::after {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    pointer-events: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: $animAll;
    will-change: opacity, visibility;
    z-index: 10;
  }
  .at-loading {
    position: absolute;
    z-index: -1;
  }
  &.paused {
    &::after {
      opacity: 0.4;
      visibility: visible;
    }
  }
  video {
    min-width: 100%;
    min-height: 100%;
  }
  .poi {
    font-size: 16px;
    position: absolute;
    max-width: 370px;
    z-index: 10;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 15;
    padding: 30px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    opacity: 0;
    visibility: hidden;
    text-decoration: none;
    transition: $animOpacity;
    .calcChildSize {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      height: 0;
      transition: $animHeight;
    }
    .h3,
    p,
    .btn {
      color: $white;
    }
    .h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      margin: 0;
      position: relative;
      min-width: 100%;
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 7px;
        background: $white;
        top: 9px;
        left: -48px;
      }
      &::after {
        left: -45px;
        width: 1px;
        top: 12px;
        height: 68px;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      padding: 16px 0 22px;
    }
    &.right {
      .calcChildSize {
        align-items: flex-end;
      }
      .h3 {
        text-align: right;
        &::before {
          left: inherit;
          right: -48px;
        }
        &::after {
          left: inherit;
          right: -45px;
        }
      }
      p {
        text-align: right;
      }
    }
    .btn {
      border-color: $white;
      color: $white;
      font-size: 0.75rem;
      padding: 4px 14px;
      border-radius: 6px;
      font-weight: 400;
      letter-spacing: 0.06rem;
      line-height: 1.25rem;
      outline: 0 !important;
      background-color: rgba(255, 255, 255, 0);
      transition: $animColor-y-Bg;
      text-transform: none;
      &:hover {
        background-color: rgba(255, 255, 255, 1);
        color: $dkGray;
      }
    }
    @include media-breakpoint-down(lg) {
      padding: 20px;
      position: fixed;
      top: 35%;
      left: 50%;
      width: 80vw;
      transform: translate(-50%, -50%) !important;
      h3 {
        text-align: center !important;
        &::before,
        &::after {
          display: none;
        }
      }
      p {
        text-align: center !important;
      }
      .calcChildSize {
        align-items: center !important;
      }
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
      .calcChildSize {
        height: var(--calc-height);
      }
    }
  }
  #video-move {
    width: 100%;
    cursor: move;
  }
  .player {
    width: auto;
    height: 100vh;
    position: relative;
    left: -50%;
    top: -50%;
    transform: translateY(-50%) scale(1.21);
    object-fit: cover;
  }
  .video-controlers {
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100px;
    pointer-events: none;
    .video-control {
      pointer-events: all;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      width: 46px;
      border-radius: 46px;
      border: 2px solid $white;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0);
      opacity: 0.35;
      transition: $animAll;
      position: relative;
      top: -9px;
      &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.7);
      }
      > span {
        font-size: 0;
      }
      svg {
        width: 24px;
        height: 24px;
        path {
          fill: $white;
        }
      }
      .pause-icon {
        display: none;
      }
      &.playing {
        .pause-icon {
          display: block;
        }
        .play-icon {
          display: none;
        }
      }
    }
    .video-control-scene {
      pointer-events: all;
      display: flex;
      position: absolute;
      left: 50%;
      bottom: -26px;
      transform: translateX(-50%);
      .video-control-prev,
      .video-control-next {
        display: flex;
        align-items: center;
        cursor: pointer;
        opacity: 0.35;
        transition: $animOpacity;
        &:hover {
          opacity: 1;
        }
        svg {
          width: 32px;
          height: 32px;
          path {
            fill: $white;
          }
        }
        &.disabled {
          opacity: 0.1 !important;
          cursor: not-allowed;
          background: none !important;
          width: auto;
          height: auto;
        }
      }
      &.sa {
        .mapa {
          .sa {
            opacity: 1;
            display: flex;
          }
          .punto {
            opacity: 1;
            left: 24px;
            top: 91px;
          }
        }
      }
      &.es {
        .mapa {
          .es {
            opacity: 1;
            display: flex;
          }
          .punto {
            opacity: 1;
            left: 50px;
            top: 78px;
          }
        }
      }
      &.an {
        .mapa {
          .an {
            opacity: 1;
            display: flex;
          }
          .punto {
            opacity: 1;
            left: 28px;
            top: 73px;
          }
        }
      }
      &.en {
        .mapa {
          .en {
            opacity: 1;
            display: flex;
          }
          .punto {
            opacity: 1;
            left: 72px;
            top: 62px;
          }
        }
      }
      &.la {
        .mapa {
          .la {
            opacity: 1;
            display: flex;
          }
          .punto {
            opacity: 1;
            left: 18px;
            top: 50px;
          }
        }
      }
      &.ca {
        .mapa {
          .ca {
            opacity: 1;
            display: flex;
          }
          .punto {
            opacity: 1;
            left: 68px;
            top: 36px;
          }
        }
      }
      &.or {
        .mapa {
          .or {
            opacity: 1;
            display: flex;
          }
          .punto {
            opacity: 1;
            left: 34px;
            top: 26px;
          }
        }
      }
    }
    .mapa {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 15px;
      max-width: 130px;
      position: relative;
      .punto {
        position: absolute;
        font-size: 0;
        background-color: $white;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        left: 28px;
        top: 73px;
        opacity: 0;
        transition: $animOpacity;
      }
      .parr {
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        color: rgba(255, 255, 255, 0.5);
        opacity: 0;
        display: none;
        transition: $animOpacity;
        min-width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 116px;
        text-align: center;
        justify-content: center;
        user-select: none;
      }
      svg {
        width: 120px;
        height: 120px;
        path {
          opacity: 0.5;
          &#parroquias {
            opacity: 0.2;
          }
        }
      }
    }
  }
  .mont {
    position: absolute;
    z-index: 19;
    width: 2100px;
    min-width: 100%;
    left: 0;
    right: 0;
    bottom: -1px;
    filter: drop-shadow(0 0 4px rgb(0 0 0 / 0.3));
    pointer-events: none;
  }
}
.permisowrap {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100vw;
  z-index: 15;
  background: rgba(0, 0, 0, 0.5);
}
