// intro-forms.scss
.intro-forms {
  min-height: 514px;
  align-items: flex-end;
  z-index: 11;
  .container {
    padding-bottom: 28px;
  }
  .intro-forms-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading {
      margin: 16px 0 10px;
      text-transform: uppercase;
      font-weight: 800;
      font-size: to.rem(20px);
      line-height: to.rem(24px);
      color: #a7a7a7;
      text-align: center;
    }
  }
}
