// _modal-general.scss
$basicLightbox__background: rgba(233, 233, 233, 0.97); // Background color

.basicLightbox {
  overflow: auto;
  .basicLightbox__placeholder {
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
  .modal-at-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .modal-at-inner {
    height: 100%;
    background: $white;
    pointer-events: all;
    margin: 20px;
    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 12px;
      cursor: pointer;
      pointer-events: all;
      svg {
        width: 37px;
        height: 37px;
        path {
          fill: #a7a7a7;
          transition: $animFill;
        }
      }
      &:hover {
        svg {
          path {
            fill: $dkGray;
          }
        }
      }
    }
    .error-msg {
      margin: 2px 0 0 12px;
      min-width: 100%;
      text-align: left;
    }
  }
  .is-modal-peque {
    .modal-at-inner {
      padding: 70px 56px 50px;
      border: 1px Solid #c9c9c9;
      max-width: 785px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0px rgb(0 0 0 / 20%);
      text-align: center;
      position: relative;
      @include media-breakpoint-down(sm) {
        padding: 50px 20px 20px;
      }
      #user_recupera_pass {
        margin: 0 auto 34px;
        max-width: 442px;
      }
      .parrafo {
        margin-bottom: 34px;
      }
      .mismalinea {
        display: flex;
        justify-content: center;
        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }
        .btn {
          margin-left: 10px;
          @include media-breakpoint-down(sm) {
            margin: 10px 0 0;
          }
        }
        .has-float-label {
          .form-input {
            font-size: to.rem(14px);
            line-height: to.rem(16px);
            padding-top: 24px;
          }
        }
      }
      .compartelista {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        li {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin: 8px;
          max-width: 52px;
          position: relative;
          span {
            text-align: center;
            font-size: to.rem(11px);
            line-height: to.rem(12px);
            color: #808080;
          }
          .aviso {
            border: 1px solid #c9c9c9;
            font-size: to.rem(12px);
            line-height: to.rem(12px);
            padding: 6px 10px;
            position: absolute;
            right: 3px;
            top: -33px;
            display: flex;
            white-space: nowrap;
            border-radius: 6px;
            color: $dkGray;
            opacity: 0;
            visibility: hidden;
            transition: $animOpacity;
            &::before {
              content: '';
              position: absolute;
              right: 18px;
              bottom: -6px;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 6px 5px 0 5px;
              border-color: #c9c9c9 transparent transparent transparent;
            }
          }
          &.clicked {
            .aviso {
              opacity: 1;
              visibility: visible;
            }
          }
        }
        .link {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1px solid #c9c9c9;
          border-radius: 10px;
          width: 49px;
          height: 49px;
          margin-bottom: 4px;
          cursor: pointer;
          transition: $animAll;
          &:hover {
            color: $at-primary;
            border-color: $at-primary;
            svg {
              path {
                fill: $at-primary;
              }
            }
          }
          svg {
            max-width: 29px;
            max-height: 29px;
            transition: $animColor;
            path {
              fill: $dkGray;
              transition: $animFill;
            }
          }
          &#tw-button,
          &#fb-button {
            svg {
              max-width: 46px;
              max-height: 46px;
            }
          }
          &.default {
            svg {
              max-width: 49px;
              max-height: 49px;
            }
          }
        }
      }
      .llistes {
        li {
          max-width: 295px;
          border-bottom: 1px solid #e9e9e9;
          &:last-child {
            border: none;
          }
        }
        a {
          display: block;
          padding: 11px 18px 10px;
          font-weight: 700;
          font-size: to.rem(16px);
          line-height: to.rem(20px);
          color: #808080;
          text-decoration: none;
          transition: $animColor-y-Bg;
          svg {
            max-width: 13px;
            position: relative;
            top: -1px;
            margin-right: 2px;
            path {
              fill: $at-primary;
              transition: $animFill;
            }
          }
          &:hover {
            color: $white;
            background: $at-primary;
            svg {
              path {
                fill: $white;
              }
            }
          }
        }
      }
    }
  }
  .is-modal-busqueda {
    align-items: flex-start;
    .modal-at-inner {
      background: none;
      width: 100%;
      pointer-events: none;
      > .container {
        padding-top: 80px;
        @include media-breakpoint-down(md) {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .container {
        position: relative;
        .results {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr;
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          font-size: to.rem(18px);
          line-height: to.rem(21px);
          letter-spacing: to.rem(-0.45px);
          color: $dkGray;
          margin: 26px 66px;
          a {
            color: $dkGray;
            text-decoration: none;
            &:hover {
              color: $black;
            }
          }
          .result {
            margin: 0 7px;
            border-bottom: 1px solid #c2cacd;
            a {
              display: flex;
              padding: 8px 0;
              &:hover {
                color: #398be7;
              }
            }
          }
          .col-9 {
            width: 100%;
          }
        }
        .viewAll {
          display: flex;
          justify-content: center;
          a {
            font-size: 1rem;
            font-weight: 800;
            letter-spacing: 0.06rem;
            white-space: nowrap;
            display: flex;
            align-items: center;
            padding: 10px 18px 11px;
            min-height: 50px;
            background-color: $white;
            color: $at-primary;
            border-radius: 6px;
            box-shadow: none !important;
            outline: 0 !important;
            padding: 14px 18px;
            text-transform: uppercase;
            border: 1px solid $at-primary;
            text-decoration: none;
            transition: $animAll;
            &:hover {
              color: $white;
              background-color: $at-primary;
              border-color: $white;
            }
          }
        }
        .container-inner {
          pointer-events: all;
          > .row {
            margin-left: auto;
            margin-right: auto;
            max-width: 1002px;
          }
        }
        .idt-h-main-search-form,
        .search-activity-form {
          position: relative;
          .error-msg {
            min-width: 100%;
            text-align: left;
            color: red;
            font-size: 12px;
          }
          button {
            position: absolute;
            top: 20px;
            right: 15px;
            border: none;
            background: none;
            outline: none;
            @include media-breakpoint-down(md) {
              top: 12px;
              right: 5px;
            }
            svg {
              width: 48px;
              height: 48px;
              @include media-breakpoint-down(md) {
                width: 30px;
                height: 30px;
              }
              path {
                fill: $dkGray;
              }
            }
          }
          @include media-breakpoint-down(md) {
            .pressenter {
              font-size: to.rem(14px);
              line-height: to.rem(18px);
            }
          }
        }
        .search-input,
        .search-activity {
          background: #ffffff;
          border: 5px solid #ced5d7;
          border-radius: 10px;
          box-sizing: border-box;
          width: 978px;
          max-width: 100%;
          padding: 0 78px 0 32px;
          height: 90px;
          font-size: to.rem(32px);
          line-height: to.rem(33px);
          letter-spacing: to.rem(-0.48px);
          color: #4d4d4d;
          @include media-breakpoint-down(md) {
            border: 3px solid #ced5d7;
            padding: 14px 42px 0 8px;
            height: 60px;
            font-size: to.rem(20px);
            line-height: to.rem(20px);
            letter-spacing: to.rem(-0.3px);
          }
        }
      }
    }
    &.is-modal-mapa {
      .container-inner {
        margin: 0 !important;
      }
    }
  }
  #is-modal-loginmobile {
    .modal-at-inner {
      display: flex;
      align-items: center;
      overflow: hidden;
      .parrafo {
        font-size: to.rem(14px);
        line-height: to.rem(22px);
        margin-bottom: 24px;
      }
    }
    .slidediv {
      transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
      min-width: 100%;
    }
    .slidediv-01 {
      transform: translateX(0);
      opacity: 1;
    }
    .slidediv-02 {
      transform: translateX(100vw);
      opacity: 0;
    }
    .forget {
      display: flex;
      justify-content: center;
      font-size: to.rem(14px);
      line-height: to.rem(16px);
      letter-spacing: to.rem(-0.18px);
      transition: $animColor;
      color: $at-primary;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $blue-dark;
      }
    }
    .titulo {
      color: $dkGray;
      font-size: 1rem;
      font-weight: 700;
      letter-spacing: 0.03rem;
      line-height: 1rem;
      padding: 0 0 16px 8px;
      text-transform: uppercase;
    }
    .lines {
      position: relative;
      width: 100%;
      margin-top: 24px;
      padding-top: 24px;
      &::after {
        content: '';
        position: absolute;
        background: #d7dcde;
        width: 100%;
        height: 1px;
        right: 0;
        left: 0;
        top: 0;
      }
    }
    &.show-next {
      .slidediv-01 {
        transform: translateX(-100vw);
        opacity: 0;
      }
      .slidediv-02 {
        transform: translateX(-100%);
        opacity: 1;
      }
    }
  }
  .pmr-modal {
    .modal-at-inner {
      padding-top: 90px;
      @include media-breakpoint-down(sm) {
        padding-top: 76px;
      }
      & > .container {
        padding: 0;
      }
    }
  }
}
