// _footer.scss
.footer {
  width: 100%;
  > .breadcrumb {
    background: #e9e9e9;
    padding: 23px 0;
    margin: 0;
    .desplega {
      position: relative;
      @include media-breakpoint-down(md) {
        min-width: 100%;
        margin-bottom: 4px;
      }
      &.active {
        .desplegar {
          display: flex;
        }
        .desplegador {
          color: $blue;
          .icon {
            background-color: $blue;
            svg {
              transform: rotate(180deg);
              path {
                fill: $white;
              }
            }
          }
        }
      }
    }
    .desplegador,
    .simple {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
      &::after {
        content: '/';
        position: absolute;
        font-weight: 400;
        top: 4px;
        right: -4px;
        text-decoration: none !important;
        pointer-events: none;
        cursor: default;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      .icon {
        width: 28px;
        height: 28px;
        border: 1px solid #c9c9c9;
        border-radius: 5px;
        background-color: transparent;
        transition: $animBgColor-y-Bc;
        margin: 0 1px 0 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          max-width: 15px;
          margin-top: 1px;
          transition: $animTransform;
          path {
            fill: #a7a7a7;
          }
        }
      }
    }
    .simple {
      &:hover {
        text-decoration: underline;
      }
    }
    .desplegar {
      flex-direction: column;
      align-items: flex-start;
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
      background: #f6f6f6;
      border: 1px solid #b3b3b3;
      box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.35);
      width: 340px;
      padding: 15px 0;
      @include media-breakpoint-down(xl) {
        width: 77vw;
        max-width: 320px;
        left: 50%;
        transform: translateX(-50%);
      }
      a {
        font-weight: 400;
        text-align: left;
        justify-content: flex-start;
        margin: 0;
        padding: 5px 24px 4px;
        background-color: transparent;
        min-width: 100%;
        &:hover {
          color: $blue;
          background-color: $white;
          text-decoration: none;
        }
      }
    }
    .texto,
    a,
    .desplegador {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      font-size: to.rem(14px);
      line-height: to.rem(21px);
      color: #4d4d4d;
      transition: $animColor;
      min-height: 28px;
      strong {
        font-weight: 700;
        margin-left: 4px;
      }
      &.fin {
        margin-left: 4px;
      }
    }
    a,
    .desplegador {
      font-weight: 700;
      text-decoration: none;
      margin: 0 4px;
      padding-right: 5px;
      position: relative;
      &:hover {
        text-decoration: underline;
        color: $black;
      }
    }
    .desplegador {
      &:hover {
        text-decoration: none;
        color: $blue;
        .icon {
          border-color: $blue;
          svg {
            path {
              fill: $blue;
            }
          }
        }
      }
    }
  }
  > .search {
    background: #ffffff;
    border: 1px Solid #b3b3b3;
    border-left: none;
    border-right: none;
    min-height: 95px;
    .footer-buscar {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: to.rem(19px);
      line-height: to.rem(21px);
      color: #666666;
      transition: $animColor;
      cursor: pointer;
      @include media-breakpoint-up(md) {
        font-size: to.rem(21px);
      }
      svg {
        width: 41px;
        height: 41px;
        margin-right: 10px;
        path {
          fill: #8d8d8d;
          transition: $animFill;
        }
      }
      span {
        max-width: 200px;
        @include media-breakpoint-up(sm) {
          max-width: inherit;
        }
      }
      &:hover {
        color: #333333;
        path {
          fill: $at-primary;
        }
      }
    }
  }
  > .image-wrap {
    background-color: #e9e9e9;
    background-image: url('/bundles/app/redesign/img/at-footer-bg-top.png');
    background-repeat: no-repeat;
    background-position: bottom 208px center;
    background-size: contain;
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 209px;
      background: transparent
        url('/bundles/app/redesign/img/at-footer-bg-bot.jpg') center bottom
        no-repeat;
      z-index: 2;
      @media (min-width: 1940px) {
        height: 100%;
        background-size: contain;
      }
    }
    > .container {
      position: relative;
      z-index: 5;
      @include media-breakpoint-down(md) {
        .row {
          margin: 0 -1.3rem;
        }
      }
    }
    .texto {
      padding: 20px 0 16px;
      font-weight: 700;
      font-size: to.rem(14px);
      line-height: to.rem(16px);
      color: #4d4d4d;
      text-align: center;
    }
    .links {
      @include media-breakpoint-down(md) {
        > div {
          margin: 0 -1.3rem;
          padding: 0;
          &:last-child {
            .titulo {
              border: none;
            }
          }
        }
      }
      ul {
        margin-bottom: 6px;
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
          padding-top: 40px;
        }
      }
      li {
        display: block;
        @include media-breakpoint-up(md) {
          margin-bottom: 4px;
        }
        a {
          display: block;
          font-weight: 400;
          font-size: to.rem(16px);
          line-height: to.rem(19px);
          padding: 6px 0;
          color: #4d4d4d;
          text-decoration: none;
          background-color: transparent;
          transition: $animColor-y-Bg;
          @include media-breakpoint-up(md) {
            &:hover {
              background-color: $white;
              color: $at-primary;
            }
          }
        }
        &.titulo {
          display: block;
          font-weight: 800;
          font-size: to.rem(21px);
          line-height: to.rem(25px);
          color: #333333;
          padding: 17px 0 5px;
          @include media-breakpoint-up(md) {
            border: none;
          }
          a {
            font-weight: 800;
            font-size: to.rem(21px);
            line-height: to.rem(25px);
            color: #333333;
            @include media-breakpoint-up(md) {
              &:hover {
                background-color: $at-primary;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
  .lineas {
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    @include media-breakpoint-down(md) {
      margin-top: 20px !important;
      margin: 0 -0.75rem;
      + .row {
        .col-md-4 {
          display: flex;
          justify-content: center !important;
        }
      }
    }
    &::before,
    &::after {
      content: '';
      position: relative;
      border-top: 1px solid rgba(0, 0, 0, 0.08);
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      width: 100%;
    }
  }
  .lineas.extern {
    position: relative;
    margin-top: 40px;
    margin-bottom: 0px;
    @include media-breakpoint-down(md) {
      margin-top: 20px !important;
      margin: 0 -0.75rem;
      + .row {
        .col-md-3 {
          display: flex;
          justify-content: center !important;
        }
      }
    }
    &::after {
      display: none;
    }

    .logo-web-extern-container {
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.10);
      display: flex;
      align-items: center;
      padding: 0.7em 1em;
      justify-content: center;
      height: 100%;
      transition: all .2s ease-in-out;
      overflow: hidden;
      position: relative;
      margin:0px 15px;
      .logo-web-extern {
        svg {
          path {
            fill:#ffffff;
            transition: fill .2s ease-in-out;
          }
        }
      }
      .logo-web-extern-color {
        position: absolute;
        top: 200px;
      }
      @include media-breakpoint-down(lg) {
        margin-bottom: 12px;
        margin-right: 8px; 
        margin-left: 8px; 
        height: auto;
        min-height: 140px;
        background: #fff;
        .logo-web-extern {
          position: absolute;
          top: -200px;
        }
        .logo-web-extern-color {
          position: relative;
          top: 0px;
        }
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 12px;
        margin-right: 70px;
        margin-left: 70px;
        height: auto;
        background: #fff;
        padding: 2em;
        .logo-web-extern {
          position: absolute;
          top: -200px;
        }
        .logo-web-extern-color {
          position: relative;
          top: 0px;
        }
      }
    }
    .logo-web-extern-container:hover {
      background: #ffffff;
      transition: all .2s ease-in-out;
      .logo-web-extern {
        position: absolute;
        top: -200px;
      }
      .logo-web-extern-color {
        position: relative;
        top: 0px;
      }

    }

  }
  .redes {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-down(xl) {
      ul {
        padding: 0 20px;
        li {
          display: flex;
          justify-content: center;
        }
      }
    }
    @include media-breakpoint-down(md) {
      ul {
        padding: 0;
        margin: 0;
      }
    }
    a {
      background: #ffffff;
      width: 49px;
      height: 49px;
      border-radius: 10px;
      margin: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      transition: $animBorderColor;
      margin-bottom: 28px;
      svg {
        width: 41px;
        height: 41px;
        path {
          fill: #333333;
          transition: $animFill;
        }
      }
      &:hover {
        border-color: $at-primary;
        svg {
          path {
            fill: $at-primary;
          }
        }
      }
    }
  }
  .andorraworldfan {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 36px;
    @include media-breakpoint-up(md) {
      margin: 0;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      min-height: 49px;
      border-radius: 10px;
      padding: 0 30px;
      border: 1px solid transparent;
      transition: $animBorderColor;
      svg {
        width: 240px;
        height: 27px;
      }
      &:hover {
        border-color: #f60046;
      }
    }
  }
  .logo {
    .logo-color {
      width: 140px;
      height: 29px;
      margin-top: 28px;
      @include media-breakpoint-up(md) {
        margin-top: 3px;
      }
      path {
        transition: $animFill;
      }
    }
    &:hover {
      .logo-color {
        path {
          fill: $white;
        }
      }
    }
  }
  .footer-bot {
    font-size: to.rem(14px);
    line-height: to.rem(18px);
    color: #333333;
    margin: 34px 0 25px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include media-breakpoint-up(xl) {
      font-size: to.rem(16px);
      line-height: to.rem(20px);
      margin: 0;
      min-height: 50px;
      align-items: center;
    }
    @include media-breakpoint-up(xxl) {
      flex-wrap: nowrap;
    }
    li {
      margin: 0 7px 2px;
      position: relative;
      &::after {
        content: '|';
        position: absolute;
        right: -9px;
        top: 0;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    a {
      text-decoration: none;
      color: #333333;
      transition: $animColor;
      white-space: nowrap;
      &:hover {
        color: $black;
        text-decoration: underline;
      }
    }
  }
  .config-cookies {
    display: flex;
    justify-content: center;
    margin: 14px 0 28px;
    @include media-breakpoint-up(xl) {
      margin: 14px 0;
    }
    #ot-sdk-btn {
      &.btn {
        padding: 4px 10px;
        font-size: to.rem(12px);
        color: #444;
        border-color: #444;
        text-transform: uppercase;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
  > .bandera {
    height: 7px;
    .col-4 {
      min-height: 7px;
    }
    .azul {
      background: #001b63;
    }
    .amarillo {
      background: #ffba00;
    }
    .rojo {
      background: #e70027;
    }
  }
}
