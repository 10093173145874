html,
body,
.tooltip,
.popover {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
code,
kbd,
pre,
samp,
.text-monospace {
  font-family: monospace;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}
h1,
.h1 {
  letter-spacing: to.rem(-0.74px);
}
h4,
.h4 {
  font-size: to.rem(21px);
  line-height: to.rem(25px);
  letter-spacing: to.rem(-0.38px);
}
