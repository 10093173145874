// paginacion.scss
.at-paginacion {
  position: relative;
  .pagination {
    font-weight: 700;
    font-size: to.rem(18px);
    line-height: to.rem(36px);
    color: $mdGray;
    padding: 20px 0 50px;
    margin: 0;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
    svg {
      height: 18px;
      color: $white;
    }
    a {
      color: $at-primary;
      &:hover {
        color: $dkGray;
      }
    }
    .page-link {
      padding: 0;
      margin: 0 2px;
      background: none;
      border: 0;
      &.arrow {
        background-color: #a4b0b0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 8px;
        transition: $animBgColor;
        @include media-breakpoint-down(sm) {
          width: 28px;
          height: 28px;
          margin: 0 2px;
        }
        &:hover {
          background-color: $at-primary;
        }
      }
    }
    .page-item {
      display: flex;
      align-items: center;
      padding: 0 6px;
      @include media-breakpoint-down(sm) {
        padding: 0 3px;
      }
      &.active {
        .page-link {
          color: $dkGray;
        }
      }
      &.disabled {
        .page-link {
          opacity: 0.3;
        }
      }
    }
  }
}
