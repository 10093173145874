:root {
  --swiper-navigation-size: 72px;
  --swiper-theme-color: #ffffff;
}
%slider-title-same {
  color: #ffffff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  @include media-breakpoint-up(md) {
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.3);
  }
}
%slider-h1 {
  font-weight: 800;
  font-size: to.rem(40px);
  line-height: to.rem(42px);
  letter-spacing: to.rem(-0.42px);
  @extend %slider-title-same;
  @include media-breakpoint-up(md) {
    font-size: to.rem(48px);
    line-height: to.rem(50px);
  }
  @include media-breakpoint-up(lg) {
    font-size: to.rem(54px);
    line-height: to.rem(56px);
  }
  @include media-breakpoint-up(xl) {
    font-size: to.rem(66px);
    line-height: to.rem(68px);
    letter-spacing: to.rem(-0.66px);
  }
}
%slider-h2 {
  font-weight: 700;
  font-size: to.rem(20px);
  line-height: to.rem(26px);
  letter-spacing: to.rem(-0.14px);
  @extend %slider-title-same;
  @include media-breakpoint-up(md) {
    font-size: to.rem(24px);
    line-height: to.rem(30px);
    letter-spacing: to.rem(-0.2px);
  }
  @include media-breakpoint-up(xl) {
    font-size: to.rem(28px);
    line-height: to.rem(34px);
    letter-spacing: to.rem(-0.28px);
  }
}
%slider-h1-big {
  font-weight: 400;
  font-size: to.rem(52px);
  line-height: to.rem(62px);
  letter-spacing: to.rem(-0.31px);
  text-transform: uppercase;
  @extend %slider-title-same;
  @include media-breakpoint-up(sm) {
    font-size: to.rem(80px);
    line-height: to.rem(60px);
    letter-spacing: to.rem(0.75px);
  }
  @include media-breakpoint-up(md) {
    font-size: to.rem(100px);
    line-height: to.rem(70px);
    letter-spacing: to.rem(1.75px);
  }
  @include media-breakpoint-up(lg) {
    font-size: to.rem(120px);
    line-height: to.rem(90px);
    letter-spacing: to.rem(2.75px);
  }
  @include media-breakpoint-up(xl) {
    font-size: to.rem(150px);
    line-height: to.rem(100px);
    letter-spacing: to.rem(3.75px);
  }
}
%slider-h2-big {
  font-weight: 700;
  font-size: to.rem(34px);
  line-height: to.rem(38px);
  letter-spacing: to.rem(-0.36px);
  @extend %slider-title-same;
  max-height: to.rem(114px);
  overflow: hidden;
  @include media-breakpoint-up(xl) {
    font-size: to.rem(36px);
    line-height: to.rem(48px);
    max-height: to.rem(96px);
  }
}
.slider-top {
  position: relative;
  background-color: #e9e9e9;
  height: 100vh;
  min-height: 500px;
  @include media-breakpoint-up(md) {
    height: inherit;
  }
  .container {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: hidden;
    box-sizing: border-box;
  }
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0.3;
    @include media-breakpoint-down(md) {
      visibility: hidden;
    }
  }
  .swiper-wrapper {
    .swiper-slide {
      overflow: hidden;
    }
    .container {
      z-index: 10;
      visibility: visible;
      &::before {
        content: '';
        position: absolute;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 33, 0) 0%,
          rgba(0, 0, 33, 0.45) 35%,
          rgba(0, 0, 33, 0.45) 65%,
          rgba(0, 0, 33, 0) 100%
        );
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 7;
        max-width: 1040px;
        margin: 0 auto;
      }
      &::after {
        position: absolute;
        background: rgba(0, 0, 33, 0.45);
        left: calc(-100% + 40px);
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 7;
        display: none;
        @include media-breakpoint-up(sm) {
          content: '';
          left: -100%;
        }
        @include media-breakpoint-up(md) {
          left: calc(-100% + 40px);
        }
      }
      .container-inner {
        min-height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 10;
        padding-bottom: 80px;
        @include media-breakpoint-up(md) {
          padding-bottom: 20px;
        }
      }
      .h1-heading {
        @extend %slider-h1;
        text-align: center;
        margin-bottom: 16px;
        max-width: 100%;
        @include media-breakpoint-up(md) {
          max-width: 600px;
        }
        > * {
          @extend %slider-h1;
          text-align: center;
        }
      }
      .h2-heading {
        @extend %slider-h2;
        text-align: center;
        margin: 0;
        max-width: 100%;
        @include media-breakpoint-up(md) {
          max-width: 600px;
        }
        > * {
          @extend %slider-h2;
          text-align: center;
        }
      }
      .btn {
        margin-top: 30px;
      }
      &.right {
        &::before {
          background: linear-gradient(
            270deg,
            rgba(0, 0, 33, 0.45) 0%,
            rgba(0, 0, 33, 0) 100%
          );
          max-width: 940px;
          width: 100%;
          right: 0;
          left: auto;
          @include media-breakpoint-up(md) {
            right: 40px;
          }
        }
        &::after {
          left: auto;
          @include media-breakpoint-up(sm) {
            display: block;
            right: -100%;
          }
          @include media-breakpoint-up(md) {
            right: calc(-100% + 40px);
          }
        }
        .container-inner {
          align-items: flex-end;
        }
        .h1-heading {
          text-align: right;
          > * {
            text-align: right;
          }
        }
        .h2-heading {
          text-align: right;
          > * {
            text-align: right;
          }
        }
      }
      &.left {
        &::before {
          background: linear-gradient(
            90deg,
            rgba(0, 0, 33, 0.45) 0%,
            rgba(0, 0, 33, 0) 100%
          );
          max-width: 940px;
          width: 100%;
          right: auto;
          left: 0;
          @include media-breakpoint-up(md) {
            left: 40px;
          }
        }
        &::after {
          @include media-breakpoint-up(sm) {
            display: block;
          }
        }
        .container-inner {
          align-items: flex-start;
        }
        .h1-heading {
          text-align: left;
          > * {
            text-align: left;
          }
        }
        .h2-heading {
          text-align: left;
          > * {
            text-align: left;
          }
        }
      }
      &.big {
        &::before {
          background: radial-gradient(
            ellipse farthest-side at center center,
            rgba(0, 20, 33, 0.3) 0%,
            rgba(0, 20, 33, 0) 60%
          );
          max-width: inherit;
        }
        .h1-heading {
          @extend %slider-h1-big;
          opacity: 0.75;
          max-width: inherit;
          @include media-breakpoint-up(md) {
            margin-bottom: 16px;
          }
          > * {
            @extend %slider-h1-big;
          }
        }
        .h2-heading {
          @extend %slider-h2-big;
          margin: 0;
          > * {
            @extend %slider-h2-big;
          }
        }
        .btn {
          margin-top: 50px;
          margin-bottom: -92px;
        }
      }
    }
  }
  .img-bg {
    position: relative;
    object-fit: cover;
    z-index: 5;
    min-height: 100%;
    min-width: 101%;
    max-width: 101%;
    max-height: 100vh;
    @include media-breakpoint-up(md) {
      max-height: 600px;
    }
    // @include media-breakpoint-up(xl) {
    //   max-height: 680px;
    // }
    @include media-breakpoint-up(lg) {
      max-height: inherit;
    }
    @include media-breakpoint-up(xxl) {
      max-height: 744px;
    }
  }
  .vid-bg {
    height: 100%;
    .at-video-bg {
      position: absolute;
      object-fit: cover;
      z-index: 5;
      min-height: 100%;
      min-width: 101%;
      max-width: 101%;
      max-height: 100vh;
      pointer-events: none;
      display: none;
      @include media-breakpoint-up(xl) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      @include media-breakpoint-up(xxl) {
        max-height: 744px;
      }
      lite-youtube {
        position: initial;
        contain: initial;
        @include media-breakpoint-up(xxl) {
          position: relative;
        }
      }
      iframe {
        width: 140%;
        left: -20%;
        @include media-breakpoint-up(xxl) {
          width: 100%;
          left: 0;
        }
      }
    }
    .img-bg {
      visibility: visible;
      @include media-breakpoint-up(xl) {
        visibility: hidden;
      }
    }
  }
  .mont {
    position: absolute;
    z-index: 50;
    width: 2100px;
    min-width: 100%;
    left: 0;
    right: 0;
    bottom: -1px;
    filter: drop-shadow(0 0 4px rgb(0 0 0 / 0.3));
    pointer-events: none;
  }
  .mouse-scroll {
    position: absolute;
    left: 50%;
    margin-left: -15px;
    bottom: 72px;
    z-index: 60;
    display: block;
    width: 26px;
    height: 44px;
    border: 2px solid #ffffff;
    border-radius: 50px;
    cursor: pointer;
    transform: translate(0, 0);
    overflow: hidden;
    font-size: 10000px;
    opacity: 0.8;
    transition: $animAll;
    &:hover {
      opacity: 1;
      filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.9));
    }
    &:after {
      content: '';
      width: 2px;
      height: 14px;
      position: absolute;
      left: 50%;
      top: 6px;
      border-radius: 2px;
      background-color: #ffffff;
      margin-left: -1px;
      opacity: 1;
      transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
      animation: 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s normal none
        infinite running scrollAnimation;
    }
    &::before {
      border-color: #ffffff;
      border-bottom-style: solid;
      border-bottom-width: 2px;
      border-right-style: solid;
      border-right-width: 2px;
      border-radius: 2px;
      content: '';
      display: block;
      position: absolute;
      height: 8px;
      width: 8px;
      left: 50%;
      margin-left: -4px;
      top: 12px;
      transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px)
        rotate(45deg);
      animation: 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s normal none
        infinite running scrollAnimationArrow;
    }
    @keyframes scrollAnimation {
      0%,
      20% {
        transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
      }
      10% {
        opacity: 1;
        transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
      }
      100% {
        opacity: 0.01;
        transform: translateY(16px) scaleY(2.5) scaleX(0.5) translateZ(0px);
      }
    }
    @keyframes scrollAnimationArrow {
      0%,
      20% {
        transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px)
          rotate(45deg);
      }
      10% {
        opacity: 1;
        transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px)
          rotate(45deg);
      }
      100% {
        opacity: 0.01;
        transform: translateY(16px) scaleY(2.5) scaleX(0.5) translateZ(0px)
          rotate(45deg);
      }
    }
  }
}
