// at-accordion.scss
body,
body main,
body .main-nav,
.filter-bar,
.header-nav-fixed,
.result-map #results {
  transition: all 0.3s 0.1s ease-in-out;
}
.avisalerta {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(to right, #ffb400 60%, #ff8000 100%);
  z-index: 1050;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.17);
  opacity: 1;
  transition: opacity 0.3s;
  .avisalerta-contenido {
    min-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    color: $white;
    min-height: 62px;
    padding: 12px 70px 12px 19px;
    justify-content: center;
    align-items: center;
  }
  .avisalerta-contenido p {
    margin: 0 0 1px 0;
    padding: 0 19px 0 0;
    font-weight: 600;
    font-size: to.rem(16px);
    line-height: to.rem(24px);
    letter-spacing: to.rem(-0.16px);
    color: $white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
  }
  .avisalerta-contenido p a {
    font-weight: 600;
    color: $white;
    text-decoration: underline;
    display: inline-block;
    transition: $animOpacity;
  }
  .avisalerta-contenido p a:hover {
    opacity: 0.7;
  }
  .btn {
    color: $dkGray;
    background-color: rgba(255, 255, 255, 0.5);
    transition: $animAll;
    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }
  .closebtn {
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -12px;
    right: 24px;
    opacity: 1;
    transition: all 0.3s ease;
  }
  .closebtn:hover {
    opacity: 0.3;
  }
  .closebtn:before,
  .closebtn:after {
    content: '';
    display: inline-block;
    height: 4px;
    width: 30px;
    top: 10px;
    left: -3px;
    background-color: $dkGray;
    border-radius: 2px;
    position: absolute;
  }
  .closebtn:before {
    transform: rotate(-45deg);
  }
  .closebtn:after {
    transform: rotate(45deg);
  }
}
@media (max-width: 991px) {
  .avisalerta .avisalerta-contenido {
    min-height: auto;
    padding: 10px 54px 10px 19px;
  }
  .avisalerta .avisalerta-contenido p {
    font-size: to.rem(14px);
    line-height: to.rem(20px);
  }
  .avisalerta .btn {
    font-size: to.rem(14px);
    padding: 10px 14px;
  }
  .avisalerta .closebtn {
    width: 18px;
    height: 18px;
    top: 50%;
    margin-top: -9px;
    right: 19px;
  }
  .avisalerta .closebtn:before,
  .avisalerta .closebtn:after {
    height: 3px;
    width: 24px;
    top: 7px;
    left: -3px;
    border-radius: 1.5px;
  }
}
@media (max-width: 575px) {
  .avisalerta .avisalerta-contenido {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
  }
  .avisalerta .avisalerta-contenido p {
    text-align: center;
    padding: 0 0 12px 0;
  }
  .avisalerta .closebtn {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 8px;
    margin-top: 0;
    right: 8px;
  }
  .avisalerta .closebtn:before,
  .avisalerta .closebtn:after {
    width: 18px;
    top: 5px;
    left: -2px;
    border-radius: 1px;
  }
}
