// intro-plan.scss
.intro-plan {
  display: flex;
  align-items: flex-end;
  min-height: 500px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(0, 20, 33, 0.75) 0.21%,
      rgba(0, 20, 33, 0) 100%
    );
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 310px;
    z-index: 6;
  }
  &::after {
    content: '';
    position: absolute;
    background: #e9e9e9;
    height: 42px;
    width: 100%;
    left: 0;
    bottom: -40px;
  }
  @include media-breakpoint-up(md) {
    min-height: 514px;
    &::after {
      display: none;
    }
  }
  .container {
    position: relative;
    z-index: 10;
    padding-top: 96px;
    @include media-breakpoint-down(xxl) {
      .container-inner {
        margin: 0;
      }
    }
  }
  .info {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    @include media-breakpoint-up(lg) {
      flex-direction: column;
      align-items: flex-start;
    }
    .title {
      @extend %intro-h1;
      margin-bottom: 54px;
      text-align: center;
      @include media-breakpoint-up(md) {
        margin-bottom: 72px;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
        text-align: left;
      }
      > * {
        @extend %intro-h1;
        margin-bottom: 0;
      }
    }
    .user {
      font-size: to.rem(11px);
      line-height: to.rem(14px);
      letter-spacing: to.rem(0.96px);
      font-weight: 700;
      display: flex;
      align-items: center;
      color: #b3b3b3;
      background-color: $black;
      padding: 3px 8px;
      text-transform: uppercase;
      @include media-breakpoint-up(sm) {
        margin-bottom: 6px;
      }
      @include media-breakpoint-up(md) {
        font-size: to.rem(14px);
        padding: 8px 15px;
      }
      @include media-breakpoint-up(xl) {
        font-size: to.rem(16px);
        line-height: to.rem(16px);
      }
      svg {
        position: relative;
        top: -1px;
        height: 11px;
        color: #8d8d8d;
        margin-right: 5px;
        @include media-breakpoint-up(lg) {
          height: 14px;
        }
        @include media-breakpoint-up(xl) {
          height: 16px;
        }
      }
    }
  }
  .nav-interno {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    @include media-breakpoint-up(lg) {
      justify-content: space-between;
      align-items: flex-start;
    }
    @include media-breakpoint-up(xl) {
      align-items: center;
      min-height: 68px;
    }
    .features {
      display: flex;
      margin-bottom: 10px;
      @include media-breakpoint-down(sm) {
        justify-content: center;
        align-items: center;
        min-width: 100vw;
        flex-wrap: wrap;
        background-color: #000;
        margin-bottom: 0;
        padding: 2px 0 7px;
        min-height: 65px;
      }
      .feature {
        display: flex;
        align-items: flex-end;
        font-size: to.rem(16px);
        line-height: to.rem(16px);
        letter-spacing: to.rem(0.24px);
        font-weight: 700;
        color: #b3b3b3;
        text-transform: uppercase;
        margin-right: 22px;
        @include media-breakpoint-only(sm) {
          align-items: center;
        }
        @include media-breakpoint-down(lg) {
          font-size: to.rem(12px);
          margin: 0 11px;
        }
        @include media-breakpoint-down(sm) {
          margin: 2px 11px;
        }
        .number {
          position: relative;
          bottom: -2px;
          font-size: to.rem(24px);
          line-height: to.rem(24px);
          letter-spacing: to.rem(-0.36px);
          color: $white;
          margin: 0 7px;
          @include media-breakpoint-only(sm) {
            bottom: 0;
          }
          @include media-breakpoint-down(lg) {
            font-size: to.rem(18px);
          }
        }
        svg {
          position: relative;
          top: -1px;
          height: 21px;
          @include media-breakpoint-down(lg) {
            height: 18px;
          }
        }
      }
    }
    .tools {
      display: flex;
      @include media-breakpoint-down(lg) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -82px;
      }
      @include media-breakpoint-down(md) {
        top: -70px;
      }
      @include media-breakpoint-down(sm) {
        top: -60px;
      }
      .imagen,
      .privacidad,
      .borrar,
      .descarga,
      .link,
      .comparte,
      .favorito {
        position: relative;
        margin: 0 0 0 22px;
        @include media-breakpoint-down(lg) {
          margin: 0 11px;
        }
        path {
          fill: $white;
          transition: $animFill;
        }
        &:hover {
          svg {
            path {
              fill: $at-primary;
            }
          }
        }
      }
      .comparte {
        border: none;
        background: none;
        padding: 0;
        svg {
          color: $white;
          filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.6));
          height: 21px;
          @include media-breakpoint-up(md) {
            height: 36px;
          }
        }
      }
      .imagen,
      .privacidad,
      .borrar,
      .descarga,
      .link,
      .favorito {
        height: auto;
        width: auto;
        right: 0;
        top: 0;
        background: none;
        cursor: pointer;
        svg {
          margin: 0;
          width: auto;
          height: 23px;
          @include media-breakpoint-up(md) {
            height: 36px;
          }
        }
      }
      .link {
        .aviso {
          background: $white;
          font-size: to.rem(14px);
          line-height: to.rem(14px);
          padding: 6px 10px;
          position: absolute;
          right: 50%;
          transform: translateX(50%);
          top: -33px;
          display: flex;
          white-space: nowrap;
          border-radius: 6px;
          color: $dkGray;
          font-weight: 700;
          opacity: 0;
          visibility: hidden;
          transition: $animOpacity;
          box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.3);
          @include media-breakpoint-up(md) {
            top: -35px;
          }
          @include media-breakpoint-up(lg) {
            right: 0;
            transform: none;
          }
          &::before {
            content: '';
            position: absolute;
            right: 50%;
            transform: translateX(50%);
            bottom: -6px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 5px 0 5px;
            border-color: $white transparent transparent transparent;
            @include media-breakpoint-up(lg) {
              right: 13px;
              transform: none;
            }
          }
        }
        &.clicked {
          .aviso {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
  .image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 7;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 33, 0.65) 0%,
        rgba(0, 0, 33, 0) 100%
      );
      width: 100%;
      height: calc(100% - 96px);
    }
    .img-bg {
      min-height: 100%;
      min-width: 100%;
      max-width: 100%;
      object-fit: cover;
      position: relative;
      z-index: 6;
    }
  }
  &.intro-plan-crear {
    .nav-interno {
      .tools {
        @include media-breakpoint-down(lg) {
          top: -60px;
        }
        @include media-breakpoint-down(md) {
          top: -54px;
        }
      }
    }
    .info {
      position: relative;
      border: 6px dashed rgba(255, 255, 255, 0.25);
      @include media-breakpoint-only(lg) {
        margin-bottom: 16px;
      }
      .title {
        padding: 16px 0 0 16px;
        @include media-breakpoint-down(lg) {
          padding: 16px 0 0 0;
        }
      }
      .edit {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(0, 0, 0, 0.3);
        width: 57px;
        height: 57px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        @include media-breakpoint-down(lg) {
          width: 34px;
          height: 34px;
          right: 2px;
          top: auto;
          transform: none;
          bottom: 2px;
        }
        svg {
          height: 32px;
          @include media-breakpoint-down(lg) {
            height: 18px;
          }
        }
        &:hover {
          background: rgba(0, 0, 0, 1);
          color: $at-primary;
        }
      }
    }
  }
}
