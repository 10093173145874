// at-mapa.scss
.at-mapa {
  position: relative;
  min-height: 582px;
  max-height: 100vh;
  &.full {
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.15) 100%
      );
      width: 100%;
      height: 48px;
      pointer-events: none;
      z-index: 1;
    }
    &::after {
      top: auto;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.15) 100%
      );
    }
  }
  #map,
  .at-map-container {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
  }
  .card-hotel-map {
    padding: 15px;
    z-index: 2;
    position: relative;
    box-shadow: none;
    top: 0;
    width: auto;
    max-width: 560px;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
    .image {
      display: none;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    .stars {
      svg {
        height: 12px;
      }
    }
    .heading {
      max-width: 250px;
      font-weight: 900;
      font-size: to.rem(21px);
      line-height: to.rem(24px);
      letter-spacing: to.rem(-0.38px);
      color: $dkGray;
      margin-bottom: 12px;
      text-align: center;
    }
    .price_container {
      display: flex;
      align-items: flex-end;
      font-size: to.rem(12px);
      line-height: to.rem(24px);
      letter-spacing: to.rem(-0.18px);
      color: $dkGray;
      margin: 6px 0;
      p {
        margin: 0;
      }
      .price {
        margin-left: 4px;
        span {
          font-weight: 800;
          font-size: to.rem(28px);
          letter-spacing: to.rem(-0.42px);
        }
      }
    }
    .btn {
      font-size: to.rem(12px);
      line-height: to.rem(19px);
      letter-spacing: to.rem(0.72px);
      padding: 10px 18px;
    }
    .favorito {
      position: relative;
      right: 0;
      top: 0;
      left: 6px;
      svg {
        filter: none;
        path {
          fill: $at-primary;
        }
      }
      &:hover {
        background: $at-primary;
        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }
  .at-map-tmp {
    z-index: 5;
  }
}
.mapboxgl-popup {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  will-change: transform;
  pointer-events: none;
  &.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    align-self: center;
    border-bottom: none;
    border-top-color: #fff;
  }
  .mapboxgl-popup-content {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    padding: 5px;
  }
  .mapboxgl-popup-close-button {
    background: none;
    margin: 0;
    padding: 4px 6px;
    z-index: 20;
    svg {
      height: 16px;
      width: 16px;
      path {
        fill: #a7a7a7;
      }
    }
    &:hover {
      svg {
        path {
          fill: $dkGray;
        }
      }
    }
  }
  .mapboxgl-popup-tip {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    z-index: 1;
    position: absolute;
    bottom: -10px;
    left: 50%;
  }
}
.mapboxgl-popup-anchor-bottom,
.mapboxgl-popup-anchor-bottom-left,
.mapboxgl-popup-anchor-bottom-right {
  flex-direction: inherit;
}
