// _busqueda-avanzada.scss
.result-map {
  &::before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(233, 233, 233, 0.97);
    z-index: 9000;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: $animOpacity;
  }
  .filter-bar {
    margin-top: 126px;
    background: $at-primary;
    height: 78px;
    padding: 0 12px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 11;
    @include media-breakpoint-down(xxl) {
      margin-top: 78px;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -7px;
      height: 7px;
      width: 100%;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 100%
      );
    }
  }
  .search-bar {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    z-index: 10000;
    background-color: $white;
    padding: 50px 20px 20px;
    border: 1px solid #c9c9c9;
    max-width: 785px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0px rgb(0 0 0 / 20%);
    text-align: center;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: $animOpacity;
    @include media-breakpoint-up(md) {
      padding: 70px 56px 50px;
    }
    #closeSearchBar {
      background: none;
      border: none;
      cursor: pointer;
      padding: 12px;
      pointer-events: all;
      position: absolute;
      right: 0;
      top: 0;
      svg {
        height: 37px;
        width: 37px;
        path {
          fill: #a7a7a7;
          transition: $animFill;
        }
      }
      &:hover {
        svg {
          path {
            fill: $dkGray;
          }
        }
      }
    }
    .title {
      font-size: calc(1.38125rem + 1.575vw);
      letter-spacing: -0.04625rem;
      color: $dkGray;
      font-weight: 800;
      line-height: 1.2;
      margin-bottom: 1.3rem;
      margin-top: 0;
      @include media-breakpoint-up(lg) {
        font-size: 2.5625rem;
      }
    }
    .btn {
      margin-left: 10px;
      background-color: $dkGray;
      color: $white;
      &:hover {
        background-color: $white;
        border-color: $dkGray;
        color: $dkGray;
      }
      @include media-breakpoint-down(md) {
        margin-top: 6px;
        margin-left: 0;
      }
    }
    @include media-breakpoint-down(md) {
      .form-group {
        flex-direction: column;
        label {
          max-width: calc(100% - 20px);
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          white-space: nowrap;
        }
      }
    }
  }
  &.show {
    &::before {
      opacity: 1;
      visibility: visible;
    }
    .search-bar {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }
  @include media-breakpoint-down(md) {
    &.result {
      #results {
        bottom: 0;
      }
      #idt-result-map {
        .showMbl {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
  .hidden {
    display: none !important;
  }
}
:root {
  --viewport-height: 100%;
}
.busquedaavanzada {
  height: var(--viewport-height);
  overflow: hidden;
}
#idt-result-map {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 156px);
  height: calc(var(--viewport-height) - 156px);
  top: 156px;
  @include media-breakpoint-up(md) {
    margin-left: 410px;
    width: calc(100vw - 410px);
  }
  @include media-breakpoint-up(lg) {
    margin-left: 480px;
    width: calc(100vw - 480px);
  }
  @include media-breakpoint-up(xxl) {
    margin-left: 610px;
    width: calc(100vw - 610px);
    height: calc(100vh - 204px);
    height: calc(var(--viewport-height) - 204px);
    top: 204px;
  }
}
.results-map {
  #results {
    position: absolute;
    background-color: $ltGray;
    left: 0;
    bottom: -100%;
    height: calc(100vh - 156px);
    height: calc(var(--viewport-height) - 156px);
    width: 100%;
    padding: 18px;
    z-index: 10;
    overflow-x: hidden;
    overflow-y: scroll;
    display: block;
    transition: $animAll;
    top: 156px;
    @include media-breakpoint-up(md) {
      bottom: 0;
      max-width: 410px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 480px;
    }
    @include media-breakpoint-up(xxl) {
      max-width: 610px;
      top: 204px;
      height: calc(100vh - 204px);
      height: calc(var(--viewport-height) - 204px);
    }
    > a {
      color: $dkGray;
      text-decoration: none;
      &:hover {
        .result {
          &::before {
            opacity: 1;
          }
          .icon {
            color: $dkGray;
          }
        }
      }
    }
    .result {
      background-color: $white;
      margin-bottom: 12px;
      font-size: to.rem(14px);
      line-height: to.rem(18px);
      position: relative;
      @include media-breakpoint-up(md) {
        padding: 14px;
      }
      &::before {
        content: '';
        position: absolute;
        left: -3px;
        top: -3px;
        border: 3px solid #4d4d4d;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        opacity: 0;
        transition: $animOpacity;
      }
      &:hover {
        &::before {
          opacity: 1;
        }
        .icon {
          color: $dkGray;
        }
      }
      .info-result {
        display: flex;
        flex-direction: column;
        a {
          position: relative;
          z-index: 1;
          text-decoration: none;
          &:hover {
            color: $blue-dark;
          }
        }
        p {
          margin-bottom: 6px;
        }
        .price_container {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          margin-top: auto;
          color: $dkGray;
          line-height: to.rem(24px);
          p {
            margin-bottom: 0;
            margin-top: 4px;
            font-size: to.rem(11px);
            letter-spacing: to.rem(-0.18px);
            font-weight: 400;
            @include media-breakpoint-up(md) {
              font-size: to.rem(12px);
            }
          }
          .from {
            font-size: to.rem(11px);
            letter-spacing: to.rem(-0.18px);
            font-weight: 400;
            margin-right: 4px;
            @include media-breakpoint-up(md) {
              font-size: to.rem(12px);
            }
          }
          .price {
            span {
              font-size: to.rem(18px);
              letter-spacing: to.rem(-0.42px);
              font-weight: 800;
              @include media-breakpoint-up(md) {
                font-size: to.rem(28px);
              }
            }
          }
        }
      }
      .hidden {
        display: none;
      }
      .planificador {
        left: calc(41.66666667% - 54px);
        right: inherit;
        top: 1px;
        @include media-breakpoint-up(md) {
          left: 104px;
          top: 15px;
        }
        @include media-breakpoint-up(lg) {
          left: 133px;
        }
        @include media-breakpoint-up(xxl) {
          left: 184px;
        }
      }
      .icon {
        margin-right: 2px;
        height: 14px;
        position: relative;
        top: -2px;
        left: 1px;
        color: #b3b3b3;
        transition: $animColor;
      }
      @include media-breakpoint-down(md) {
        .info-result {
          padding: 6px 16px 6px 0;
          .stars {
            display: none;
          }
        }
      }
      .image-result {
        .img-fluid {
          min-height: 100%;
          object-fit: cover;
        }
      }
      .title-result {
        display: flex;
        flex-direction: column;
        font-weight: 800;
        font-size: to.rem(21px);
        line-height: to.rem(24px);
        letter-spacing: to.rem(-0.38px);
        color: $dkGray;
        margin-bottom: 8px;
        @include media-breakpoint-down(md) {
          font-size: to.rem(18px);
          line-height: to.rem(20px);
          letter-spacing: to.rem(-0.32px);
        }
        .stars {
          margin-top: 4px;
        }
      }
    }
  }
  .showMbl {
    font-family: 'Montserrat', sans-serif;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #4d4d4d;
    align-items: center;
    font-weight: 700;
    font-size: to.rem(12px);
    line-height: to.rem(14px);
    color: $white;
    position: fixed;
    bottom: 0;
    padding: 8px 14px;
    left: 50%;
    transform: translateX(-50%);
    display: flex !important;
    z-index: 15;
    opacity: 1;
    visibility: visible;
    transition: $animOpacity;
    border: none;
    @include media-breakpoint-up(md) {
      display: none !important;
    }
    svg {
      height: 24px;
      margin-right: 7px;
    }
  }
}
.filter-bar {
  .info {
    margin: 6px;
    @include media-breakpoint-down(md) {
      flex: 2;
    }
    .searchResult {
      position: relative;
      display: flex;
      align-items: center;
      background-color: $white;
      border: 1px solid #d7dcde;
      border-radius: 6px;
      width: 100%;
      height: 50px;
      font-weight: 700;
      font-size: to.rem(18px);
      line-height: to.rem(20px);
      letter-spacing: to.rem(-0.27px);
      color: #4d4d4d;
      margin: 0;
      padding: 12px 34px 12px 12px;
      @include media-breakpoint-up(md) {
        width: 300px;
      }
      #text-value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      #openSearchBar {
        position: absolute;
        left: 0;
        top: 0;
        background: none;
        border: none;
        width: 100%;
        height: 100%;
        color: $dkGray;
        svg {
          height: 20px;
          position: absolute;
          top: 12px;
          right: 8px;
        }
      }
    }
  }
  .filters {
    .filter {
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid #e9e9e9;
      border-width: 1px;
      padding: 5px 32px 5px 12px;
      min-height: 50px;
      margin: 6px;
      width: 100%;
      box-sizing: border-box;
      border-radius: 6px;
      opacity: 1;
      transition: $animBgColor;
      @include media-breakpoint-up(lg) {
        width: 206px;
      }
      svg {
        width: 18px;
        color: $at-primary;
      }
      span {
        font-size: to.rem(16px);
        line-height: to.rem(18px);
        color: $dkGray;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .label {
        margin-bottom: 1px;
        font-weight: 700;
        font-size: to.rem(12px);
        letter-spacing: to.rem(0.96px);
        color: #b3b3b3;
      }
      .icon {
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -11px;
        svg {
          path {
            fill: $at-primary;
          }
        }
      }
    }
    .filters-dropdown {
      position: relative;
      display: none !important;
      @include media-breakpoint-up(xl) {
        display: block !important;
      }
      .dropdown-toggle {
        text-decoration: none;
        &::after {
          content: '';
          background: $at-primary;
          mask-image: url('/bundles/app/redesign/img/fa-icons/chevron-down-solid.svg');
          mask-size: cover;
          mask-repeat: no-repeat;
          mask-position: center;
          position: absolute;
          right: 12px;
          top: 16px;
          width: 18px;
          height: 18px;
          color: $at-primary;
        }
        .icon-abajo {
          display: none;
        }
      }
      .dropdown-menu {
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        padding: 20px;
        &::after {
          content: '';
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent #ffffff transparent;
        }
        .btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: to.rem(12px);
          line-height: to.rem(14px);
          font-weight: 700;
          margin: 6px;
          padding: 9px 18px;
          min-height: 50px;
          background-color: $white;
          color: $at-primary;
          border-color: $at-primary;
          &:hover {
            color: $white;
            background-color: $at-primary;
          }
        }
        .btn-container {
          margin-top: 8px;
          .col-12 {
            display: flex;
            justify-content: center;
          }
        }
        .radio {
          align-items: center;
          display: flex;
          margin-bottom: 6px;
          min-height: 20px;
          label {
            font-size: to.rem(16px);
            line-height: to.rem(19px);
            letter-spacing: to.rem(-0.4px);
            color: #4d4d4d;
            font-weight: 400;
            width: 100%;
            @include media-breakpoint-up(md) {
              width: 220px;
            }
          }
          input {
            &:checked + label {
              &,
              a {
                color: $at-primary !important;
              }
            }
          }
        }
      }
    }
    .clear-filters {
      @include media-breakpoint-down(xl) {
        visibility: hidden;
        pointer-events: none;
        display: none !important;
      }
      svg {
        height: 20px;
      }
      span {
        display: none !important;
      }
    }
    .more-filters {
      display: flex !important;
      span {
        max-width: 70px;
        @include media-breakpoint-down(xl) {
          &:not(.counter) {
            display: none !important;
          }
        }
      }
    }
    .clear-filters,
    .more-filters {
      background: none;
      border: none;
      flex-direction: row;
      align-items: center;
      margin: 6px;
      border-radius: 6px;
      font-size: to.rem(12px);
      line-height: to.rem(14px);
      font-weight: 700;
      padding: 9px 18px;
      min-height: 50px;
      background-color: $dkGray;
      border-color: $dkGray;
      color: $white;
      text-transform: uppercase;
      transition: $animColor-y-Bg;
      @include media-breakpoint-up(md) {
        display: flex !important;
      }
      svg {
        path {
          fill: $white;
          transition: $animFill;
        }
      }
      .icon-editar-filtros {
        position: relative;
        &::before {
          display: none;
        }
        svg {
          width: 28px;
        }
        .counter {
          position: absolute;
          display: block;
          text-align: center;
          font-size: to.rem(10px);
          line-height: to.rem(19px);
          font-weight: 400;
          font-style: normal;
          margin: 0;
          color: $white;
          padding: 0 0 0 1px;
          height: 18px;
          width: 18px;
          box-sizing: border-box;
          left: -8px;
          bottom: -3px;
          border-radius: 50%;
          background-color: $at-primary;
          transition: $animBgColor;
        }
      }
      span {
        margin-left: 9px;
        text-align: left;
      }
      &:hover {
        background-color: $white;
        color: $at-primary;
        svg {
          path {
            fill: $at-primary;
          }
        }
        .icon-editar-filtros {
          .counter {
            background-color: $dkGray;
          }
        }
      }
    }
  }
}
