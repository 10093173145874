// social-reviews.scss
.social-reviews {
  padding-bottom: 100px;
  margin-top: 40px;
  position: relative;
  .social-tabs {
    position: relative;
    overflow: hidden;
    max-height: 420px;
    margin-top: 40px;
    @include media-breakpoint-down(sm) {
      max-height: 340px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid rgba(17, 17, 17, 0.1);
      background-color: rgba(17, 17, 17, 0.05);
      width: 100%;
      border-radius: 8px;
      height: 44px;
    }
    &::after {
      pointer-events: none;
      content: '';
      position: absolute;
      display: block;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      height: 180px;
      z-index: 10;
      opacity: 1;
      transition: $animOpacity;
    }
    &.opened {
      max-height: 100%;
      &::after {
        opacity: 0;
      }
    }
  }
  .TabsContainer__Inner {
    overflow: hidden;
    position: relative;
    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 24px;
      height: 44px;
      border-radius: 8px;
      z-index: 10;
    }
    &::before {
      left: 0;
      background: linear-gradient(
        to left,
        rgba(243, 243, 243, 0) 0%,
        rgba(243, 243, 243, 1) 90%,
        rgba(243, 243, 243, 1) 100%
      );
    }
    &::after {
      right: 0;
      background: linear-gradient(
        to right,
        rgba(243, 243, 243, 0) 0%,
        rgba(243, 243, 243, 1) 90%,
        rgba(243, 243, 243, 1) 100%
      );
    }
    .TabsSlider__Container {
      position: relative;
      display: flex;
      height: 51px;
      overflow-x: auto;
      .TabsSlider__Inner {
        position: relative;
        height: 100%;
        display: flex;
        height: 45px;
        .TabsSlider__ColumnsSliderItem {
          * {
            user-select: none;
          }
          &:first-child {
            margin-left: 24px;
          }
          &:last-child {
            margin-right: 24px;
          }
          margin: 0px 14px;
          position: relative;
          display: inline-flex;
          align-items: center;
          height: 44px;
          line-height: 1;
          cursor: pointer;
          &::before {
            position: absolute;
            z-index: 1;
            display: block;
            inset: 6px -9px;
            border-radius: 16px;
            opacity: 0;
            transform: scale(0.8);
            background-color: rgb(17, 17, 17);
            transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
            content: '';
          }
          &:hover::before {
            opacity: 0.1;
            transform: scale(1);
          }
          .Tab__Container {
            pointer-events: none;
            .Tab__Inner {
              position: relative;
              display: flex;
              z-index: 2;
              align-items: center;
              .Icon__IconContainer {
                width: 24px;
                height: 24px;
                margin-right: 8px;
              }
              .TabTitle__Container {
                font-weight: 400;
                font-size: to.rem(14px);
                color: rgb(17, 17, 17);
                line-height: 1;
                white-space: nowrap;
              }
              .Rating__Container {
                margin-left: 8px;
                display: flex;
                align-items: center;
                .RatingValue__Container {
                  font-weight: 700;
                  line-height: normal;
                  font-size: to.rem(14px);
                  color: rgb(17, 17, 17);
                }
              }
            }
          }
          &.is-checked {
            .Tab__Container::after {
              position: absolute;
              display: block;
              right: 0px;
              bottom: 0px;
              left: 0px;
              height: 2px;
              transform: translate(0px, 0px) scaleY(1);
              transform-origin: 50% 100%;
              background-color: rgb(17, 17, 17);
              content: '';
            }
          }
        }
      }
    }
  }
  .Masonry__MasonryContainer {
    margin-top: 14px;
    transition: $animHeight;
    .ReviewContainer__Inner {
      display: flex;
      flex-direction: column;
      width: 264px;
      margin-bottom: 20px;
      @include media-breakpoint-down(xxl) {
        width: 258px;
      }
      @include media-breakpoint-down(xl) {
        width: 232px;
      }
      @include media-breakpoint-down(lg) {
        width: 288px;
      }
      @include media-breakpoint-down(md) {
        width: 248px;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
    .ReviewBackground__Container {
      position: relative;
      padding: 24px;
      transition: background-color 0.1s ease 0s;
      border-radius: 8px;
      box-sizing: border-box;
      flex-grow: 1;
      background-color: rgba(17, 17, 17, 0.05);
      transition: $animBgColor;
      &:hover {
        background-color: rgba(17, 17, 17, 0.1);
      }
    }
    .ReviewAlignContainer__Container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .ReviewLinkContainer__Container {
        display: flex;
        flex-wrap: nowrap;
        position: static;
        max-width: 100%;
        text-decoration: none;
        border: none;
        .ReviewAvatar__Container {
          flex: 0 0 auto;
          width: 44px;
          height: 44px;
          border-radius: 50%;
          overflow: hidden;
          .ReviewAvatar__Background {
            width: 100%;
            height: 100%;
            transition: opacity 1s ease-out 0s;
            background-color: rgb(210, 210, 210);
            border-radius: 50%;
            overflow: hidden;
            opacity: 1;
          }
        }
        .Card__Info {
          margin-left: 14px;
          display: flex;
          flex-direction: column;
          min-width: 0px;
          .ReviewAuthorName__Container {
            font-size: to.rem(14px);
            line-height: to.rem(16px);
            font-weight: 700;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            color: rgb(17, 17, 17);
          }
          .Card__InfoReview {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .Rating__Container {
              margin-top: 5px;
              display: flex;
              align-items: center;
              .RatingBar__Container {
                margin-right: 8px;
                display: inline-flex;
                flex-wrap: nowrap;
                align-items: center;
                .RatingItemFilledSvg__Container {
                  position: relative;
                  width: 18px;
                  height: 18px;
                  svg {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                  }
                  &.es-rating-stars-item-filled {
                    svg {
                      path {
                        fill: rgb(252, 191, 2) !important;
                      }
                    }
                  }
                  &.es-rating-stars-item-unfilled {
                    svg {
                      path {
                        fill: rgba(17, 17, 17, 0.2) !important;
                      }
                    }
                  }
                }
              }
            }
            .ReviewDate__Container {
              margin-top: 5px;
              overflow: hidden;
              min-width: 0px;
              font-weight: 400;
              font-size: to.rem(12px);
              line-height: 1.17;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: rgba(17, 17, 17, 0.5);
            }
          }
        }
        .ReviewPostedOn__IconStyle {
          margin-top: 12px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          .Icon__IconContainer {
            width: 34px;
            height: 34px;
            svg {
              display: block;
              width: inherit;
              height: inherit;
            }
          }
          .ReviewPostedOn__Info {
            margin-left: 8px;
            .ReviewPostedOn__Label {
              overflow: hidden;
              min-width: 0px;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: 400;
              font-size: to.rem(12px);
              line-height: 1.2;
              color: rgba(17, 17, 17, 0.5);
            }
            .ReviewPostedOn__Name {
              overflow: hidden;
              min-width: 0px;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: to.rem(14px);
              line-height: 1.2;
              color: rgb(25, 123, 255);
            }
          }
        }
      }
      .ReviewText__Container {
        margin-top: 8px;
        width: 100%;
        font-size: to.rem(15px);
        line-height: to.rem(20px);
        color: rgb(17, 17, 17);
        text-align: left;
        .SimpleShortener__Outer {
          position: relative;
          overflow: hidden;
          max-height: 60px;
          transition: $animAll;
          &:not(.closed) {
            max-height: var(--calc-height);
          }
        }
        .ReviewText__Control {
          display: inline-block;
          cursor: pointer;
          font-size: to.rem(14px);
          color: rgba(17, 17, 17, 0.5);
          margin-top: 4px;
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .vermas {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
