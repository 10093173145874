// at-accordion.scss
.at-accordion {
  padding-right: 22px;
  .ac {
    margin-top: 2px;
    border: none;
    .ac-trigger {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: to.rem(18px);
      line-height: to.rem(20px);
      letter-spacing: to.rem(-0.45px);
      color: #4d4d4d;
      padding: 14px 18px;
      background: #f3f3f3;
      span::first-letter {
        text-transform: uppercase;
      }
      svg {
        height: 21px;
        transform: rotate(0deg);
        transition: $animTransform;
        path {
          fill: #a7a7a7;
        }
      }
      &::after {
        display: none;
      }
    }
    .andorra-search-filter {
      padding: 4px 28px 10px;
      grid-column-gap: 0;
      grid-row-gap: 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      @include media-breakpoint-up(md) {
        grid-column-gap: 70px;
        grid-template-columns: 1fr 1fr;
      }
      @include media-breakpoint-up(lg) {
        grid-column-gap: 50px;
        grid-template-columns: 1fr 1fr 1fr;
      }
      .btn-container {
        display: none !important;
      }
    }
    &.is-active {
      .ac-trigger {
        color: $at-primary;
        background: none;
        svg {
          transform: rotate(90deg);
          path {
            fill: $at-primary;
          }
        }
      }
    }
  }
}
#myScrollElement {
  max-height: 402px;
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 6px;
  &::after {
    content: '';
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 40px;
    pointer-events: none;
  }
}
/* Switch */
.andorra-search-filter .checkbox,
.switch {
  --switch-height: 20px;
  --switch-width: 34px;
  --switch-border: 1px;
  --switch-color-checked: #398be7;
  --switch-color-unchecked: #f2f2f2;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  label {
    display: block;
    position: relative;
    cursor: pointer;
    padding-right: calc(var(--switch-width) + 4px) !important;
    padding-left: 0 !important;
    min-width: var(--switch-width);
    min-height: var(--switch-height);
    font-size: to.rem(16px);
    line-height: to.rem(19px);
    letter-spacing: to.rem(-0.4px);
    color: #4d4d4d;
    font-weight: 400;
    width: 100%;
    box-sizing: border-box;
    @include media-breakpoint-up(md) {
      width: 220px;
    }
    & > * {
      font-size: to.rem(16px);
      line-height: to.rem(19px);
      letter-spacing: to.rem(-0.4px);
      color: #4d4d4d;
      font-weight: 400;
      text-decoration: none;
    }
    a {
      pointer-events: none;
    }
    &::before,
    &::after {
      content: '' !important;
      position: absolute !important;
      font-size: inherit !important;
      opacity: 1 !important;
      top: 0 !important;
      right: 0 !important;
      left: auto !important;
      transition: 0.25s ease-in-out !important;
      box-sizing: border-box;
    }
    /* Switch Background */
    &::before {
      z-index: 1;
      background: var(--switch-color-unchecked) !important;
      width: var(--switch-width) !important;
      height: var(--switch-height) !important;
      border-radius: calc(var(--switch-height) * 0.5) !important;
      border: var(--switch-border) solid #e9e9e9 !important;
    }
    /* Dot */
    &::after {
      z-index: 2;
      background: $white !important;
      height: calc(
        var(--switch-height) - (var(--switch-border) * 2)
      ) !important;
      width: calc(var(--switch-height) - (var(--switch-border) * 2)) !important;
      transform: translate(-15px, var(--switch-border)) !important;
      border-radius: calc(var(--switch-height) / 2) !important;
      border: var(--switch-border) solid #c9c9c9 !important;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      mask-image: none !important;
    }
  }
  input {
    width: 0;
    height: 0;
    visibility: hidden;
    display: none;
    &:checked + label {
      &::before {
        transition: 0.5s;
        background: var(--switch-color-checked) !important;
      }
      &::after {
        transform: translate(-1px, var(--switch-border)) !important;
        border: var(--switch-border) solid #398be7 !important;
      }
      &,
      a {
        color: $at-primary !important;
      }
    }
  }
}
