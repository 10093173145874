// loader.scss
.at-loading {
  background: rgba(233, 233, 233, 0.97);
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.4s ease;
  width: 100%;
  will-change: opacity;
  z-index: 1100;
  .just-a-box {
    width: 140px;
    height: 140px;
  }
}
.in-progress {
  --pwd: var(--wave-diameter, 140px);
  --pwb: var(--wave-thickness, 6px);
  --pwt: var(--wave-duration, 1.7s);
  position: relative;
  top: 0;
  left: 0;
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    animation: var(--pwt) linear infinite progresswave;
  }
  &::after {
    animation-delay: calc(var(--pwt) / 2);
  }
}
@keyframes progresswave {
  from {
    border: var(--pwb) rgb(0, 44, 114, 0.8) solid;
    width: calc(var(--pwb) * 2);
    height: calc(var(--pwb) * 2);
    border-radius: calc(var(--pwb) * 2);
  }
  to {
    border: var(--pwb) rgba(0, 44, 114, 0) solid;
    width: var(--pwd);
    height: var(--pwd);
    border-radius: var(--pwd);
  }
}
