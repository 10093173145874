// filter-bar-seo.scss
.filter-bar-seo {
  .filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .filter {
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid #e9e9e9;
      border-width: 1px;
      padding: 5px 18px;
      min-height: 50px;
      margin: 6px;
      width: 100%;
      box-sizing: border-box;
      border-radius: 6px;
      opacity: 1;
      transition: $animBgColor;
      @include media-breakpoint-up(lg) {
        width: 328px;
        width: 280px;
      }
      svg {
        width: 18px;
        color: $at-primary;
      }
      span {
        font-size: to.rem(16px);
        line-height: to.rem(18px);
        color: $dkGray;
      }
      .label {
        margin-bottom: 1px;
        font-weight: 700;
        font-size: to.rem(12px);
        letter-spacing: to.rem(0.96px);
        color: #b3b3b3;
      }
      .icon {
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -11px;
        svg {
          path {
            fill: $at-primary;
          }
        }
      }
      //   &:hover {
      //     background-color: rgba(255, 255, 255, 0.8);
      //   }
    }
    .btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: to.rem(12px);
      line-height: to.rem(14px);
      font-weight: 700;
      margin: 6px;
      padding: 9px 18px;
      min-height: 50px;
      .icon-editar-filtros {
        position: relative;
        .counter {
          position: absolute;
          display: block;
          text-align: center;
          font-size: to.rem(10px);
          line-height: to.rem(19px);
          font-weight: 400;
          font-style: normal;
          margin: 0;
          color: $white;
          padding: 0 0 0 1px;
          height: 18px;
          width: 18px;
          box-sizing: border-box;
          left: -8px;
          bottom: -3px;
          border-radius: 50%;
          background-color: $at-primary;
          transition: $animBgColor;
        }
      }
      span {
        max-width: 70px;
        text-align: left;
        margin-left: 9px;
      }
      svg {
        width: 28px;
        transition: $animFill;
      }
      &.modal-filtro-hoteles,
      &.clear-filters {
        background-color: $dkGray;
        border-color: $dkGray;
        color: $white;
        &:hover {
          background-color: $white;
          color: $at-primary;
          svg {
            path {
              fill: $at-primary;
            }
          }
          .icon-editar-filtros {
            .counter {
              background-color: $dkGray;
            }
          }
        }
      }
      &.btn-primary {
        svg {
          width: 32px;
        }
      }
      &.clear-filters {
        svg {
          width: 15px;
        }
        span {
          position: relative;
          top: 2px;
        }
      }
    }
    .filters-dropdown {
      position: relative;
      .dropdown-toggle {
        text-decoration: none;
        &::after {
          content: '';
          background: $at-primary;
          mask-image: url('/bundles/app/redesign/img/fa-icons/chevron-down-solid.svg');
          mask-size: cover;
          mask-repeat: no-repeat;
          mask-position: center;
          position: absolute;
          right: 12px;
          top: 16px;
          width: 18px;
          height: 18px;
          color: $at-primary;
        }
      }
      .dropdown-menu {
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
        padding: 20px;
        &::after {
          content: '';
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent #ffffff transparent;
        }
      }
    }
  }
}
