.slider-info-full-wrap {
  position: relative;
  overflow: hidden;
  background: #e9e9e9;
  padding: 52px 0 40px;
  @include media-breakpoint-up(lg) {
    padding: 0;
  }
  > .container {
    position: relative;
    z-index: 10;
    @include media-breakpoint-up(lg) {
      max-width: inherit;
      padding: 0;
      margin: 0;
    }
    .container-inner {
      overflow: hidden;
      padding: 0 8px;
      @include media-breakpoint-up(md) {
        margin: 0 42px;
      }
      @include media-breakpoint-up(lg) {
        margin: 0;
        padding: 0;
        overflow: visible;
      }
    }
  }
}
.slider-info-full {
  overflow: visible;
  padding-bottom: 60px;
  @include media-breakpoint-up(lg) {
    max-height: 900px;
    padding-bottom: 0;
    .at-tit-txt-btn-img {
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
      padding: 0;
      &::before {
        content: '';
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.68) 54.76%,
          rgba(0, 0, 0, 0.7) 100%
        );
        width: 100%;
        height: 313px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
      }
      .text-left {
        position: absolute;
        z-index: 10;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        padding-bottom: 80px;
        margin: 0 auto;
        z-index: 10;
        align-items: center !important;
        text-align: center !important;
        max-width: 960px;
        @include media-breakpoint-up(xl) {
          max-width: 1140px;
        }
        @include media-breakpoint-up(xxl) {
          max-width: 1440px;
        }
        .btn {
          margin: 0 0 14px;
          order: 1;
          opacity: 0.5;
          transition: $animAll;
          &:hover {
            opacity: 1;
          }
        }
        .heading,
        .parrafo {
          color: $white !important;
          order: 2;
        }
        .heading {
          font-size: to.rem(42px);
          line-height: to.rem(52px);
          letter-spacing: to.rem(-0.76px);
          & > * {
            color: $white;
          }
          &:hover {
            & > * {
              color: $at-primary;
            }
          }
        }
        .parrafo {
          font-size: to.rem(18px);
          line-height: to.rem(24px);
          letter-spacing: to.rem(-0.32px);
        }
      }
      .image {
        a {
          cursor: default;
          pointer-events: none;
        }
      }
    }
  }
}
.nav-slider-info-full {
  @include media-breakpoint-up(lg) {
    max-width: 960px;
    margin: 0 auto;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1140px;
  }
  @include media-breakpoint-up(xxl) {
    max-width: 1440px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0.7;
    @include media-breakpoint-down(lg) {
      visibility: hidden;
    }
  }
  .swiper-pagination {
    visibility: visible;
    font-size: 0;
    @include media-breakpoint-up(lg) {
      bottom: 28px !important;
    }
    .swiper-pagination-bullet {
      border: 1px solid #a7a7a7;
      transition: $animAll;
      outline: none !important;
      box-shadow: none !important;
      @include media-breakpoint-up(lg) {
        opacity: 0.25;
      }
      &-active {
        background: #333333 !important;
        border: 1px solid #fff !important;
        cursor: default;
        @include media-breakpoint-up(lg) {
          opacity: 1;
        }
      }
      &:hover {
        background: $at-primary;
        border: 1px solid #fff;
        @include media-breakpoint-up(lg) {
          opacity: 1;
        }
      }
    }
  }
}
