// at-tabs-vert.scss
.at-tabs-vert {
  position: relative;
  margin-top: 40px;
  @include media-breakpoint-down(sm) {
    margin-top: 20px;
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .tabsvert {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
    .tabsvertlist {
      display: flex;
      flex-direction: column;
      left: 1.5rem;
      margin-top: -14px;
      min-width: auto;
      position: absolute;
      top: 100%;
      width: calc(100% - 3rem);
      background: white;
      z-index: 11;
      opacity: 0;
      visibility: hidden;
      transition: $animOpacity;
      @include media-breakpoint-up(sm) {
        width: 100%;
        left: 0;
      }
      @include media-breakpoint-up(lg) {
        position: relative;
        width: auto;
        top: auto;
        left: auto;
        margin: 0;
        opacity: 1;
        visibility: visible;
        background: transparent;
      }
      @include media-breakpoint-up(xxl) {
        min-width: 348px;
      }
      li {
        margin: 0;
        padding: 0;
        display: flex;
        position: relative;
        &::after,
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          background: $white;
          width: 100%;
          height: 1px;
        }
        &::before {
          bottom: -1px;
          background: #d7dcde;
        }
        &:last-child {
          &::after,
          &::before {
            display: none;
          }
        }
        a {
          width: 100%;
          font-weight: 700;
          font-size: to.rem(17px);
          line-height: to.rem(18px);
          letter-spacing: to.rem(-0.2px);
          color: #666666;
          text-decoration: none;
          box-sizing: border-box;
          padding: 10px 12px;
          @include media-breakpoint-up(xxl) {
            font-size: to.rem(21px);
            line-height: to.rem(22px);
            letter-spacing: to.rem(-0.31px);
            padding: 15px 20px;
          }
          &:hover {
            color: $at-primary;
          }
          &[aria-selected='true'] {
            background: #a7a7a7;
            color: $white;
          }
        }
      }
    }
    .tabsvertcontents {
      position: relative;
      flex: 2;
      .img-bg {
        max-width: 100%;
        min-width: 100%;
      }
      .update {
        position: absolute;
        top: 7px;
        left: 7px;
        width: 39px;
        height: 39px;
        z-index: 10;
        font-size: 0;
        background-color: transparent;
        border-radius: 40px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $animBgColor;
        cursor: pointer;
        svg {
          margin-top: 1px;
          width: 25px;
          height: 28px;
          filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.6));
          path {
            fill: #ffffff;
            transition: $animFill;
          }
        }
        &:hover {
          background-color: $white;
          svg {
            filter: none;
            path {
              fill: $at-primary;
            }
          }
        }
      }
    }
    .tabsvertlistwrap {
      position: relative;
      padding-left: var(--bs-gutter-x, 0.75rem);
      padding-right: var(--bs-gutter-x, 0.75rem);
      @include media-breakpoint-up(sm) {
        padding: 0;
      }
      #tab-tit-mobile {
        display: flex;
        align-items: center;
        background: $white;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        width: 100%;
        height: 52px;
        box-sizing: border-box;
        padding: 4px 24px 4px 14px;
        margin-bottom: 16px;
        cursor: pointer;
        span {
          font-weight: 700;
          font-size: to.rem(21px);
          line-height: to.rem(22px);
          letter-spacing: to.rem(-0.31px);
          color: $at-primary;
        }
        @include media-breakpoint-up(lg) {
          display: none;
        }
        svg {
          position: absolute;
          width: 18px;
          color: $at-primary;
          top: 18px;
          right: 34px;
          @include media-breakpoint-up(sm) {
            right: 14px;
          }
        }
      }
      &.show {
        .tabsvertlist {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
