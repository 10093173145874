.at-navbar-global {
  padding: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 20, 33, 0) 0%,
    rgba(0, 20, 33, 0.3) 100%
  );
  height: 128px;
  width: 100%;
  z-index: 20;
  align-items: stretch;
  position: absolute;
  display: flex;
  @include media-breakpoint-down(xxl) {
    height: 100px;
  }
  > .container {
    position: relative;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100px;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
  }
  @include media-breakpoint-up(xxl) {
    > .container {
      position: initial;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 128px;
      width: 100%;
      background: #ffffff;
      z-index: 10;
      opacity: 0;
      transition: $animOpacity;
    }
    &::after {
      height: 78px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.05) 0%,
        rgba(0, 0, 0, 0.6) 100%
      );
    }
    &:hover,
    &.hovered,
    &.hoverfixed,
    &.navbar-blanco {
      &::before {
        opacity: 0.75;
      }
      .header-page {
        .logo-wrap {
          .logo-color {
            path {
              &.azul {
                fill: #002c72;
              }
              &.amarillo {
                fill: #ffd000;
              }
              &.rojo {
                fill: #e1043a;
              }
              &.negro {
                fill: #000000;
              }
            }
          }
        }
        .modos {
          ul {
            li {
              .modos-btn {
                .text {
                  color: $dkGray;
                }
                svg {
                  path {
                    fill: $dkGray;
                  }
                }
              }
            }
          }
        }
        .nav {
          &::before {
            background: #ffffff;
          }
          .nav-link {
            color: $dkGray;
            &.current {
              &::before {
                background-color: $dkGray;
              }
            }
          }
        }
        .tools {
          .nav-clima {
            color: $dkGray;
          }
          .tool-item {
            svg {
              path {
                fill: $dkGray;
              }
            }
            &.current {
              &::before {
                background-color: $dkGray;
              }
            }
          }
        }
      }
      &.modo-lectura {
        &::before {
          opacity: 0;
        }
        .header-page {
          .logo-wrap {
            .logo-color {
              path {
                fill: $mdGray !important;
              }
            }
          }
          .modos {
            ul {
              li {
                .modos-btn {
                  .text {
                    color: $white;
                  }
                  svg {
                    path {
                      fill: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(xxl) {
    &.navbar-blanco {
      background: none;
      &::after {
        opacity: 1;
        background: $white;
        height: 78px;
      }
      .header-page {
        .logo-wrap {
          .logo-color {
            path {
              &.azul {
                fill: #002c72 !important;
              }
              &.amarillo {
                fill: #ffd000 !important;
              }
              &.rojo {
                fill: #e1043a !important;
              }
              &.negro {
                fill: #000000 !important;
              }
            }
          }
        }
      }
      .mobilebtn {
        svg {
          path {
            fill: #1a1a1a;
          }
        }
        span {
          color: #1a1a1a;
        }
      }
    }
  }
  .at-menu-mobile {
    display: flex;
    position: absolute;
    top: 0;
    right: 6px;
    z-index: 30;
    @include media-breakpoint-up(xxl) {
      display: none;
    }
  }
  .mobilebtn {
    position: relative;
    height: 74px;
    width: 46px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    @include media-breakpoint-up(xxl) {
      display: none;
    }
    svg {
      position: relative;
      top: 34px;
      width: 18px;
      height: 18px;
      pointer-events: none;
      path {
        fill: $white;
      }
    }
    span {
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 700;
      font-size: to.rem(10px);
      line-height: to.rem(10px);
      color: $white;
    }
    &.vista {
      svg {
        top: 28px;
        width: 30px;
        height: 30px;
      }
    }
    &.lectura-cerrar {
      display: none;
    }
  }
  .slide-menu {
    position: fixed;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    display: none;
    overflow: hidden;
    box-sizing: border-box;
    transform: translateX(100%);
    overflow-y: auto;
    z-index: 1000;
    background: #f2f2f2;
    &,
    .slide-menu__slider {
      transition: transform 0.3s ease-in-out;
      will-change: transform;
    }
    .slide-menu__slider {
      width: 100%;
      transform: translateX(0);
    }
    a {
      cursor: pointer;
      text-decoration: none;
    }
    .slide-menu__slider {
      background: $white;
      position: relative;
      z-index: 100;
    }
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      background: $white;
      z-index: 20;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -7px;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.07) 100%
        );
        width: 100%;
        height: 7px;
      }
      .logo {
        padding-left: 24px;
        svg {
          width: 130px;
          height: 28px;
        }
      }
      .cerrar {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        svg {
          width: 24px;
          height: 24px;
          pointer-events: none;
          path {
            fill: #a7a7a7;
          }
        }
      }
    }
    ul {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      background: $white;
      li {
        display: flex;
        border-bottom: 1px solid $ltGray;
      }
      ul {
        position: absolute;
        top: 0;
        left: 100%;
        display: none;
        li {
          &:first-child {
            border: none;
          }
        }
        ul {
          .slide-menu__backlink {
            font-size: 0;
            line-height: 0;
            .tit-prev {
              font-size: to.rem(12px);
              line-height: to.rem(14px);
            }
          }
        }
      }
      ul a {
        display: flex;
        align-items: center;
        @extend %btn-mobile;
      }
      &.primary-ul {
        background: $white;
      }
    }
    %btn-mobile {
      padding: 6px 0 6px 24px;
      min-height: 58px;
      font-weight: 700;
      font-size: to.rem(18px);
      line-height: to.rem(22px);
      color: $dkGray;
    }
    .main-btn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      @extend %btn-mobile;
      background: none;
      border: none;
      appearance: none;
      & + .chevron {
        span {
          display: none;
        }
      }
    }
    .chevron {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      width: 60px;
      cursor: pointer;
      svg {
        width: 12px;
        height: 19px;
        pointer-events: none;
        path {
          fill: $at-primary;
        }
      }
    }
    .slide-menu__backlink {
      align-items: center;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.15) 100%
      );
      min-height: 32px;
      width: 100%;
      font-weight: 700;
      font-size: to.rem(12px);
      line-height: to.rem(14px);
      letter-spacing: to.rem(0.18px);
      color: $white;
      text-transform: uppercase;
      padding: 0 !important;
      .arrow-left {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        width: 24px;
        cursor: pointer;
        svg {
          width: 12px;
          height: 19px;
          pointer-events: none;
          path {
            fill: $white;
          }
        }
      }
    }
    .titulo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 65px;
      text-align: center;
      font-weight: 800;
      font-size: to.rem(18px);
      line-height: to.rem(24px);
      color: $at-primary;
    }
    .slide-menu__submenu--active {
      .chevron {
        width: 100%;
        justify-content: space-between;
        @extend %btn-mobile;
        svg {
          min-width: 60px;
        }
      }
    }
    .search-wrap {
      padding: 27px 10px;
      width: 100%;
      box-sizing: border-box;
      opacity: 1;
      transition: $animOpacity;
      &.search-interno {
        opacity: 1 !important;
        display: block !important;
        background: #f2f2f2;
        border: none;
      }
      .mobile-search {
        position: relative;
      }
      input {
        background: $white;
        border: 1px Solid $ltGray;
        width: 100%;
        min-height: 47px;
        border-radius: 6px;
        padding: 0 50px 0 15px;
        font-size: to.rem(14px);
        line-height: to.rem(46px);
        color: $dkGray;
      }
      .form-btn {
        position: absolute;
        top: 0;
        right: 0;
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        padding: 0;
        background: none;
        border: none;
        font-size: 0;
        svg {
          position: relative;
          top: -1px;
          width: 21px;
          height: 21px;
          path {
            fill: $at-primary;
          }
        }
      }
    }
    display: flex !important;
    flex-direction: column;
    .tools-mobile {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      opacity: 1;
      transition: $animOpacity;
      .tool-item {
        display: flex;
        align-items: center;
        min-height: 46px;
        border-bottom: 1px solid $ltGray;
        padding: 6px 24px;
        &:last-of-type {
          border: none;
        }
        .tit {
          font-weight: 700;
          font-size: to.rem(14px);
          line-height: to.rem(16px);
          color: #666666;
        }
        svg {
          margin-left: auto;
          path {
            fill: $at-primary;
          }
        }
        .climadata {
          font-weight: 700;
          font-size: to.rem(23px);
          line-height: to.rem(23px);
          color: $at-primary;
          transition: $animColor;
          &.numero {
            font-weight: 800;
            margin-left: 2px;
          }
        }
      }
      .logged-menu {
        flex-direction: column;
        border-top: 1px solid $ltGray;
        .list {
          display: flex;
          flex-direction: column;
          font-weight: 700;
          font-size: to.rem(14px);
          line-height: to.rem(16px);
          color: #666666;
          a,
          span {
            padding: 12px 34px;
            color: #666666;
          }
        }
      }
      .select-wrap {
        display: flex;
        justify-content: center;
        margin-top: 34px;
        .select {
          background: $white;
          margin-bottom: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    &.no-first {
      .search-wrap,
      .tools-mobile {
        opacity: 0;
        display: none;
      }
    }
  }
  .header-planificador {
    width: 32px;
    height: 27px;
  }
  .header-clima {
    width: 30px;
    height: 26px;
  }
  .header-user {
    width: 29px;
    height: 29px;
  }
  .tools-mlectura {
    display: none;
  }
  &.active-modos {
    .modos {
      display: flex;
      top: 0;
      ul li.current .modos-btn {
        &::after {
          opacity: 1;
        }
      }
    }
  }
  &.modo-lectura {
    overflow: hidden;
    height: 118px;
    background: none;
    @include media-breakpoint-up(xxl) {
      height: 100px;
      overflow: inherit;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    &::after {
      display: none;
    }
    .header-page {
      @include media-breakpoint-up(xxl) {
        .modos {
          height: 100px;
          ul,
          ul li {
            align-items: flex-start;
            height: 100%;
            .modos-btn {
              height: 100%;
              justify-content: flex-start;
              color: $mdGray !important;
              .text {
                color: $mdGray !important;
              }
              svg {
                path {
                  fill: $mdGray !important;
                }
              }
              &:hover {
                color: $white !important;
                .text {
                  color: $white !important;
                }
                svg {
                  path {
                    fill: $white !important;
                  }
                }
              }
            }
            &.current {
              .modos-btn {
                background-color: $white !important;
                .text {
                  color: $blue-dark !important;
                }
                svg {
                  path {
                    fill: $blue-dark !important;
                  }
                }
              }
            }
          }
        }
      }
      .logo-wrap {
        .logo-color {
          path {
            fill: $mdGray !important;
          }
        }
      }
      .nav {
        display: none !important;
      }
      .tools {
        display: none !important;
      }
      .tools-mlectura {
        position: absolute;
        display: flex;
        flex-grow: 1;
        flex-basis: 0;
        justify-content: center;
        top: 78px;
        height: 40px;
        min-width: 100%;
        left: 0;
        background-color: #ececec;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 100vw;
          height: 100%;
          background-color: #ececec;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100%;
          background-color: #ececec;
        }
        @include media-breakpoint-up(xxl) {
          position: relative;
          top: 0;
          height: auto;
          background: none;
          min-width: auto;
          justify-content: flex-end;
          &::before,
          &::after {
            display: none;
          }
        }
        @media (min-width: 1440px) and (max-width: 1516px) {
          margin-right: 48px;
        }
        .tool-item {
          height: 100%;
          padding: 0 10px;
          margin-left: 1px;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;
          z-index: 20;
          &:hover {
            color: $blue-dark !important;
            svg {
              path {
                fill: $blue-dark !important;
              }
            }
          }
          &.cerrar {
            display: none;
            @include media-breakpoint-up(xxl) {
              display: flex;
              position: fixed;
              right: 12px;
              top: 36px;
              height: auto;
            }
          }
        }
        svg {
          width: 22px;
          height: 22px;
          @include media-breakpoint-up(xxl) {
            width: 28px;
            height: 28px;
          }
          path {
            transition: $animFill;
          }
        }
      }
    }
    .at-menu-mobile {
      .mobilebtn {
        svg {
          path {
            fill: $mdGray;
          }
        }
        span {
          color: $mdGray;
        }
        &.hamburguesa {
          display: none;
        }
        &.lectura-cerrar {
          display: flex;
          svg {
            top: 30px;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
.header-page {
  min-width: 100%;
  min-height: 100%;
  .modos-tools {
    height: 78px;
    justify-content: flex-start !important;
    @include media-breakpoint-up(xxl) {
      justify-content: center !important;
    }
  }
  .modos {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    justify-content: center;
    display: flex !important;
    align-items: flex-start;
    position: fixed;
    z-index: 20;
    left: 0;
    top: -78px;
    height: 78px;
    min-width: 100vw;
    background-color: #e9e9e9;
    transition: $animTop;
    z-index: 40;
    @include media-breakpoint-up(xxl) {
      justify-content: flex-start;
      top: 0;
      position: relative;
      min-height: 100%;
      min-width: inherit;
      background-color: transparent;
      z-index: 10;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        cursor: pointer;
        .modos-btn {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          padding: 10px 5px 12px;
          transition: $animBgColor;
          cursor: pointer;
          &::after {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8px 0 8px;
            border-color: #ffffff transparent transparent transparent;
            opacity: 0;
            transition: $animOpacity;
          }
          .text {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 4px 4px 0;
            font-weight: 800;
            font-size: to.rem(14px);
            line-height: to.rem(16px);
            font-size: 14px;
            line-height: 16px;
            color: $dkGray;
            text-transform: uppercase;
            transition: $animColor;
            @include media-breakpoint-up(sm) {
              padding: 4px 10px 0;
            }
            @include media-breakpoint-up(xxl) {
              color: $white;
            }
          }
          svg {
            width: 36px;
            height: 36px;
            path {
              transition: $animFill;
              fill: $dkGray;
              @include media-breakpoint-up(xxl) {
                fill: $white;
              }
            }
          }
          &:hover {
            background: $at-primary;
            .text {
              color: $white !important;
            }
            svg {
              path {
                fill: $white !important;
              }
            }
          }
        }
        &.current {
          .modos-btn {
            background-color: $white !important;
            &:hover {
              .text {
                color: $blue-dark !important;
              }
              svg {
                path {
                  fill: $blue-dark !important;
                }
              }
            }
          }
          .text {
            color: $blue-dark !important;
          }
          svg {
            path {
              fill: $blue-dark !important;
            }
          }
        }
        &.disabled {
          opacity: 0.35;
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }
    .cerrar-modos {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      position: relative;
      top: 16px;
      left: 4px;
      @include media-breakpoint-up(xxl) {
        display: none;
      }
      svg {
        width: 24px;
        height: 24px;
        pointer-events: none;
        path {
          fill: #333333;
        }
      }
    }
  }
  .logo-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 20;
    @include media-breakpoint-up(xxl) {
      justify-content: center;
    }
    .logo-color {
      margin-bottom: 8px;
      width: 160px;
      height: 40px;
      margin-left: 2px;
      margin-top: 10px;
      @include media-breakpoint-up(xxl) {
        margin: 0;
        width: 190px;
      }
      transform: $animFill;
      path {
        transform: $animFill;
        fill: $white;
      }
    }
  }
  .nav {
    display: none !important;
    @include media-breakpoint-up(xxl) {
      display: flex !important;
      align-items: stretch;
      height: 50px;
      &::before {
        content: '';
        position: absolute;
        background-color: rgba(0, 0, 0, 0.25);
        height: 50px;
        width: 100%;
        left: 0;
        bottom: 0;
        top: 78px;
        z-index: 10;
        transition: $animBgColor;
      }
    }
  }
  .tools {
    display: none !important;
    @include media-breakpoint-up(xxl) {
      display: flex !important;
      flex: 1;
      justify-content: flex-end;
      align-items: stretch;
      margin-right: 14px;
      .tool-item {
        height: 100%;
        padding: 0 10px;
        margin-left: 1px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        z-index: 20;
        &.current {
          &::before {
            content: '';
            position: absolute;
            background-color: $white;
            height: 5px;
            width: calc(100% - 1.25rem);
            left: 0.65rem;
            right: 0;
            bottom: 26px;
            transition: $animBgColor;
          }
        }
        &::after {
          content: '';
          position: absolute;
          background-color: #f1f1f1;
          height: 51px;
          width: 100%;
          left: 0;
          right: 0;
          bottom: -51px;
          opacity: 0;
          //   transition: $animOpacity;
        }
        &:hover {
          background-color: #f1f1f1;
          color: $blue-dark !important;
          &::after {
            opacity: 1;
          }
          svg {
            path {
              fill: $blue-dark !important;
            }
          }
          &.current {
            &::before {
              background-color: $blue-dark !important;
            }
          }
        }
      }
      svg {
        path {
          transition: $animFill;
        }
      }
      .nav-clima {
        align-items: center;
        font-weight: 700;
        font-size: to.rem(23px);
        line-height: to.rem(23px);
        color: $white;
        transition: $animColor;
        .numero {
          font-weight: 800;
          margin-left: 2px;
        }
        .megamenu {
          .btn {
            padding: 8px 18px;
            &.btn-outline-primary {
              padding: 8px 18px 8px 10px;
            }
          }
        }
      }
      .at-navbar-list {
        &:hover,
        &.hovered,
        &.hoverfixed {
          .tool-item {
            background-color: #f1f1f1;
            color: $blue-dark !important;
            &::after {
              opacity: 1;
            }
            svg {
              path {
                fill: $blue-dark !important;
              }
            }
            &.current {
              &::before {
                background-color: $blue-dark !important;
              }
            }
          }
        }
      }
      .nav-buscar {
        .tool-item {
          &::after {
            bottom: -50px;
            height: 50px;
          }
        }
      }
      .header-lupa {
        width: 21px;
        height: 21px;
        path {
          fill: #ffffff;
        }
      }
    }
  }
  .header-mobile {
    width: 30px;
    height: 32px;
  }
  .at-navbar-list {
    display: flex;
    .megamenu-wrap {
      min-height: 100vh;
      min-width: 100vw;
      overflow: hidden;
      position: absolute;
      top: 128px;
      right: 0;
      pointer-events: none;
      &.chico {
        right: -40px;
        width: 444px;
        padding: 0 40px;
      }
    }
    .megamenu {
      background: #f1f1f1 url('/bundles/app/redesign/img/at-megamenu-bg.png')
        center bottom no-repeat;
      background-size: contain;
      position: absolute;
      left: 0;
      right: 0;
      top: -515px;
      width: 100%;
      border-top: 1px solid #d7dcde;
      height: 480px;
      padding-top: 48px;
      box-sizing: border-box;
      transition: $animAll;
      transition-delay: 0.2s;
      z-index: 1;
      visibility: hidden;
      pointer-events: all;
      &::after {
        content: '';
        position: absolute;
        bottom: -35px;
        left: 0;
        right: 0;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.4) 100%
        );
        width: 100%;
        height: 35px;
      }
      .row {
        padding-left: 24px;
        padding-right: 24px;
      }
      .titulo {
        font-weight: 700;
        font-size: to.rem(16px);
        line-height: to.rem(16px);
        letter-spacing: to.rem(0.48px);
        color: #333333;
        text-transform: uppercase;
        padding: 0 0 16px 8px;
      }
      .btn {
        font-size: to.rem(18px);
        letter-spacing: to.rem(-0.36px);
        white-space: nowrap;
        display: flex;
        align-items: center;
        padding: 10px 18px 11px;
        text-transform: inherit;
        margin-right: 8px;
        min-height: 50px;
        svg {
          margin-right: 10px;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .clima-list {
        display: flex;
        flex-direction: row;
        margin-bottom: 38px;
      }
      .clima-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background: #ffffff;
        width: 160px;
        height: 202px;
        padding: 20px 0;
        box-sizing: border-box;
        border-radius: 8px;
        margin-right: 12px;
        .dia {
          font-weight: 700;
          font-size: to.rem(16px);
          line-height: to.rem(20px);
          color: #333333;
        }
        .icon {
          margin: 6px 0;
          svg {
            width: 80px;
            height: 80px;
            path {
              fill: #a7a7a7;
            }
          }
        }
        .minmax {
          font-weight: 700;
          font-size: to.rem(16px);
          line-height: to.rem(20px);
          letter-spacing: to.rem(-0.16px);
          color: $at-primary;
          margin-bottom: 6px;
          .max {
            color: #cb0000;
          }
          strong {
            font-weight: 800;
          }
        }
        .viento {
          font-weight: 700;
          font-size: to.rem(14px);
          line-height: to.rem(16px);
          letter-spacing: to.rem(-0.14px);
          color: #333333;
          strong {
            font-weight: 800;
          }
        }
      }
      .col {
        position: relative;
        &::before,
        &::after {
          content: '';
          position: absolute;
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
          );
          width: 1px;
          height: 110%;
          right: calc(var(--bs-gutter-x) * 0.5 - 1px);
          top: -8px;
          bottom: 0;
        }
        &::after {
          background: linear-gradient(
            0deg,
            rgba(215, 220, 222, 0) 0.21%,
            rgba(215, 220, 222, 1) 100%
          );
          right: calc(var(--bs-gutter-x) * 0.5 - 2px);
        }
        &:last-child {
          &::before,
          &::after {
            display: none;
          }
        }
        &.grande {
          min-width: 482px;
          .titulo {
            padding-left: 0;
          }
          .slider-icos-nav-wrap {
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 10px;
            margin: 12px 0 30px;
            padding: 12px 0;
          }
          .slider-icos-nav {
            min-height: auto;
            .swiper-wrapper {
              .container {
                z-index: 10;
                visibility: visible;
              }
              .swiper-slide {
                display: flex;
                justify-content: center;
              }
            }
            .propostes {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              text-decoration: none;
              background-color: transparent;
              transition: $animBgColor;
              border-radius: 10px;
              padding: 20px 5px 28px;
              min-width: 130px;
              box-sizing: border-box;
              svg {
                width: 80px;
                height: 80px;
                path {
                  fill: $at-primary;
                  transition: $animFill;
                }
              }
              .proposta {
                font-weight: 800;
                font-size: to.rem(18px);
                line-height: to.rem(30px);
                color: #333333;
                transition: $animColor;
                text-transform: lowercase;
                &::first-letter {
                  text-transform: capitalize;
                }
              }
              &:hover {
                background-color: $at-primary;
                .proposta {
                  color: $white;
                }
                svg {
                  path {
                    fill: $white;
                  }
                }
              }
            }
          }
          .nav-slider-icos-nav {
            .swiper-button-prev,
            .swiper-button-next {
              color: #c2cacd;
              left: -10px;
              &::before {
                width: 30px;
                height: 30px;
                left: 10px;
                top: 20px;
                border-radius: 30px;
              }
              &::after {
                font-size: 20px;
              }
            }
            .swiper-button-next {
              left: auto;
              right: -10px;
              &::before {
                left: auto;
                right: 10px;
              }
            }
          }
          .mapa {
            position: relative;
            .botones-wrap {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              .btn-outline-primary {
                background-color: $white;
                &:hover {
                  background-color: $at-primary;
                }
              }
              svg {
                width: 18px;
                height: 24px;
              }
            }
          }
        }
        &.masgrande {
          min-width: 798px;
          .titulo {
            padding-left: 0;
          }
          .slider-cuad-nav-wrap {
            margin: 12px 0 30px;
          }
          .slider-cuad-nav {
            min-height: auto;
            .img-bg {
              position: relative;
              z-index: 5;
              max-width: 100%;
              min-width: 100%;
              object-fit: cover;
            }
            .cont {
              position: relative;
              position: relative;
              height: 100%;
              display: flex;
              z-index: 5;
              &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(
                  0deg,
                  rgba(0, 0, 0, 0.97) 0%,
                  rgba(0, 0, 0, 0) 100%
                );
                opacity: 0.76;
                width: 100%;
                height: 102px;
                z-index: 10;
                transition: $animAll;
              }
              .text-center {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                z-index: 15;
                text-decoration: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-height: 70px;
                padding: 0 22px;
                box-sizing: border-box;
                max-height: 100%;
                overflow: hidden;
                .cat {
                  margin: 12px 0 4px;
                }
                .heading {
                  font-weight: 800;
                  font-size: to.rem(24px);
                  line-height: to.rem(30px);
                  letter-spacing: to.rem(-0.36px);
                  color: #ffffff;
                  max-height: to.rem(60px);
                  overflow: hidden;
                }
                .calcChildSize {
                  overflow: hidden;
                  height: 0;
                  transition: $animHeight;
                }
                .parrafo {
                  font-size: to.rem(15px);
                  line-height: to.rem(20px);
                  max-height: to.rem(132px);
                  overflow: hidden;
                  color: #ffffff;
                  text-shadow: 1px 1px 0.55px rgba(0, 0, 33, 0.4);
                  margin: 0;
                  padding: 10px 0 18px;
                }
              }
              @include media-breakpoint-up(lg) {
                &:hover {
                  &::before {
                    opacity: 1;
                    height: 100%;
                  }
                  .cat {
                    color: $black;
                    background-color: $white;
                  }
                  .calcChildSize {
                    height: var(--calc-height);
                  }
                }
              }
            }
            .swiper-button-next,
            .swiper-button-prev {
              color: #a7a7a7;
              transform: scale(0.4386);
              left: 0;
              &::before {
                opacity: 0.35;
              }
            }
            .swiper-button-next {
              left: auto;
              right: 0;
            }
          }
        }
        &.muygrande {
          min-width: 1084px;
          .titulo {
            padding-left: 0;
          }
          .proximos-eventos {
            display: flex;
            .item-evento-nav {
              display: flex;
              flex-direction: column;
              text-decoration: none;
              margin-right: 12px;
              &:last-child {
                margin-right: 0;
              }
              .datos {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                background: linear-gradient(
                  180deg,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 0) 100%
                );
                min-height: 110px;
                padding-bottom: 14px;
                transition: $animBgColor;
              }
              .heading {
                font-weight: 800;
                font-size: to.rem(16px);
                line-height: to.rem(19px);
                letter-spacing: to.rem(-0.32px);
                color: #333333;
                margin: 18px 0 14px;
                transition: $animColor;
              }
              .fechas,
              .lugar {
                font-size: to.rem(12px);
                line-height: to.rem(13px);
                color: #333333;
                margin-bottom: 9px;
                svg {
                  height: 12px;
                  margin-right: 6px;
                  path {
                    fill: #b3b3b3;
                    transition: $animFill;
                  }
                }
              }
              &:hover {
                .datos {
                  background: $white;
                }
                .heading {
                  color: $at-primary;
                }
                svg {
                  path {
                    fill: $at-primary;
                  }
                }
              }
            }
          }
        }
        .social {
          margin-top: 26px;
          a {
            background: #ffffff;
            width: 38px;
            height: 38px;
            border-radius: 8px;
            margin: 0 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid transparent;
            transition: $animBorderColor;
            svg {
              width: 34px;
              height: 34px;
              path {
                fill: #333333;
                transition: $animFill;
              }
            }
            &:hover {
              border-color: $at-primary;
              background: #ffffff;
              svg {
                path {
                  fill: $at-primary;
                }
              }
            }
          }
        }
      }
      .list-unstyled {
        a,
        .link {
          display: block;
          padding: 11px 18px 10px;
          font-weight: 700;
          font-size: to.rem(16px);
          line-height: to.rem(20px);
          color: #808080;
          text-decoration: none;
          transition: $animColor-y-Bg;
          cursor: pointer;
          &:hover {
            color: $white;
            background: $at-primary;
          }
        }
      }
      &.chico {
        min-width: 290px;
        left: auto;
        width: auto;
        right: 40px;
        height: 340px;
        box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 0.3);
        &::after {
          display: none;
        }
        .andorraworldfan {
          width: 286px;
          height: 32px;
          margin-bottom: 32px;
        }
        &.login {
          min-width: 444px;
          height: 480px;
          box-sizing: border-box;
        }
      }
      .user-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        form {
          min-width: 330px;
        }
        .form-group {
          display: flex;
          flex-direction: column;
          .form-input {
            font-size: to.rem(14px);
            line-height: to.rem(16px);
            padding-top: 24px;
          }
        }
        .mismalinea {
          display: flex;
        }
        .error-msg {
          min-width: 100%;
          text-align: left;
          margin: 2px 0 0 12px;
        }
        input.password {
          max-width: 218px;
        }
        .btn.btn-secondary {
          font-size: to.rem(16px);
        }
        .forget {
          display: flex;
          font-size: to.rem(12px);
          line-height: to.rem(16px);
          letter-spacing: to.rem(-0.18px);
          transition: $animColor;
          color: $at-primary;
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            color: $blue-dark;
          }
        }
        .lines {
          position: relative;
          width: 100%;
          margin-top: 24px;
          padding-top: 24px;
          &::before,
          &::after {
            content: '';
            position: absolute;
            background: $white;
            width: 100%;
            height: 1px;
            right: 0;
            left: 0;
            top: 0;
          }
          &::after {
            background: #d7dcde;
            top: -1px;
          }
        }
      }
    }
    .nav-link {
      display: flex;
      align-items: center;
      text-align: center;
      color: $white;
      font-weight: 700;
      font-size: to.rem(18px);
      line-height: to.rem(22px);
      padding: 0 0.72rem;
      background-color: transparent;
      transition: $animColor-y-Bg;
      position: relative;
      border: 1px solid transparent;
      border-bottom: none;
      z-index: 20;
      &.current {
        &::before {
          content: '';
          position: absolute;
          background-color: $white;
          height: 4px;
          width: calc(100% - 1.44rem);
          left: 0.72rem;
          right: 0;
          bottom: 0;
          transition: $animBgColor;
          z-index: 10;
        }
      }
      &::after {
        content: '';
        position: absolute;
        background-color: #f1f1f1;
        height: 2px;
        width: 100%;
        left: 0;
        right: 0;
        bottom: -1px;
        opacity: 0;
        transition: $animOpacity;
        z-index: 5;
      }
      &:hover {
        background-color: #f1f1f1;
        color: $blue-dark !important;
        &::after {
          opacity: 1;
        }
        &.current {
          &::before {
            background-color: $blue-dark !important;
          }
        }
      }
    }
    &.nav-planificador,
    &.nav-user {
      position: relative;
    }
    &:hover,
    &.hovered,
    &.hoverfixed {
      .nav-link {
        background-color: #f1f1f1;
        color: $blue-dark !important;
        border-color: #d7dcde;
        &.current {
          &::before {
            background-color: $blue-dark !important;
          }
        }
        &::after {
          opacity: 1;
        }
      }
      .tool-item {
        &.current {
          &::before {
            background-color: $blue-dark !important;
          }
        }
      }
      .megamenu {
        top: 0;
        visibility: visible;
      }
    }
  }
  .slider-webcams {
    width: 100%;
    min-height: auto;
    .link {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    .img-fluid {
      position: relative;
      object-fit: cover;
      z-index: 5;
      min-height: 100%;
      min-width: 101%;
      max-width: 101%;
    }
    .heading {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 70, 148, 0.6);
      min-height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 800;
      font-size: to.rem(14px);
      line-height: to.rem(16px);
      letter-spacing: to.rem(-0.14px);
      color: #ffffff;
      z-index: 10;
    }
  }
  .nav-slider-webcams {
    .swiper-button-next,
    .swiper-button-prev {
      transform: scale(0.3);
      top: auto;
      bottom: -8px;
      left: 2px;
      opacity: 0.4;
    }
    .swiper-button-next {
      left: auto;
      right: 2px;
    }
  }
}
.ScrollToTop {
  position: fixed;
  cursor: pointer;
  bottom: -100px;
  right: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 100%;
  margin: 25px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 16;
  overflow: hidden;
  transition: $animAll;
  visibility: visible;
  opacity: 1;
  transform: none;
}
.page-is-scrolled {
  .ScrollToTop {
    bottom: 0;
  }
  @include media-breakpoint-down(xxl) {
    &.header-not-at-top {
      .ScrollToTop {
        bottom: 50px;
      }
    }
  }
}
@media print {
  .ScrollToTop {
    display: none;
  }
}
.ScrollToTop:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  border-radius: inherit;
  transition: visibility 0.2s, opacity 0.2s, transform 0.2s;
}
.ScrollToTop:hover {
  transform: scale(1.05);
}
.ScrollToTop:hover:before {
  visibility: visible;
  opacity: 1;
}
.ScrollToTop:hover .ScrollToTop--icon {
  animation: ScrollToTop--arrow 1.2s ease-in-out infinite;
}
.ScrollToTop:active {
  transform: scale(0.9);
  transition: transform 0.1s ease-in-out;
}
.ScrollToTop--inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  .hidden {
    display: none;
  }
}
.ScrollToTop--icon {
  height: 22px;
  position: relative;
  top: -3px;
  color: $at-primary;
}
@-webkit-keyframes ScrollToTop--arrow {
  0% {
    transform: none;
  }
  50% {
    transform: translateY(-60px);
  }
  50.0001% {
    transform: translateY(60px);
  }
  to {
    transform: none;
  }
}
@keyframes ScrollToTop--arrow {
  0% {
    transform: none;
  }
  50% {
    transform: translateY(-60px);
  }
  50.0001% {
    transform: translateY(60px);
  }
  to {
    transform: none;
  }
}
#header-site-pixel-anchor {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 0;
  top: 900px;
  left: 0;
}

.at-navbar-global .slide-menu .tools-mobile .select-wrap {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.at-navbar-global .slide-menu .tools-mobile {
  margin-top: 10%;
}
