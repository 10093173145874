// area-privada.scss

.private-area-page {
  overflow: hidden;
  .jumbotron-private {
    height: 180px;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      height: 240px;
    }
    picture {
      display: flex;
      align-items: center;
      max-height: 100%;
      position: absolute;
      overflow: hidden;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 5;
      .img-fluid {
        max-width: 100%;
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
        object-position: center right;
        position: relative;
        z-index: 6;
      }
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      min-height: 100%;
      &::after {
        opacity: 0.6;
        background-image: linear-gradient(
          to right,
          rgba(0, 17, 99, 0) 0,
          #001163 30%,
          #001163 70%,
          rgba(0, 17, 99, 0) 100%
        );
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        height: 100%;
        pointer-events: none;
        z-index: 5;
      }
    }
    .caption {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 20px;
      z-index: 7;
      margin-bottom: 10px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }
      .title-main {
        font-weight: 700;
        text-align: center;
        color: $white;
        padding: 0;
        margin: 0;
        width: 100%;
        font-size: to.rem(20px);
        line-height: to.rem(26px);
        letter-spacing: to.rem(-0.14px);
        @include media-breakpoint-up(md) {
          font-size: to.rem(24px);
          line-height: to.rem(30px);
          letter-spacing: to.rem(-0.2px);
        }
        @include media-breakpoint-up(xl) {
          font-size: to.rem(28px);
          line-height: to.rem(34px);
          letter-spacing: to.rem(-0.28px);
        }
      }
    }
    &.jumbotron-thanks {
      height: auto;
      @include media-breakpoint-up(md) {
        height: 282px;
      }
      .container {
        padding-top: 70px;
        @include media-breakpoint-up(md) {
          padding-top: 40px;
        }
      }
      .caption {
        margin-bottom: 0;
      }
      p {
        text-align: center;
        color: $white;
        font-weight: 700;
        margin: 16px 0;
        font-size: to.rem(16px);
        line-height: to.rem(22px);
      }
      .text-center.btn-dv {
        margin: 6px 0;
        .btn {
          background-color: $white;
          color: #4d4d4d;
          &:hover {
            background-color: #333;
            border-color: $white;
            color: $white;
          }
        }
      }
    }
  }
  .private-menu {
    border-bottom: 2px solid $ltGray;
    .private-menu-in {
      display: flex;
      justify-content: space-between;
      padding: 0 28px;
      position: relative;
      @include media-breakpoint-down(lg) {
        flex-direction: column-reverse;
        align-items: center;
      }
    }
    ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        min-height: 100%;
      }
    }
    .left {
      max-width: 100vw;
      @include media-breakpoint-down(xl) {
        overflow-y: scroll;
        padding: 0 15px;
        &::after,
        &::before {
          content: '';
          bottom: 0;
          left: 0;
          position: absolute;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
          );
          width: 25px;
          height: 52px;
          display: block;
          z-index: 60;
        }
        &::before {
          left: inherit;
          right: 0;
          background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      }
      a {
        position: relative;
        display: flex;
        min-height: 100%;
        color: $dkGray;
        justify-content: center;
        align-items: center;
        font-weight: 800;
        font-size: to.rem(14px);
        line-height: to.rem(16px);
        text-decoration: none;
        text-align: center;
        padding: 10px;
        @include media-breakpoint-up(xl) {
          font-size: to.rem(16px);
          line-height: to.rem(18px);
          padding: 10px 15px;
        }
        &:hover {
          color: $at-primary;
        }
      }
      .active {
        a {
          color: $at-primary;
          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            background-color: $at-primary;
          }
        }
      }
    }
    .btn {
      margin: 6px;
      background-color: $at-primary;
      border-color: $at-primary;
      color: $white;
      font-size: to.rem(14px);
      padding: 8px 18px;
      @include media-breakpoint-up(xl) {
        font-size: to.rem(16px);
        padding: 14px 18px;
      }
      &:hover {
        background-color: $white;
        color: $at-primary;
      }
    }
  }
  .at-profile-edit {
    margin-top: 64px;
    margin-bottom: 80px;
  }
  .profile-details {
    article {
      margin-bottom: 42px;
      & > header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e6e6e6;
        padding: 5px 0;
        margin-bottom: 15px;
        align-items: center;
        &.border-top {
          border-bottom: 0;
          border-top: 1px solid #e6e6e6;
        }
        a {
          display: flex;
          font-size: to.rem(14px);
          line-height: to.rem(20px);
          cursor: pointer;
          color: $at-primary;
          transition: $animColor;
          text-decoration: none;
          svg {
            height: 17px;
            width: 17px;
            margin-right: 3px;
          }
          &:hover {
            color: #155fb3;
          }
        }
      }
    }
    p {
      font-size: to.rem(14px);
      line-height: to.rem(20px);
    }
    .improve {
      margin: 20px 0 10px;
    }
    .title,
    .control-label {
      font-size: to.rem(18px);
      line-height: to.rem(20px);
      font-weight: 700;
      color: $dkGray;
      margin-bottom: 2px;
    }
    dl {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      font-size: to.rem(14px);
      line-height: to.rem(20px);
      dt {
        margin-top: 0;
        width: 33%;
        font-weight: 400;
      }
      dd {
        width: 66%;
        font-weight: 700;
        word-break: break-word;
      }
    }
    dd.password {
      font-size: to.rem(30px);
      line-height: 1;
      font-weight: 800;
    }
    .btn {
      margin: 6px 0;
      background-color: $at-primary;
      border-color: $at-primary;
      color: $white;
      min-width: 100%;
      &:hover {
        background-color: $white;
        color: $at-primary;
      }
      &.ghost {
        background-color: $white;
        color: $at-primary;
        &:hover {
          background-color: $at-primary;
          color: $white;
        }
      }
    }
  }
  .form-group .form-input {
    &:read-only,
    &[readonly='readonly'],
    &[readonly] {
      background: #eeeeee;
      cursor: not-allowed;
      &:active,
      &:focus {
        outline: 0;
      }
    }
  }
  .switch {
    flex-direction: column;
    height: auto;
    width: auto;
    text-align: left;
    label {
      display: flex;
      margin-bottom: 6px;
      font-weight: 400 !important;
    }
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
  }
  .form {
    input,
    select {
      text-overflow: ellipsis;
    }
  }
  .my-profile,
  .area-privada-crea {
    padding-right: 20px;
    p,
    ul {
      font-size: to.rem(16px) !important;
      line-height: to.rem(24px);
      &.error-msg {
        padding: 0;
      }
      li {
        margin-bottom: 6px;
        &::marker {
          color: $at-primary;
          font-size: to.rem(20px);
        }
      }
    }
  }
  .my-profile {
    padding-right: 20px;
  }
  .account-already {
    display: flex;
    align-items: flex-start;
    font-weight: 700;
    a {
      margin-left: 6px;
    }
    svg {
      position: relative;
      top: -3px;
      width: 34px;
      margin-right: 8px;
      path {
        fill: $dkGray;
      }
    }
  }
  .area-privada-crea {
    padding-top: 132px;
    @include media-breakpoint-up(xl) {
      padding-top: 166px;
    }
    .attribute-image {
      margin-bottom: 32px;
      svg {
        max-width: 286px;
      }
    }
    .group > legend,
    .control-label {
      border-top: 1px solid #e6e6e6;
      font-size: to.rem(18px);
      line-height: to.rem(20px);
      font-weight: 700;
      color: $dkGray;
      padding-top: 22px;
      margin-top: 42px;
      margin-bottom: 10px;
      display: flex;
    }
    .checkbox {
      margin-bottom: 10px;
    }
    .btn {
      margin: 6px 0;
      background-color: $at-primary;
      border-color: $at-primary;
      color: $white;
      min-width: 100%;
      &:hover {
        background-color: $white;
        color: $at-primary;
      }
    }
    .col-lg-6 {
      &:last-of-type {
        margin-bottom: 62px;
      }
    }
  }
  .area-privada-elimina {
    margin-bottom: 62px;
    .grid-tips {
      padding: 42px 0;
      .tip {
        text-align: center;
        position: relative;
        padding: 10px;
        &::before {
          content: '';
          position: absolute;
          background-color: $ltGray;
          border-radius: 10px;
          opacity: 0;
          width: 100%;
          height: 100%;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1;
        }
        &:hover {
          &::before {
            opacity: 0.4;
            animation: BounceSwiperNav 0.3s;
          }
        }
        & > div {
          position: relative;
          z-index: 5;
        }
        a {
          position: relative;
          z-index: 5;
          text-decoration: none;
          text-align: center;
          font-size: to.rem(16px);
          line-height: to.rem(25px);
          svg {
            height: 50px;
            width: 50px;
            color: $at-primary;
          }
          &:hover {
            color: $at-primary;
            .title {
              color: $at-primary;
            }
          }
        }
      }
      .title {
        font-weight: 800;
        font-size: to.rem(24px);
        line-height: to.rem(30px);
        letter-spacing: to.rem(-0.4px);
        color: $dkGray;
        margin: 20px 0 10px;
        text-decoration: none;
        text-transform: lowercase;
        position: relative;
        z-index: 5;
        transition: $animColor;
        @include media-breakpoint-up(md) {
          font-size: to.rem(28px);
          line-height: to.rem(34px);
          letter-spacing: to.rem(-0.5px);
        }
      }
    }
    .delete-account {
      .col-md-6 {
        display: flex;
        justify-content: center;
        .btn {
          min-width: auto;
        }
      }
    }
  }
  &.promo {
    .account-already {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 24px;
      margin-bottom: 26px;
    }
  }
  &.deleted-confirm {
    min-height: 100vh;
    padding: 20px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .col-12 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .text {
      text-align: center;
    }
  }
  &.at-fila {
    margin-top: 84px;
    margin-bottom: 64px;
    @include media-breakpoint-down(sm) {
      margin-top: 42px;
      margin-bottom: 32px;
    }
  }
}
