html {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
.loader-hoteles {
  background-color: #76bbdf;
  min-height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 96px 0;
  position: relative;
  .container {
    position: relative;
    z-index: 4;
  }
  .loader-hoteles-anim {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(md) {
      transform: scale(0.75);
    }
    .icon {
      position: absolute;
      width: 100px;
      height: 100px;
    }
    .loader {
      position: relative;
      margin: 0px auto;
      width: 200px;
      height: 200px;
      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }
      .circular-loader {
        animation: rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: auto;
      }
      .loader-path {
        stroke-dasharray: 150, 200;
        stroke-dashoffset: -10;
        animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        stroke-linecap: round;
      }
      .loader-course {
        opacity: 0.25;
      }
    }
  }
  .tit-loader {
    font-size: to.rem(32px);
    line-height: to.rem(42px);
    letter-spacing: to.rem(-0.2px);
    color: $white;
    @include media-breakpoint-up(md) {
      margin-top: 12px;
      font-size: to.rem(42px);
      line-height: to.rem(52px);
    }
    @include media-breakpoint-up(xl) {
      font-size: to.rem(52px);
      line-height: to.rem(62px);
      letter-spacing: to.rem(-0.31px);
    }
  }
  p {
    color: $white;
  }
  .img-bg {
    bottom: 0;
    left: 0;
    position: absolute;
    object-fit: cover;
    right: 0;
    width: 100%;
    z-index: 2;
    min-height: 40%;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
  @keyframes color {
    0% {
      stroke: $white;
    }
    40% {
      stroke: $white;
    }
    66% {
      stroke: $white;
    }
    80%,
    90% {
      stroke: $white;
    }
  }
}
