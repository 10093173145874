// _contacto.scss
.title-sinintro {
  @extend %intro-h1;
  color: $dkGray;
  text-shadow: none;
  margin-top: 178px;
  margin-bottom: 20px;
  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
  }
  > * {
    @extend %intro-h1;
    color: $dkGray;
    text-shadow: none;
  }
}
.contacta-cols {
  .col-xl-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    padding-bottom: 32px;
    padding-top: 20px;
    @include media-breakpoint-up(xl) {
      padding-top: 0;
      padding-bottom: 12px;
    }
    &::after,
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      background: $white;
      width: 100%;
      height: 1px;
    }
    &::before {
      bottom: -1px;
      background: rgba(0, 0, 0, 0.08);
    }
    @include media-breakpoint-up(xl) {
      &::after,
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        background: $white;
        width: 1px;
        height: 100%;
      }
      &::before {
        right: -1px;
        background: rgba(0, 0, 0, 0.08);
      }
    }
    &:last-child {
      padding-bottom: 0 !important;
      &::after,
      &::before {
        display: none;
      }
    }
  }
  .title {
    font-weight: 800;
    font-size: to.rem(18px);
    line-height: to.rem(22px);
    color: $dkGray;
    margin: 8px 0 15px;
  }
  p {
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: to.rem(16px);
    line-height: to.rem(22px);
    letter-spacing: -0.24px;
    color: $mdGray;
    strong {
      font-weight: 800;
      color: $dkGray;
    }
  }
  svg {
    max-width: 90px;
    max-height: 90px;
  }
  .redes {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-down(xl) {
      ul {
        padding: 0 20px;
        li {
          display: flex;
          justify-content: center;
          flex: 1 0 33%;
        }
      }
    }
    @include media-breakpoint-down(md) {
      ul {
        padding: 0;
        margin: 0;
      }
    }
    a {
      background: #ffffff;
      width: 49px;
      height: 49px;
      border-radius: 10px;
      margin: 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #c9c9c9;
      transition: $animBorderColor;
      margin-bottom: 28px;
      svg {
        width: 41px;
        height: 41px;
        path {
          fill: #4d4d4d;
          transition: $animFill;
        }
      }
      &:hover {
        border-color: $at-primary;
        svg {
          path {
            fill: $at-primary;
          }
        }
      }
    }
  }
}
