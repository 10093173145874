// at-detalle-pistas.scss
.at-detalle-pistas {
  position: relative;
  overflow: hidden;
  background-color: $white;
  margin: 50px 0;
  @include media-breakpoint-down(xl) {
    margin-top: 30px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 250px;
    left: 0;
    right: 0;
    width: 100%;
    height: 410px;
    background: linear-gradient(
      to bottom,
      rgba(233, 233, 233, 0) 0%,
      rgba(233, 233, 233, 1) 100%
    );
    z-index: 1;
    @include media-breakpoint-down(xl) {
      display: none;
    }
  }
  .mont {
    position: absolute;
    z-index: 2;
    width: 2100px;
    min-width: 100%;
    left: 0;
    right: 0;
    top: 575px;
    pointer-events: none;
    overflow: hidden;
    @include media-breakpoint-down(xl) {
      display: none;
    }
    &.mobile {
      position: relative;
      display: none;
      @include media-breakpoint-down(xl) {
        display: block;
        overflow: visible;
        top: inherit;
        margin-top: 20px;
        left: -50%;
        z-index: -1;
        transform: translateX(-25%);
        svg {
          position: relative;
          z-index: 2;
        }
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: -1.3rem;
          right: 0;
          width: 100%;
          height: 600px;
          background: linear-gradient(
            to bottom,
            rgba(233, 233, 233, 0) 0%,
            rgba(233, 233, 233, 1) 100%
          );
          z-index: auto;
        }
        &::after {
          content: '';
          position: absolute;
          bottom: -38px;
          left: 0;
          right: 0;
          width: 100%;
          height: 40px;
          background: $white;
          z-index: 2;
        }
      }
    }
    svg {
      position: relative;
      bottom: -0.5px;
      width: 100%;
      height: auto;
      filter: drop-shadow(0 0 4px rgb(0 0 0 / 0.3));
    }
  }
  > .container {
    position: relative;
    z-index: 3;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 90%;
      height: 100%;
      margin: 0 auto;
      background: $white;
      z-index: 1;
      @include media-breakpoint-down(xl) {
        display: none;
      }
    }
    .container-inner {
      position: relative;
      z-index: 4;
    }
  }
  .separadorlinea {
    margin: 60px 0;
  }
  .graficas {
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @include media-breakpoint-down(xl) {
      flex-direction: column;
      &::before {
        display: none;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 54px;
      bottom: 0;
      left: 50%;
      width: 1px;
      height: 166px;
      background-color: rgb(0 0 0 / 0.08);
      z-index: 1;
    }
    .grafica {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include media-breakpoint-down(xl) {
        background: $white;
        border: 1px solid #f1f1f1;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        margin-top: 10px;
        border-bottom: 0;
      }
      .circle_progress {
        position: relative;
        .valor {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          font-size: to.rem(42px);
          line-height: to.rem(42px);
          letter-spacing: to.rem(-1.26px);
          color: $at-primary;
          span {
            font-size: to.rem(14px);
            line-height: to.rem(24px);
            letter-spacing: 0;
            color: #808080;
          }
        }
        .circle {
          stroke-linecap: round;
          transform: rotate(315deg);
          transform-origin: 50% 50%;
        }
      }
      .btn {
        position: relative;
        margin: 24px 0 30px;
        pointer-events: none;
        @include media-breakpoint-down(xl) {
          margin-bottom: 20px;
        }
        span {
          display: none;
          position: absolute;
          height: 22px;
          width: 44px;
          left: 50%;
          bottom: -53px;
          transform: rotate(-45deg) translateX(-50%);
          @include media-breakpoint-down(xl) {
            display: none !important;
          }
          &::before {
            content: '';
            width: 0;
            height: 0;
            border-bottom: 38px solid transparent;
            border-left: 38px solid transparent;
            border-right: 38px solid #f1f1f1;
            position: absolute;
            top: -20px;
            right: 0;
          }
          &:after {
            content: '';
            width: 0;
            height: 0;
            border-bottom: 32px solid transparent;
            border-left: 32px solid transparent;
            border-right: 32px solid $white;
            position: absolute;
            right: 4px;
            top: -16px;
            width: 0;
          }
        }
        &.show {
          span {
            display: block;
          }
        }
        &:not(.show) {
          pointer-events: all;
          background: #f5f5f5;
          border: 1px Solid #c9c9c9;
          color: #4d4d4d;
          &:hover {
            border-color: $at-primary;
            color: $at-primary;
          }
        }
      }
    }
    p {
      padding: 0;
      margin: 0;
    }
    .title {
      font-weight: 700;
      letter-spacing: to.rem(1px);
      color: $dkGray;
      margin: 20px 0 10px;
      text-align: center;
      text-transform: uppercase;
      margin-left: 22px;
      margin-right: 22px;
      line-height: to.rem(22px);
      @include media-breakpoint-up(md) {
        font-size: to.rem(16px);
        letter-spacing: to.rem(2px);
      }
    }
  }
  .collapse {
    &#pistesextend {
      display: block;
    }
    min-width: 100%;
  }
  .barcharts {
    border: 4px #f1f1f1 solid;
    padding: 20px 0;
    margin-top: 4px;
    @include media-breakpoint-down(xl) {
      border: none;
      background: $white;
      border: 1px solid #f1f1f1;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      margin-top: 0;
      border-top: 0;
      padding-top: 6px;
    }
    .barchart {
      width: 100%;
      justify-content: space-evenly;
      @include media-breakpoint-down(xl) {
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 5px;
      }
      .name {
        min-width: 210px;
        margin: 0 15px;
        display: flex;
        justify-content: flex-end;
        position: relative;
        @include media-breakpoint-down(xl) {
          bottom: -2px;
          min-width: auto;
          margin: 0 0 0 22px;
        }
        .title {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: 0;
          padding: 0 0 5px;
          font-weight: 700;
          font-size: to.rem(21px);
          line-height: to.rem(30px);
          letter-spacing: 0;
          color: $dkGray;
          text-transform: none;
          @include media-breakpoint-down(xl) {
            font-size: to.rem(16px);
            padding: 0;
          }
        }
      }
      .barwraper {
        display: flex;
        flex: 2;
        min-height: 54px;
        align-items: center;
        @include media-breakpoint-down(xl) {
          margin: 0 20px;
          min-height: 20px;
          min-width: calc(100% - 40px);
        }
        .barchart_progress {
          position: relative;
          min-width: 100%;
          display: flex;
          align-items: center;
        }
        .bar {
          width: 100%;
          height: 5px;
          background: #d7dcde;
        }
        .percent {
          position: absolute;
          left: 0;
          top: 0;
          height: 5px;
          margin: 0 auto;
          &::after {
            content: '';
            position: absolute;
            top: -5px;
            right: -13px;
            width: 15px;
            height: 15px;
            background: $white;
            border: 2px rgb(57, 139, 231) solid;
            border-radius: 50%;
          }
        }
      }
      .valorwraper {
        min-width: 210px;
        margin: 0 15px;
        @include media-breakpoint-down(xl) {
          min-width: auto;
          margin: 0 22px 0 0;
        }
        .valor {
          margin: 0;
          padding: 5px 0 0;
          font-weight: 800;
          font-size: to.rem(32px);
          line-height: to.rem(26px);
          letter-spacing: to.rem(-0.48px);
          color: $at-primary;
          @include media-breakpoint-down(xl) {
            font-size: to.rem(21px);
            letter-spacing: to.rem(-0.31px);
          }
          span {
            font-weight: 400;
            font-size: to.rem(21px);
            letter-spacing: to.rem(-0.31px);
            color: #4d4d4d;
            padding-bottom: 2px;
            @include media-breakpoint-down(xl) {
              font-size: to.rem(14px);
              letter-spacing: to.rem(-0.21px);
            }
          }
        }
      }
    }
  }
  .tipus-pista {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    @include media-breakpoint-down(xl) {
      margin-top: 50px;
    }
    button.title {
      font-weight: 700;
      font-size: to.rem(16px);
      letter-spacing: to.rem(2px);
      color: $dkGray;
      margin: 16px 0 0;
      text-align: center;
      text-transform: uppercase;
      pointer-events: none;
      cursor: default;
      background: none;
      border: none;
      .banderes {
        display: none;
      }
      p {
        margin-bottom: 4px;
      }
    }
    .barcharts {
      border: none;
      padding-top: 0;
      .title:focus-within .blau {
        background-color: aquamarine;
      }
      .barchart {
        .icon-bandera {
          display: none;
        }
        &.verd {
          .title {
            color: $verd;
          }
          .barwraper {
            .percent {
              &::after {
                border-color: $verd;
              }
            }
          }
          .valorwraper {
            .valor,
            span {
              color: $verd;
            }
          }
        }
        &.blau {
          .title {
            color: $blau;
          }
          .barwraper {
            .percent {
              &::after {
                border-color: $blau;
              }
            }
          }
          .valorwraper {
            .valor,
            span {
              color: $blau;
            }
          }
        }
        &.vermell {
          .title {
            color: $vermell;
          }
          .barwraper {
            .percent {
              &::after {
                border-color: $vermell;
              }
            }
          }
          .valorwraper {
            .valor,
            span {
              color: $vermell;
            }
          }
        }
        &.negre {
          .title {
            color: $black;
          }
          .barwraper {
            .percent {
              &::after {
                border-color: $black;
              }
            }
          }
          .valorwraper {
            .valor,
            span {
              color: $black;
            }
          }
        }
      }
    }
    .masdatos {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 20px 0;
      @include media-breakpoint-down(xl) {
        flex-direction: column;
      }
    }
    .dato {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 49%;
      padding: 32px;
      min-height: 100px;
      background: #f5f5f5;
      border-radius: 11px;
      @include media-breakpoint-down(xl) {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 22px;
        padding-bottom: 22px;
        &:first-of-type {
          margin-bottom: 20px;
        }
      }
      p {
        margin-bottom: 0;
      }
      .name {
        font-weight: 700;
        font-size: to.rem(24px);
        line-height: to.rem(27px);
        color: $dkGray;
        @include media-breakpoint-down(xl) {
          flex: 2;
          font-size: to.rem(18px);
          min-width: 100%;
          text-align: center;
        }
      }
      .icon {
        width: 40px;
        height: 40px;
        margin-right: 12px;
        margin-left: auto;
        path {
          fill: #8d8d8d;
        }
        @include media-breakpoint-down(xl) {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          margin-left: inherit;
        }
      }
      .valor,
      .weather {
        display: flex;
        align-items: center;
        font-weight: 800;
        font-size: to.rem(24px);
        line-height: to.rem(27px);
        letter-spacing: to.rem(-0.24px);
        span {
          font-weight: 700;
        }
        .icon {
          height: 40px;
          width: 40px;
          font-size: to.rem(60px);
          line-height: to.rem(60px);
          padding: 0;
          color: #8d8d8d;
          position: relative;
          top: -10px;
          margin-right: 26px;
        }
        &.viento {
          .icon-wind-direction {
            display: none;
          }
        }
      }
      .weather {
        margin-left: auto;
        margin-right: 20px;
        @include media-breakpoint-down(xl) {
          margin-left: inherit;
        }
      }
      .temperatura {
        font-weight: 800;
        .max {
          color: #ff0000;
        }
        .min {
          color: $at-primary;
        }
        span span {
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
  }
  .updated {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    p {
      display: flex;
      justify-content: center;
    }
    .link {
      display: flex;
      justify-content: center;
      margin: 2px 0 8px;
      svg {
        width: 21px;
        height: 21px;
        margin-right: 12px;
      }
    }
  }
}
