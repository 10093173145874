// _slider-detalle.scss
.swiper-detalle {
  overflow: hidden;
  .detalle-gallery {
    padding: 0;
    margin: 0;
    .caption {
      display: none;
    }
    li {
      padding: 0;
      margin: 0;
      display: flex;
      width: auto;
      &::marker {
        display: none;
      }
      img {
        max-height: 370px;
        min-height: 370px;
      }
      .play-icon {
        background-color: $at-primary;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.15);
        svg {
          width: 56px;
          height: 56px;
          position: relative;
          right: -4px;
          path {
            fill: $white;
          }
        }
      }
    }
  }
  &.solo {
    .detalle-gallery {
      li {
        a {
          min-width: 100%;
        }
        img {
          //   max-height: inherit;
          //   min-height: inherit;
          min-width: 100%;
          object-fit: cover;
          object-position: top;
          max-width: 100%;
        }
      }
    }
  }
}
.slider-detalle-wrap {
  position: relative;
  padding-bottom: 60px;
  @include media-breakpoint-down(lg) {
    .container {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
    .container-inner {
      margin: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    .container-inner {
      background-color: $white;
      border-radius: 10px;
      padding: 12px;
    }
  }
  .nav-slider-detalle {
    .swiper-pagination {
      visibility: visible;
      font-size: 0;
      .swiper-pagination-bullet {
        border: 1px solid #a7a7a7;
        transition: $animAll;
        outline: none !important;
        box-shadow: none !important;
        &-active {
          background: #333333 !important;
          border: 1px solid #fff !important;
          cursor: default;
        }
        &:hover {
          background: $at-primary;
          border: 1px solid #fff;
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      color: $white;
      margin-top: -60px;
      @include media-breakpoint-up(lg) {
        color: #d1d1d1;
      }
    }
  }
}
.pswp__container {
  .plyr {
    max-height: 100vh;
    min-width: 100%;
  }
  .pswp__zoom-wrap {
    display: flex;
    justify-content: center;
  }
}
