.at-fila-seo-full-wrap {
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.3));
  margin-bottom: -112px;
  position: relative;
  z-index: 10;
  padding-bottom: 20px;
  .sinpunta & {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    .at-fila-seo-full {
      clip-path: none !important;
      padding-bottom: 40px !important;
    }
  }
}
.at-fila-seo-full {
  position: relative;
  margin-top: 0;
  padding: 70px 0 38px;
  background: linear-gradient(
    0deg,
    rgba(0, 139, 231, 1) 0%,
    rgba(0, 70, 148, 1) 100%
  );
  clip-path: polygon(0% 0%, 0% 95%, 50% 100%, 100% 95%, 100% 0%);
  @include media-breakpoint-up(sm) {
    clip-path: polygon(0% 0%, 0% 90%, 50% 100%, 100% 90%, 100% 0%);
  }
  @include media-breakpoint-up(md) {
    padding: 80px 0 60px;
    clip-path: polygon(0% 0%, 0% 85%, 50% 100%, 100% 85%, 100% 0%);
  }
  @include media-breakpoint-up(lg) {
    clip-path: polygon(0% 0%, 0% 80%, 50% 100%, 100% 80%, 100% 0%);
  }
  .h1 {
    color: #b0d1f5;
  }
  .parrafo {
    font-weight: 800;
    font-size: to.rem(21px);
    line-height: to.rem(34px);
    letter-spacing: to.rem(-0.21px);
    color: $white;
  }
  .btn {
    margin-top: 26px;
  }
  .arrow-down {
    position: relative;
    width: 37px;
    height: 22px;
    margin-top: 18px;
    cursor: pointer;
    transition: $animTransform;
    &:before,
    &:after {
      background-color: #59abe5;
      border-radius: 3px;
      right: 7px;
      height: 27.5px;
      width: 6px;
    }
    &:after {
      left: 7px;
    }
    &:hover {
      &:before,
      &:after {
        background-color: $white;
      }
    }
  }
}
#seocontainer {
  .calcChildSize {
    overflow: hidden;
    height: 0;
    transition: $animHeight;
    .container-inner {
      padding: 150px 0 50px;
    }
    .row {
      margin-bottom: 30px;
    }
  }
  &.sinpunta {
    .calcChildSize {
      .container-inner {
        padding-top: 50px;
      }
    }
  }
  &.show {
    .calcChildSize {
      height: var(--calc-height);
    }
    .at-fila-seo-full .arrow-down {
      transform: rotate(180deg);
    }
  }
}
