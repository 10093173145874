// at-search-activity.scss
.at-search-activity {
  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }
  }
  input {
    min-width: 100%;
    @include media-breakpoint-up(lg) {
      min-width: 472px;
    }
  }
  .has-float-label,
  button {
    margin: 6px;
  }
  .form-group {
    margin-bottom: 0;
    .error-msg {
      position: relative;
      padding: 0 14px;
      line-height: 13px;
      @include media-breakpoint-up(lg) {
        position: absolute;
      }
    }
  }
  .select {
    min-width: 262px;
  }
  #rangepicker {
    bottom: 10px;
    margin-right: 5px;
    opacity: 0;
    position: absolute;
    right: 50%;
    min-width: auto;
  }
}
