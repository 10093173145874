// _swiper.scss
.swiper {
  width: 100%;
  min-height: 300px;
}
[class^='nav-slider-'],
[class*=' nav-slider-'] {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden;
}
.swiper-button-prev,
.swiper-button-next {
  visibility: visible;
  opacity: 1;
  transition: $animColor;
  color: $white;
  transform: scale(0.42);
  user-select: none;
  &:focus {
    outline: 0 !important;
  }
  @include media-breakpoint-up(md) {
    transform: scale(0.55);
  }
  @include media-breakpoint-up(lg) {
    transform: scale(0.7);
  }
  @include media-breakpoint-up(xl) {
    transform: scale(0.85);
  }
  @include media-breakpoint-up(xxl) {
    transform: scale(1);
  }
  &::after {
    font-weight: bold;
    z-index: 10;
  }
  &::before {
    content: '';
    position: absolute;
    background-color: #000000;
    opacity: 0;
    width: 114px;
    height: 114px;
    left: -30px;
    top: -20px;
    border-radius: 114px;
    z-index: 9;
  }
  &:hover {
    opacity: 1 !important;
    color: $white !important;
    &::before {
      opacity: 0.3;
      animation: BounceSwiperNav 0.3s;
    }
  }
}
.swiper-button-next {
  right: 0;
  @include media-breakpoint-up(md) {
    right: 30px;
  }
  &::before {
    left: inherit;
    right: -30px;
  }
}
.swiper-button-prev {
  left: 0;
  @include media-breakpoint-up(md) {
    left: 30px;
  }
}
