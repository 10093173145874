.at-fila-event-full {
  position: relative;
  overflow: hidden;
  background: $black;
  > .container {
    position: relative;
    z-index: 10;
    .row {
      min-height: 560px;
      .col-xl-6 {
        padding-top: 390px;
        @include media-breakpoint-up(lg) {
          padding-top: 0;
        }
      }
    }
  }
  > .cont-image {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(100% - 200px);
    width: 100%;
    z-index: 5;
    @include media-breakpoint-up(lg) {
      width: fit-content;
      top: 0;
      height: 100%;
    }
    img {
      object-fit: contain;
      overflow: hidden;
      position: absolute;
      left: 50%;
      bottom: 0;
      max-height: 100%;
      transform: translate(-50%);
      @include media-breakpoint-up(md) {
        min-width: 100%;
        object-fit: cover;
      }
      @include media-breakpoint-up(lg) {
        max-width: 60vw;
        min-height: 100%;
        position: relative;
        left: inherit;
        bottom: inherit;
        transform: translate(0);
      }
    }
    .mascara {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .info-box-bg {
    padding: 32px 20px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    margin: -368px 0 200px;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 66px 36px;
    }
    > .img-fluid {
      margin-bottom: 10px;
    }
    > .titulo {
      color: $white;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
    }
    > .fecha {
      font-weight: 800;
      font-size: to.rem(24px);
      line-height: to.rem(29px);
      letter-spacing: to.rem(-0.43px);
      color: #2f95f0;
      margin-bottom: 16px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    }
    > .parrafo {
      font-size: to.rem(16px);
      line-height: to.rem(19px);
      color: #222222;
      margin-bottom: 26px;
      @include media-breakpoint-up(lg) {
        line-height: to.rem(25px);
        margin-bottom: 32px;
      }
    }
  }
}
body:before {
  content: 'xs';
  display: none;
  visibility: hidden;
}
@include media-breakpoint-up(sm) {
  body:before {
    content: 'sm';
  }
}
@include media-breakpoint-up(md) {
  body:before {
    content: 'md';
  }
}
@include media-breakpoint-up(lg) {
  body:before {
    content: 'lg';
  }
}
@include media-breakpoint-up(xl) {
  body:before {
    content: 'xl';
  }
}
@include media-breakpoint-up(xxl) {
  body:before {
    content: 'xxl';
  }
}
