// at-video.scss
.at-video {
  lite-youtube {
    max-width: initial;
    &::before {
      display: none;
    }
    .lty-playbtn {
      display: none;
    }
    &:hover {
      .triangle-wrap {
        &::before {
          background: rgba(0, 0, 0, 0.7);
        }
      }
    }
    .triangle-wrap {
      transform: rotate(90deg) scale(0.4);
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -28px;
      margin-top: -33px;
      @include media-breakpoint-up(md) {
        transform: rotate(90deg) scale(0.7);
      }
      @include media-breakpoint-up(xl) {
        transform: rotate(90deg);
      }
      &::before {
        content: '';
        position: absolute;
        background: rgba(0, 0, 0, 0.2);
        width: 224px;
        height: 224px;
        border-radius: 100%;
        z-index: 10;
        left: -79px;
        top: -72px;
        transition: $animBgColor;
      }
      .triangle {
        position: relative;
        background-color: $white;
        text-align: left;
        z-index: 20;
        &:before,
        &:after {
          content: '';
          position: absolute;
          background-color: inherit;
        }
        transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
        &,
        &:before,
        &:after {
          width: 66px;
          height: 66px;
          border-top-right-radius: 30%;
        }
        &:before {
          transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
            translate(0, -50%);
        }
        &:after {
          transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414)
            translate(50%);
        }
      }
    }
  }
}
