:root {
  --swiper-navigation-size: 72px;
  --swiper-theme-color: #ffffff;
}
.slider-contabs-previo {
  position: relative;
  z-index: 2;
}
.slider-contabs-wrap {
  position: relative;
  overflow: hidden;
  margin-top: -100px;
  z-index: 1;
  .img-wrap {
    position: relative;
    min-height: 730px;
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      min-height: 700px;
      max-height: 700px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 5%,
        rgba(255, 255, 255, 0.8) 37.03%,
        rgba(255, 255, 255, 0) 99.78%
      );
      width: 100%;
      height: 289px;
      z-index: 6;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      width: 100%;
      height: 377px;
      z-index: 6;
    }
  }
  .img-bg {
    position: absolute;
    object-fit: cover;
    z-index: 5;
    min-height: 100%;
    min-width: 101%;
    max-width: 101%;
    // @include media-breakpoint-up(sm) {
    //   position: relative;
    // }
  }
}
.slider-contabs {
  box-sizing: border-box;
  padding: 0 0 70px;
  min-height: 730px;
  overflow: visible;
  .swiper-wrapper {
    .container {
      position: absolute;
      z-index: 10;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      @include media-breakpoint-down(sm) {
        margin: 0;
        padding: 0 14px;
        box-sizing: border-box;
      }
      .container-inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        min-height: 100%;
      }
    }
  }
  .heading {
    font-weight: 400;
    font-size: to.rem(40px);
    line-height: to.rem(40px);
    color: #ffffff;
    margin-bottom: -6px;
    text-transform: uppercase;
    @include media-breakpoint-up(sm) {
      font-size: to.rem(46px);
      line-height: to.rem(46px);
      letter-spacing: to.rem(0.5px);
      margin-bottom: -9px;
    }
    @include media-breakpoint-up(md) {
      font-size: to.rem(70px);
      line-height: to.rem(70px);
      letter-spacing: to.rem(1.5px);
      margin-bottom: -10px;
    }
    @include media-breakpoint-up(xl) {
      font-size: to.rem(100px);
      line-height: to.rem(100px);
      letter-spacing: to.rem(2.5px);
      margin-bottom: -15px;
    }
  }
  .boxed {
    display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    background: #ffffff;
    border-radius: 10px;
    min-width: 100%;
    padding: 14px 14px 0;
    margin: 0 14px;
    box-sizing: border-box;
    margin-bottom: -64px;
    @include media-breakpoint-up(md) {
      padding: 18px 70px 0;
    }
    @include media-breakpoint-up(xl) {
      padding: 18px 130px 0;
    }
  }
  .parrafo {
    font-weight: 800;
    font-size: to.rem(21px);
    line-height: to.rem(34px);
    letter-spacing: to.rem(-0.21px);
    color: #666;
    margin-top: 14px;
    margin-bottom: 0;
  }
  .btn {
    margin-top: 32px;
  }
}
.nav-slider-contabs {
  .swiper-pagination {
    visibility: visible;
    bottom: auto;
    top: 110px;
    height: 42px;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    max-width: 100vw;
    box-sizing: border-box;
    padding: 4px 12px;
    mask-image: linear-gradient(
      to left,
      transparent 1%,
      black 6%,
      black 94%,
      transparent 99%
    );
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    @include media-breakpoint-up(sm) {
      overflow: inherit;
      justify-content: center;
      mask-image: none;
    }
    .swiper-pagination-bullet {
      position: relative;
      width: auto;
      height: 100%;
      margin: 0 5px;
      padding: 0 6px;
      transition: $animAll;
      font-weight: 800;
      font-size: to.rem(20px);
      line-height: to.rem(24px);
      text-transform: uppercase;
      color: #808080;
      background-color: transparent;
      transition: $animColor-y-Bg;
      border-radius: 0;
      white-space: nowrap;
      box-sizing: border-box;
      outline: 0 !important;
      box-shadow: none !important;
      @include media-breakpoint-up(md) {
        padding: 0 14px;
        font-size: to.rem(24px);
        line-height: to.rem(32px);
        &:hover {
          background-color: $at-primary;
          color: $white;
        }
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 6px;
        width: 0;
        height: 4px;
        transition: $animAll;
        opacity: 0;
        background-color: #333333;
        @include media-breakpoint-up(md) {
          bottom: -4px;
          left: 14px;
        }
      }
      &.swiper-contabs-pagination-bullet-activ {
        color: #333333 !important;
        background-color: transparent !important;
        &:after {
          opacity: 1;
          width: calc(100% - 12px);
          @include media-breakpoint-up(md) {
            width: calc(100% - 28px);
          }
        }
      }
    }
  }
}
